import { Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import ColumnTable from '../../Components/Table/Colunm';
import Title from '../../Components/Title';
import { useForm, Controller } from 'react-hook-form';
import ErrorMessage from './../../Components/Form/ErrorMessage';

const MODE_TYPES = {
    VIEW: 'VIEW',
    EDIT: 'EDIT',
};

function ManageContent() {
    const [mode, setMode] = useState(MODE_TYPES.VIEW);
    const { control, handleSubmit } = useForm();

    const content = {
        wash: '수시란 시신을 바르게 한다는 뜻으로 시신이 굳어지기 전에 수족(手足)을 골고루 주물로 굽힘이 없이 바르게 펴고 묶어 주는 절차를 말합니다.\n환자가 무릎이나 다른 부위가 굽어진 상태로 운명하였을 경우 수의를 입히거나 입관 시킬 때 어려움을 겪게 되므로 임종 후 바로 반듯이 해 주어야 합니다.\n\n수시용품\n수시복, 탈지면, 끈, 수건, 홑이불, 환자용 기저귀, 병풍, 상, 촛대(초), 향로(향), 사진, 긴 자',
        shroud: '염습이란 옷을 겹쳐 놓고 시신을 목욕시킨 후 수의를 입히는 절차를 말합니다.\n\n염습용품\n대야, 수건, 목욕물(향물, 알코올),수의 , 관',
        clean: '청소료\n음식물 및 사업장 폐기물 의료페기물 처리비 감염성폐기물처리\n캐시 지급',
    };

    const columnContents = [
        {
            title: '수시',
            content:
                mode === MODE_TYPES.VIEW ? (
                    content.wash
                ) : (
                    <Controller
                        control={control}
                        name="wash"
                        defaultValue={content.wash}
                        rules={{
                            required: '수시에 관한 용어설명을 입력해주세요.',
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    multiline
                                    {...field}
                                />
                                <ErrorMessage error={error} />
                            </>
                        )}
                    />
                ),
        },
        {
            title: '염습',
            content:
                mode === MODE_TYPES.VIEW ? (
                    content.shroud
                ) : (
                    <Controller
                        control={control}
                        name="shroud"
                        defaultValue={content.shroud}
                        rules={{
                            required: '염습에 관한 용어설명을 입력해주세요.',
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    multiline
                                    {...field}
                                />
                                <ErrorMessage error={error} />
                            </>
                        )}
                    />
                ),
        },
        {
            title: '청소 및 폐기물 처리',
            content:
                mode === MODE_TYPES.VIEW ? (
                    content.clean
                ) : (
                    <Controller
                        control={control}
                        name="clean"
                        defaultValue={content.clean}
                        rules={{
                            required:
                                '청소 및 폐기물 처리에 관한 용어설명을 입력해주세요.',
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    multiline
                                    {...field}
                                />
                                <ErrorMessage error={error} />
                            </>
                        )}
                    />
                ),
        },
    ].map((ele) => ({
        ...ele,
        contentSx: {
            padding: mode === MODE_TYPES.VIEW ? '16px' : '6px',
            whiteSpace: 'pre-wrap',
        },
    }));

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <>
            <Title header={{ title: '컨텐츠 관리' }} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <ColumnTable columnContents={columnContents} TextField={true} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: '10px',
                    }}
                >
                    {mode === MODE_TYPES.EDIT ? (
                        <>
                            <Button
                                variant="outlined"
                                size="large"
                                color="secondary"
                                sx={{ mr: '15px' }}
                                onClick={() => {
                                    setMode(MODE_TYPES.VIEW);
                                }}
                            >
                                취소
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                            >
                                저장
                            </Button>
                        </>
                    ) : (
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => {
                                setMode(MODE_TYPES.EDIT);
                            }}
                        >
                            변경
                        </Button>
                    )}
                </Box>
            </form>
        </>
    );
}

export default ManageContent;
