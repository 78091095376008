import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import {
    Collapse,
    List,
    ListItemButton,
    ListItemText,
    Typography,
} from '@mui/material';

// project imports
import NavItem from './NavItem';

// assets
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box } from '@mui/system';
import { matchPath } from 'react-router-dom';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {
    // const [open, setOpen] = useState(false);
    const [open, setOpen] = useState(
        () =>
            menu.children.reduce(
                (prev, curr) =>
                    prev ||
                    Boolean(matchPath(curr.url, window.location.pathname)),
                false
            ) ||
            (menu.includePaths &&
                menu.includePaths.reduce(
                    (prev, curr) =>
                        prev ||
                        Boolean(matchPath(curr, window.location.pathname)),
                    false
                ))
    );

    const handleClick = () => {
        setOpen(!open);
    };

    // menu collapse & item
    const menus = menu.children?.map((item, idx) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={idx} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={idx} item={item} level={level + 1} />;
            default:
                return null;
        }
    });

    return (
        <>
            <ListItemButton
                sx={{
                    width: '200px',
                    backgroundColor:
                        level > 1 ? 'transparent !important' : 'inherit',
                    py: level > 1 ? 1 : 1.25,
                }}
                onClick={handleClick}
            >
                <ListItemText
                    primary={
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '500',
                                color: 'rgba(0, 0, 0, 0.87)',
                                lineHeight: '23px',
                            }}
                        >
                            {menu.title}
                        </Typography>
                    }
                />
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                    {menus}
                </List>
            </Collapse>
            <Box
                sx={{
                    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            ></Box>
        </>
    );
};

NavCollapse.propTypes = {
    level: PropTypes.number,
};

export default NavCollapse;
