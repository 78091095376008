import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { BrowserRouter, MemoryRouter } from 'react-router-dom';
import 'normalize.css';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider as ReduxStoreProvider } from 'react-redux';
import axios from 'axios';
import { API_BASE_URL } from './common/constants';
import store from './redux';
import AlertProvider from './Components/AlertProvider';

const theme = createTheme({
    palette: {
        primary: {
            main: '#C3A981',
            contrastText: '#fff',
        },
        primary2: {
            main: '#303742',
            contrastText: '#fff',
        },
        primary3: {
            main: '#000000DE',
        },
        primary4: {
            main: '#007AFF',
        },
        primary5: {
            main: '#FF5160',
        },
        secondary: {
            main: '#000000',
        },
    },
    typography: {
        fontFamily: 'Noto Sans KR',
        body1: {
            fontSize: '14px',
        },
        button: {
            fontFamily: 'Roboto',
            lineHeight: '26px',
        },
    },
    components: {
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflowX: 'inherit',
                },
            },
        },
        MuiTable: {
            styleOverrides: {
                root: {
                    background: '#FFFFFF',
                },
            },
        },
        MuiTableCell: {
            styleOverrides: {
                root: {},
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {},
            },
        },
    },
});

const root = createRoot(document.getElementById('root'));
const queryClient = new QueryClient();
axios.defaults.baseURL = API_BASE_URL;

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <ReduxStoreProvider store={store}>
                    <QueryClientProvider client={queryClient}>
                        <AlertProvider>
                            <App />
                        </AlertProvider>
                    </QueryClientProvider>
                </ReduxStoreProvider>
            </ThemeProvider>
        </BrowserRouter>
    </React.StrictMode>
);
