import Title from '../Components/Title';
import {
    TableContainer,
    TableCell,
    TableHead,
    TableRow,
    TableBody,
    Table,
} from '@mui/material';
import { Box, styled } from '@mui/system';

const TableTitle = styled(TableCell)({
    background: '#303742',
    color: '#FFFFFF',
    borderRight: '1px solid #979BA0',
    textAlign: 'center',
    padding: '6px 16px',
});

const headCells = [
    {
        id: '1',
        width: '145',
        bgBlack: true,
    },
    {
        id: '2',
        align: 'center',
        label: '어제',
        width: '135',
        bgBlack: true,
    },
    {
        id: '3',
        align: 'center',
        label: '오늘',
        width: '135',
        bgBlack: true,
    },
    {
        id: '4',
        align: 'center',
        label: '금주',
        width: '135',
        bgBlack: true,
    },
    {
        id: '5',
        align: 'center',
        label: '지난주',
        width: '135',
        bgBlack: true,
    },
    {
        id: '6',
        align: 'center',
        label: '이번달',
        width: '135',
        bgBlack: true,
    },
    {
        id: '7',
        align: 'center',
        label: '지난달',
        width: '135',
        bgBlack: true,
    },
];

function DashBoard() {
    return (
        <>
            <Title header={{ title: '대시보드' }} />
            <Box sx={{ mb: '40px' }}>
                <TableContainer>
                    <Table sx={{ border: '1px solid #E0E0E0' }}>
                        <TableHead>
                            <TableRow>
                                <TableTitle colSpan={4}>미처리</TableTitle>
                                <TableTitle>현재</TableTitle>
                                <TableTitle colSpan={3}>협력사</TableTitle>
                            </TableRow>
                            <TableRow
                                sx={{
                                    background: 'rgba(48, 55, 66, 0.07)',
                                }}
                            >
                                {[
                                    '비용청구',
                                    '출금지급',
                                    '미답변 문의',
                                    '미승인 협력사',
                                    '회원',
                                    '장례지도사',
                                    '장례식장',
                                    '장지',
                                ].map((ele, idx) => (
                                    <TableCell
                                        key={idx}
                                        sx={{
                                            textAlign: 'center',
                                        }}
                                        size="small"
                                    >
                                        {ele}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                {[5, 5, 2, 2, 310, 310, 310, 310].map(
                                    (ele, idx) => (
                                        <TableCell
                                            key={idx}
                                            sx={{
                                                '&:nth-of-type(-n+4)': {
                                                    color: '#FF5160',
                                                },
                                                '&:nth-of-type(n+5):nth-of-type(-n+8)':
                                                    {
                                                        color: '#485BA1',
                                                    },
                                                width: '12.5%',
                                                textAlign: 'center',
                                                borderRight:
                                                    '1px solid #F2F2F2',
                                                fontSize: '18px',
                                                lineHeight: '26px',
                                                fontWeight: 700,
                                            }}
                                        >
                                            {ele}
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
            <TableContainer>
                <Table sx={{ border: '1px solid #E0E0E0' }}>
                    <TableHead>
                        <TableRow>
                            {headCells.map((headCell, idx) => (
                                <TableCell
                                    key={idx}
                                    align={headCell.align}
                                    width={headCell.width}
                                    sx={{
                                        background: '#303742',
                                        color: '#FFFFFF',
                                        borderRight: '1px solid #979BA0',
                                        padding: '6px 16px',
                                    }}
                                >
                                    {headCell.label}
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[
                            '회원가입',
                            '회원탈퇴',
                            '견적요청',
                            '견적작성',
                            '장례 예약요청',
                            '장례 예약됨',
                            '발인예약 요청',
                            '발인예약됨',
                        ].map((bodyTitle, idx) => (
                            <TableRow key={idx}>
                                <TableCell
                                    sx={{
                                        background: 'rgba(48, 55, 66, 0.07)',
                                        borderRight: '1px solid #E0E0E0',
                                        borderBottom: '1px solid #E0E0E0',
                                        fontWeight: 500,
                                    }}
                                >
                                    {bodyTitle}
                                </TableCell>
                                {[1, 2, 31, 135, 40, 137].map(
                                    (bodyContent, idx) => (
                                        <TableCell
                                            key={idx}
                                            sx={{
                                                textAlign: 'center',
                                                borderRight:
                                                    '1px solid #F2F2F2',
                                            }}
                                        >
                                            {bodyContent}
                                        </TableCell>
                                    )
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}

export default DashBoard;
