import React, { useEffect } from 'react';
import Header from '../Components/Header/';

import styled from '@emotion/styled';

import {
    TextField,
    Button,
    Container,
    Box,
    FormHelperText,
} from '@mui/material';
import sx from '@mui/system/sx';
import { Controller, useForm } from 'react-hook-form';
import regexp from './../common/regexp';
import ErrorMessage from './../Components/Form/ErrorMessage';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { setToken } from './../redux/reducer/user';
import { useNavigate } from 'react-router-dom';

const StyledContainer = styled(Container)`
    .title {
        text-align: center;
        font-weight: 500;
        font-size: 20px;
        line-height: 29px;
        color: #000000;
    }
    .number {
        display: flex;
        li {
            color: #666666;
        }
        li:first-child {
            margin-right: 5px;
        }
    }
    input::placeholder {
        color: rgba(0, 0, 0, 0.6) !important;
    }
`;

function Login() {
    const {
        register,
        control,
        setValue,
        setError,
        clearErrors,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // 로그인
    const { mutate: login } = useMutation(['login'], {
        mutationFn: (data) =>
            axios.post('/admin/signIn', data).then((res) => res.data),
        onSuccess: (data) => {
            const { success, accessToken } = data;
            // 로그인 실패
            if (!success) {
                setError('login', {
                    type: 'custom',
                    message: '로그인 정보가 틀렸습니다.',
                });
                return;
            }
            // 로그인 성공
            dispatch(setToken(accessToken));
            navigate('/account');
        },
    });

    const onSubmit = (data) => {
        const { account, password } = data;
        // const formData = {
        //     // account값이 핸드폰 번호라면 hyphen입력
        //     account: regexp.mobilePhone.test(account)
        //         ? account
        //               .replace(/[^0-9]/g, '')
        //               .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3')
        //         : account,
        //     password,
        // };
        const formData = {
            account,
            password,
        };

        login(formData);
    };
    console.log(errors);

    // 테스트용 자동 입력
    useEffect(() => {
        if (process.env.NODE_ENV === 'development') {
            setValue('account', 'admin');
            setValue('password', 'p@ssW0Rd!!!');
        }
    }, []);

    return (
        <>
            <Header />
            <StyledContainer
                sx={{
                    height: 'calc(100vh - 55px)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box className="input-box" sx={{ width: '270px' }}>
                    <div className="title">로그인</div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Controller
                            control={control}
                            name="account"
                            defaultValue=""
                            rules={{
                                required:
                                    '이메일 또는 휴대폰 번호 또는 관리자ID를 입력해주세요.',
                                // validate: {
                                //     checkFormat: (v) =>
                                //         regexp.email.test(v) ||
                                //         regexp.mobilePhone.test(v) ||
                                //         '이메일 또는 휴대폰 번호의 양식이 잘못되었습니다.',
                                // },
                                onChange: () => {
                                    clearErrors('login');
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    fullWidth
                                    placeholder="이메일 또는 휴대폰 번호 또는 관리자ID"
                                    autoFocus
                                    sx={{
                                        mb: '5px',
                                        mt: '24px',
                                    }}
                                    defaultValue={''}
                                    {...field}
                                />
                            )}
                        />
                        <Controller
                            name="password"
                            control={control}
                            defaultValue=""
                            rules={{
                                required: '비밀번호를 입력해주세요.',
                                onChange: () => {
                                    clearErrors('login');
                                },
                            }}
                            render={({ field }) => (
                                <TextField
                                    placeholder="비밀번호"
                                    fullWidth
                                    type="password"
                                    autoComplete="current-password"
                                    defaultValue={''}
                                    {...field}
                                />
                            )}
                        />
                        <ErrorMessage error={errors} />
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                width: '100%',
                                height: '56px',
                                fontWeight: '500',
                                fontSize: '16px',
                                lineHeight: '23px',

                                my: '12px',
                            }}
                        >
                            로그인
                        </Button>
                    </form>

                    <ul className="number">
                        <li>계정문의는 담당자에게</li>
                        <li>02-345-6789</li>
                    </ul>
                    <ul className="number">
                        <li>내선번호 123</li>
                    </ul>
                </Box>
            </StyledContainer>
        </>
    );
}

export default Login;
