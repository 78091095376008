// project imports
import menuItem from './data/menuItem';
import NavCollapse from './NavCollapse';
import NavItem from './NavItem';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => (
    <nav>
        <ul>
            {menuItem.children.map((menu, idx) => {
                switch (menu.type) {
                    case 'collapse':
                        return <NavCollapse key={idx} menu={menu} level={1} />;
                    case 'item':
                        return (
                            <NavItem
                                key={idx}
                                item={menu}
                                menu={menu}
                                level={1}
                            />
                        );
                    default:
                        return '';
                }
            })}
        </ul>
    </nav>
);

export default MenuList;
