import React, { useState } from 'react';
import Title from './../../Components/Title';
import ColumnTable from './../../Components/Table/Colunm/index';
import { Box } from '@mui/system';
import { Button, Checkbox, FormControlLabel } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import parse from 'html-react-parser';
import DialogModal from '../../Components/Modal/DialogModal';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../Components/PageHeader';

function MessageDetail() {
    const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);
    const { control, handleSubmit, watch } = useForm();

    const navigate = useNavigate();

    const onSubmit = (data) => {
        console.log(data);
    };
    // 메세지 발성버튼 비활성화 여부
    const isDisabledSendBtn = (() => {
        const { send } = watch();
        return !(
            send &&
            [...Object.keys(send)].reduce((prev, key) => {
                const value = send[key];
                return prev || !!value;
            }, false)
        );
    })();

    const message = {
        id: 0,
        sendCnt: 2,
        title: '이용약관 개정 사전 안내',
        message: '문자입니다',
        content: '<p><strong>내용입니다.</strong></p>',
    };

    const columnContents = [
        { title: '제목', content: message.title },
        {
            title: '문자',
            content: (
                // <>
                //     [명당]{message.message} &lt;
                //     <Box color="primary4.main" component="span">
                //         URL
                //     </Box>
                //     &gt;
                // </>
                <>{message.message}</>
            ),
        },
        { title: '이메일', content: parse(message.content) },
        {
            title: '발송',
            content: (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box>
                        <FormControlLabel
                            label="유족"
                            control={
                                <Controller
                                    name="send.family"
                                    defaultValue={false}
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            sx={{ padding: '0 9px' }}
                                            {...field}
                                        />
                                    )}
                                />
                            }
                        />
                        <FormControlLabel
                            label="장례지도사"
                            control={
                                <Controller
                                    name="send.director"
                                    defaultValue={false}
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            sx={{ padding: '0 9px' }}
                                            {...field}
                                        />
                                    )}
                                />
                            }
                        />
                        <FormControlLabel
                            label="장례식장"
                            control={
                                <Controller
                                    name="send.funeralHall"
                                    defaultValue={false}
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            sx={{ padding: '0 9px' }}
                                            {...field}
                                        />
                                    )}
                                />
                            }
                        />
                        <FormControlLabel
                            label="장지"
                            control={
                                <Controller
                                    name="send.cemetery"
                                    defaultValue={false}
                                    control={control}
                                    render={({ field }) => (
                                        <Checkbox
                                            sx={{ padding: '0 9px' }}
                                            {...field}
                                        />
                                    )}
                                />
                            }
                        />
                    </Box>
                    <Box>{message.sendCnt}회 발송함</Box>
                </Box>
            ),
        },
    ];

    return (
        <>
            <PageHeader
                title="메세지 상세"
                breadcrumbItems={[{ name: '메세지' }, { name: '메세지 상세' }]}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <ColumnTable columnContents={columnContents} />
                <Box
                    sx={{
                        mt: '10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                    }}
                >
                    <Box sx={{ display: 'flex', gap: '15px' }}>
                        <Button
                            variant="outlined"
                            color="primary5"
                            onClick={() => setIsOpenRemoveModal(true)}
                        >
                            삭제
                        </Button>
                        <Button
                            variant="contained"
                            color="primary2"
                            onClick={() => {
                                navigate('edit');
                            }}
                        >
                            수정
                        </Button>
                    </Box>
                    <Button
                        variant="contained"
                        type="submit"
                        disabled={isDisabledSendBtn}
                    >
                        발송
                    </Button>
                </Box>
            </form>
            <DialogModal
                open={isOpenRemoveModal}
                close={() => {
                    setIsOpenRemoveModal(false);
                }}
                handleClickRightBtn={() => {
                    setIsOpenRemoveModal(false);
                }}
                rightBtnProps={{ color: 'error' }}
                rightBtnChildren={'삭제'}
            >
                삭제하시겠습니까?
            </DialogModal>
        </>
    );
}

export default MessageDetail;
