import styled from '@emotion/styled';
import { Button, Table, TableContainer, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { set } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { addHyphenPhone, checkIsValidPhone } from '../../../common/util';
import ErrorMessage from '../../../Components/Form/ErrorMessage';
import ColumnTable from '../../../Components/Table/Colunm';
import Title from '../../../Components/Title';

function EditFlower() {
    const navigate = useNavigate();
    const { control, handleSubmit, setValue, reset } = useForm();
    const { flowerId } = useParams();

    const funeralFlowerBusiness = {
        name: '(주)플라워센터',
        businessNumber: '352-86-01087',
        representative: '박상화',
        address: '부산광역시 사상구 학강대로 127,2층 (학장동, 문성빌딩)',
        phone: '1666-1002',
    };

    const columnContents = [
        {
            title: '협회명',
            content: (
                <Controller
                    control={control}
                    name="name"
                    defaultValue={funeralFlowerBusiness.name}
                    rules={{ required: '협회명을 입력해주세요.' }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '사업자번호',
            content: (
                <Controller
                    control={control}
                    name="businessNumber"
                    defaultValue={funeralFlowerBusiness.businessNumber}
                    rules={{ required: '사업자번호를 입력해주세요.' }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '대표자명',
            content: (
                <Controller
                    control={control}
                    name="representative"
                    defaultValue={funeralFlowerBusiness.representative}
                    rules={{ required: '대표자명을 입력해주세요.' }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '주소',
            content: (
                <Controller
                    control={control}
                    name="address"
                    defaultValue={funeralFlowerBusiness.address}
                    rules={{ required: '주소를 입력해주세요.' }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '전화번호',
            content: (
                <Controller
                    control={control}
                    name="phone"
                    defaultValue={funeralFlowerBusiness.phone}
                    rules={{
                        required: '전화번호를 입력해주세요.',
                        // onChange: (e) => {
                        //     const value = e.target.value;
                        //     setValue('phone', addHyphenPhone(value));
                        // },
                        // validate: {
                        //     isCorrectPattern: (v) =>
                        //         checkIsValidPhone(v) ||
                        //         '전화번호를 양식에 맞게 입력해주세요.',
                        // },
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
    ].map((ele) => ({
        ...ele,
        contentSx: { padding: '6px' },
    }));

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <>
            <Title header={{ title: '근조화환' }} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <ColumnTable columnContents={columnContents} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: '10px',
                    }}
                >
                    <Button
                        variant="outlined"
                        size="large"
                        color="secondary"
                        sx={{ mr: '15px' }}
                        onClick={() => {
                            navigate(`/partner/flower/${flowerId}`);
                        }}
                    >
                        취소
                    </Button>
                    <Button variant="contained" size="large" type="submit">
                        저장
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default EditFlower;
