import React, { useEffect, useState } from 'react';
import ModalLayout from '../../../Components/Modal/ModalLayout';
import { Box } from '@mui/system';
import { Controller } from 'react-hook-form';
import { MenuItem, Select, TextField, Button } from '@mui/material';
import ErrorMessage from '../../../Components/Form/ErrorMessage';
import { addHyphenPhone, checkIsValidPhone } from './../../../common/util';
import styled from 'styled-components';
import DialogModal from '../../../Components/Modal/DialogModal';

const Wrapper = styled.div`
    h5 {
        font-weight: 500;
        font-size: 16px;
        text-align: center;
        margin: 5px 0 15px;
    }

    .input-wrapper {
        margin: 0 40px 30px;
        display: flex;
        flex-direction: column;

        .input-layout {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 500;
                padding: 16px;
                width: 90px;
            }
            .content {
                width: 287px;
                padding: 6px;
            }
        }
    }

    .action-buttons {
        padding: 10px 20px 20px;
        border-top: 1px solid #e0e0e0;
        display: flex;
        justify-content: space-between;

        .action-buttons-right {
            display: flex;
            gap: 10px;
        }
    }
`;

function InputLayout({ title, children }) {
    return (
        <div className="input-layout">
            <div className="title">{title}</div>
            <div className="content">{children}</div>
        </div>
    );
}

function VehicleEditModal({ open, close, form, vehicle, mode }) {
    const {
        handleSubmit,
        control,
        setValue,
        reset,
        watch,
        formState: { errors },
    } = form;
    const [isOpenDeleteVehicle, setIsOpenDeleteVehicle] = useState(false);
    const isFilledInput = (() => {
        const { name, phone, area1, area2 } = watch();
        return name && phone && area1 && area2;
    })();
    const onSubmit = (data) => {
        console.log(data);
    };

    useEffect(() => {
        if (mode === 'edit' && vehicle) {
            reset({
                name: vehicle.name,
                phone: vehicle.phone,
                area1: vehicle.area1,
                area2: vehicle.area2,
            });
        }

        if (!open) {
            reset({});
        }
    }, [open]);

    return (
        <ModalLayout open={open} close={close}>
            <Wrapper>
                <h5>장의 차량</h5>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="modal-body">
                        <div className="input-wrapper">
                            <InputLayout title="업체명">
                                <Controller
                                    name="name"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        required: '업체명을 입력해주세요.',
                                    }}
                                    render={({
                                        field,
                                        fieldState: { errors },
                                    }) => (
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            {...field}
                                        />
                                    )}
                                />
                            </InputLayout>
                            <InputLayout title="전화번호">
                                <Controller
                                    name="phone"
                                    control={control}
                                    defaultValue=""
                                    rules={{
                                        validate: {
                                            isCorrectPattern: (v) =>
                                                checkIsValidPhone(v) ||
                                                '전화번호를 양식에 맞게 입력해주세요.',
                                        },
                                        onChange: (e) => {
                                            const value = e.target.value;
                                            setValue(
                                                'phone',
                                                addHyphenPhone(value)
                                            );
                                        },
                                    }}
                                    render={({
                                        field,
                                        fieldState: { error },
                                    }) => (
                                        <TextField
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                            {...field}
                                        />
                                    )}
                                />
                            </InputLayout>
                            <InputLayout title="지역">
                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '5px',
                                        '& > div:first-of-type': {
                                            flex: 4,
                                        },
                                        '& > div:last-child': {
                                            flex: 6,
                                        },
                                    }}
                                >
                                    <Controller
                                        name="area1"
                                        control={control}
                                        defaultValue={''}
                                        rules={{
                                            required: '시/도를 선택해주세요.',
                                        }}
                                        render={({
                                            field,
                                            fieldState: { error },
                                        }) => (
                                            <Select
                                                {...field}
                                                displayEmpty
                                                size="small"
                                            >
                                                <MenuItem value="">
                                                    시/도
                                                </MenuItem>
                                                <MenuItem value="서울">
                                                    서울
                                                </MenuItem>
                                            </Select>
                                        )}
                                    />{' '}
                                    <Controller
                                        name="area2"
                                        control={control}
                                        defaultValue={''}
                                        rules={{
                                            required: '군/구를 선택해주세요.',
                                        }}
                                        render={({
                                            field,
                                            fieldState: { error },
                                        }) => (
                                            <Select
                                                {...field}
                                                displayEmpty
                                                size="small"
                                            >
                                                <MenuItem value="">
                                                    선택
                                                </MenuItem>
                                                <MenuItem value="강남구">
                                                    강남구
                                                </MenuItem>
                                                <MenuItem value="송파구">
                                                    송파구
                                                </MenuItem>
                                            </Select>
                                        )}
                                    />
                                </Box>
                            </InputLayout>
                            <ErrorMessage error={errors} />
                        </div>
                    </div>
                    <div className="action-buttons">
                        <div className="action-buttons-left">
                            {mode === 'edit' && (
                                <Button
                                    variant="outlined"
                                    size="large"
                                    color="error"
                                    onClick={() => {
                                        setIsOpenDeleteVehicle(true);
                                    }}
                                >
                                    삭제
                                </Button>
                            )}
                        </div>
                        <div className="action-buttons-right">
                            <Button
                                variant="outlined"
                                size="large"
                                color="primary2"
                                onClick={close}
                            >
                                취소
                            </Button>
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                                disabled={!isFilledInput}
                            >
                                저장
                            </Button>
                        </div>
                    </div>
                </form>

                <>
                    <DialogModal
                        open={isOpenDeleteVehicle}
                        close={() => setIsOpenDeleteVehicle(false)}
                        handleClickRightBtn={() => {
                            setIsOpenDeleteVehicle(false);
                            close();
                        }}
                        rightBtnProps={{ color: 'error' }}
                        rightBtnChildren="삭제"
                    >
                        삭제하시겠습니까?
                    </DialogModal>
                </>
            </Wrapper>
        </ModalLayout>
    );
}

export default VehicleEditModal;
