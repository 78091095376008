import React from 'react';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../Components/PageHeader';
import Title from './../../../Components/Title';
import NewsForm from './NewsForm';

function AddNews() {
    const navigate = useNavigate();

    return (
        <>
            <PageHeader
                title="소식 추가"
                breadcrumbItems={[{ name: '소식' }, { name: '소식 추가' }]}
            />
            <NewsForm
                onSubmit={() => {
                    navigate('/service/news');
                }}
            />
        </>
    );
}

export default AddNews;
