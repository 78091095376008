import {
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import DatePickerTable from '../../../Components/Table/DatePickerTable';
import RowTable from '../../../Components/Table/Row';
import Title from '../../../Components/Title';
import { useForm } from 'react-hook-form';
import Table from '../../../Components/Table/index';
import { useNavigate } from 'react-router-dom';
import { moneyWithCommas } from '../../../common/util';
import Pagination from '../../../Components/Table/Pagination';

function ManageClaim() {
    const navigate = useNavigate();
    const { control } = useForm();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const claims = [
        {
            id: 0,
            cemeteryDate: '2021.11.12 ~ 2021.11.14 (3일)',
            balinDate: '15일 오전 05시 30분',
            deceased: '배회호',
            totalPrice: 9500000,
            claimPrice: 0,
            payStatus: '0',
        },
        {
            id: 1,
            cemeteryDate: '2021.11.12 ~ 2021.11.14 (3일)',
            balinDate: '15일 오전 05시 30분',
            deceased: '배회호',
            totalPrice: 9500000,
            claimPrice: 5400000,
            payStatus: '1',
        },
        {
            id: 2,
            cemeteryDate: '2021.11.12 ~ 2021.11.14 (3일)',
            balinDate: '15일 오전 05시 30분',
            deceased: '배회호',
            totalPrice: 9500000,
            claimPrice: 5400000,
            payStatus: '1',
        },
    ];

    const claimTableCellProps = [
        { align: 'center' },
        {},
        {},
        { align: 'center' },
        { align: 'right' },
        { align: 'right' },
        { align: 'center' },
    ];

    return (
        <>
            <Title header={{ title: '청구 관리' }} />
            <DatePickerTable
                title={'기간'}
                title2={'고인'}
                control={control}
                select
            />
            <Box
                sx={{
                    minWidth: 120,
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">납부상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                        <MenuItem value={2}>완납</MenuItem>
                        <MenuItem value={3}>미납</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    { content: '장례기간' },
                    { content: '발인' },
                    { content: '고인명' },
                    { content: '총 비용' },
                    { content: '청구금액' },
                    { content: '납부상태' },
                ]}
                tableCellProps={claimTableCellProps}
                items={claims.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate(`${idx}`);
                        }}
                    >
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },
                            {
                                content: ele.cemeteryDate,
                            },
                            { content: ele.balinDate },
                            {
                                content: ele.deceased,
                            },
                            { content: moneyWithCommas(ele.totalPrice) },
                            {
                                content:
                                    ele.payStatus === '0' ? (
                                        moneyWithCommas(ele.claimPrice)
                                    ) : ele.payStatus === '1' ? (
                                        <Box color="primary5.main">
                                            {moneyWithCommas(ele.claimPrice)}
                                        </Box>
                                    ) : (
                                        ''
                                    ),
                            },
                            {
                                content:
                                    ele.payStatus === '0' ? (
                                        '완납'
                                    ) : ele.payStatus === '1' ? (
                                        <Box color="primary5.main">미납</Box>
                                    ) : (
                                        ''
                                    ),
                            },
                        ].map(({ content }, idx, columns) => (
                            <TableCell key={idx} {...claimTableCellProps[idx]}>
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
        </>
    );
}
export default ManageClaim;
