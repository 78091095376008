import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function useGetLoginStatus() {
    const { token, me } = useSelector((state) => state.user);
    const [loginStatus, setLoginStatus] = useState({
        isLoading: Boolean(token && !(me && me.id)),
        isSuccess: Boolean(token && me && me.id),
        isFail: Boolean(!token),
    });

    useEffect(() => {
        setLoginStatus({
            isLoading: Boolean(token && !(me && me.id)),
            isSuccess: Boolean(token && me && me.id),
            isFail: Boolean(!token),
        });
    }, [token, me]);

    return loginStatus;
}

export default useGetLoginStatus;
