import { FormHelperText } from '@mui/material';
import React, { useCallback } from 'react';

function ErrorMessage({ error }) {
    // 에러가 존재하는지 여부
    const isHaveError =
        error &&
        typeof error === 'object' &&
        [...Object.keys(error)].length > 0;

    const getErrorMessage = useCallback((error) => {
        // message가 담겨진 error객체 형태인경우
        if (
            Object.prototype.hasOwnProperty.call(error, 'type') &&
            Object.prototype.hasOwnProperty.call(error, 'ref') &&
            Object.prototype.hasOwnProperty.call(error, 'message')
        ) {
            return error.message;
        }
        // 여러개의 error객체가 담겨져 있는경우 1번째 key에 접근
        return getErrorMessage(error[[...Object.keys(error)][0]]);
    }, []);
    return (
        <>
            {isHaveError && (
                <FormHelperText sx={{ px: '14px' }} error>
                    {getErrorMessage(error)}
                </FormHelperText>
            )}
        </>
    );
}

export default ErrorMessage;
