import {
    Button,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import PageHeader from '../../../Components/PageHeader';
import Title from '../../../Components/Title';
import TermForm from './TermForm';

function EditTerm() {
    const { control, watch } = useForm();

    return (
        <>
            <PageHeader
                title="약관 수정"
                breadcrumbItems={[{ name: '약관' }, { name: '약관 수정' }]}
            />
            <TermForm onSubmit={() => {}} />
        </>
    );
}

export default EditTerm;
