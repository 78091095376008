import ColumnTable from '../../../Components/Table/Colunm';
import Title from '../../../Components/Title';
import {
    Box,
    Button,
    FormControl,
    Select,
    MenuItem,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { TERM_TYPES } from './index';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Editor from '../../../Components/Editor';

function TermForm({ onSubmit }) {
    const { control, watch, handleSubmit, reset } = useForm();
    const navigate = useNavigate();

    const { title, content, target } = watch();
    const isFilledInput = (() => {
        const isCheckTarget =
            target &&
            [...Object.keys(target)].filter((key) => target[key]).length > 0;

        return title !== '' && content !== '' && isCheckTarget;
    })();

    const terms = useMemo(
        () => ({
            [TERM_TYPES.USER_SERVICE]: {
                type: TERM_TYPES.USER_SERVICE,
                title: '사용자 서비스 이용약관',
                content:
                    '<h1><strong>사용자 서비스 이용약관 자주하는 질문 내용</strong></h1>',
                target: {
                    family: true,
                    director: true,
                    funeralHall: false,
                    cemetery: true,
                },
            },
            [TERM_TYPES.USER_PRIVACY]: {
                type: TERM_TYPES.USER_PRIVACY,
                title: '사용자 개인정보 취급방침',
                content:
                    '<h1><strong>사용자 개인정보 취급방침 자주하는 질문 내용</strong></h1>',
                target: {
                    family: true,
                    director: true,
                    funeralHall: false,
                    cemetery: true,
                },
            },
            [TERM_TYPES.USER_THIRD_PARTY_INFO]: {
                type: TERM_TYPES.USER_THIRD_PARTY_INFO,
                title: '사용자 제3자 정보 제공동의',
                content:
                    '<h1><strong>사용자 제3자 정보 제공동의 자주하는 질문 내용</strong></h1>',
                target: {
                    family: true,
                    director: true,
                    funeralHall: false,
                    cemetery: true,
                },
            },
            [TERM_TYPES.USER_LOCATION]: {
                type: TERM_TYPES.USER_LOCATION,
                title: '사용자 위치정보 이용약관',
                content:
                    '<h1><strong>사용자 위치정보 이용약관 자주하는 질문 내용</strong></h1>',
                target: {
                    family: true,
                    director: true,
                    funeralHall: false,
                    cemetery: true,
                },
            },
            [TERM_TYPES.PARTNER]: {
                type: TERM_TYPES.PARTNER,
                title: '협력사 이용약관',
                content:
                    '<h1><strong>협력사 이용약관 자주하는 질문 내용</strong></h1>',
                target: {
                    family: true,
                    director: true,
                    funeralHall: false,
                    cemetery: true,
                },
            },
            [TERM_TYPES.PARTNER_PRIVACY]: {
                type: TERM_TYPES.PARTNER_PRIVACY,
                title: '협력사 개인정보 취급방침',
                content:
                    '<h1><strong>협력사 개인정보 취급방침 자주하는 질문 내용</strong></h1>',
                target: {
                    family: true,
                    director: true,
                    funeralHall: false,
                    cemetery: true,
                },
            },
        }),
        []
    );

    const selectTermType = watch('type', TERM_TYPES.USER_SERVICE);
    const [term, setTerm] = useState(terms[selectTermType]);

    const columnContents = [
        {
            title: '제목',
            content: (
                <Controller
                    control={control}
                    name="type"
                    defaultValue={term && term.type}
                    render={({ field }) => (
                        <FormControl
                            sx={{ mb: '10px', minWidth: 270 }}
                            size="small"
                        >
                            <Select
                                labelId="demo-select-small"
                                id="demo-select-small"
                                sx={{ minHeight: 40 }}
                                {...field}
                            >
                                <MenuItem value={TERM_TYPES.USER_SERVICE}>
                                    사용자 서비스 이용약관
                                </MenuItem>
                                <MenuItem value={TERM_TYPES.USER_PRIVACY}>
                                    사용자 개인정보 취급방침
                                </MenuItem>
                                <MenuItem
                                    value={TERM_TYPES.USER_THIRD_PARTY_INFO}
                                >
                                    사용자 제3자 정보 제공동의
                                </MenuItem>
                                <MenuItem value={TERM_TYPES.USER_LOCATION}>
                                    사용자 위치정보 이용약관
                                </MenuItem>
                                <MenuItem value={TERM_TYPES.PARTNER}>
                                    협력사 이용약관
                                </MenuItem>
                                <MenuItem value={TERM_TYPES.PARTNER_PRIVACY}>
                                    협력사 개인정보 취급방침
                                </MenuItem>
                            </Select>
                        </FormControl>
                    )}
                />
            ),
        },
        {
            title: '내용',
            content: (
                <Controller
                    control={control}
                    name="content"
                    defaultValue={term && term.content}
                    render={({ field: { value, onChange } }) => (
                        <Editor value={value} onChange={onChange} />
                    )}
                />
            ),
        },
        {
            title: '대상',
            content: (
                <>
                    <Controller
                        control={control}
                        name="target.family"
                        defaultValue={Boolean(term && term.target.family)}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                    />
                                }
                                label="유족"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="target.director"
                        defaultValue={Boolean(term && term.target.director)}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                    />
                                }
                                label="장례지도사"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="target.funeralHall"
                        defaultValue={Boolean(term && term.target.funeralHall)}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                    />
                                }
                                label="장례식장"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="target.cemetery"
                        defaultValue={Boolean(term && term.target.cemetery)}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                    />
                                }
                                label="장지"
                            />
                        )}
                    />
                </>
            ),
        },
    ].map((ele) => ({
        ...ele,
        contentSx: {
            padding: '6px',
        },
    }));

    useEffect(() => {
        setTerm(terms[selectTermType]);
    }, [selectTermType, terms]);

    useEffect(() => {
        // term값 input value에 채우기
        if (term) {
            reset(term);
        }
    }, [reset, term]);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ColumnTable columnContents={columnContents} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: '10px',
                }}
            >
                <Button
                    variant="outlined"
                    size="large"
                    color="secondary"
                    sx={{ mr: '15px' }}
                    onClick={() => {
                        navigate('/service/terms');
                    }}
                >
                    취소
                </Button>
                <Button
                    variant="contained"
                    size="large"
                    type="submit"
                    disabled={!isFilledInput}
                >
                    저장
                </Button>
            </Box>
        </form>
    );
}

export default TermForm;
