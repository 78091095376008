import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const BreadcrumbWrapper = styled.nav`
    ul {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        padding: 5px 15px;

        font-size: 12px;
        color: rgba(0, 0, 0, 0.6);

        .current {
            color: rgba(0, 0, 0, 0.87);
        }
    }
`;

function Breadcrumb({ items }) {
    return (
        <>
            {items && (
                <BreadcrumbWrapper>
                    <ul>
                        {items.map(({ name, url = null }, idx) => {
                            // 마지막 menu idx
                            const lastItemIdx = items.length - 1;
                            // url맨끝 슬래시 제거
                            const relativeUrl = `./${'../'.repeat(
                                lastItemIdx - idx
                            )}`.slice(0, -1);

                            return (
                                <React.Fragment key={idx}>
                                    <li>
                                        {idx < lastItemIdx ? (
                                            <Link to={url || relativeUrl}>
                                                {name}
                                            </Link>
                                        ) : (
                                            <div className="current">
                                                {name}
                                            </div>
                                        )}
                                    </li>
                                    {idx < lastItemIdx && (
                                        <ChevronRightIcon
                                            sx={{
                                                color: 'rgba(0,0,0,0.54)',
                                                fontSize: '20px',
                                            }}
                                        />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </ul>
                </BreadcrumbWrapper>
            )}
        </>
    );
}

export default Breadcrumb;
