const menuItem = {
    type: 'group',
    children: [
        {
            title: '관리자 계정관리',
            type: 'item',
            url: '/account',
            breadcrumbs: false,
        },
        {
            title: '회원관리',
            type: 'item',
            url: '/user',
            breadcrumbs: false,
        },
        {
            title: '협력사 관리',
            type: 'collapse',
            includePaths: [
                'partner/funeral/add',
                'partner/funeral/:funeralId',
                'partner/flower/:flowerId',
                'partner/flower/:flowerId/edit',
                'partner/gift/:giftId',
                'partner/gift/:giftId/edit',
            ],
            children: [
                {
                    title: '장례지도사',
                    type: 'item',
                    url: 'partner/director',
                    breadcrumbs: false,
                },
                {
                    title: '장례식장',
                    type: 'item',
                    url: 'partner/funeral',
                    breadcrumbs: false,
                },
                {
                    title: '장지',
                    type: 'item',
                    url: 'partner/tomb',
                    breadcrumbs: false,
                },
                {
                    title: '장의차량',
                    type: 'item',
                    url: 'partner/vehicle',
                    breadcrumbs: false,
                },
                {
                    title: '근조화환',
                    type: 'item',
                    url: 'partner/flower',
                    breadcrumbs: false,
                },
                {
                    title: '답례품',
                    type: 'item',
                    url: 'partner/gift',
                    breadcrumbs: false,
                },
            ],
        },
        {
            title: '견적 관리',
            type: 'collapse',
            includePaths: [
                'estimate/funeral/:funeralEstimateId',
                'estimate/burial/:burialEstimateId',
            ],
            children: [
                {
                    title: '장례식',
                    type: 'item',
                    url: 'estimate/funeral',
                    breadcrumbs: false,
                },
                {
                    title: '발인',
                    type: 'item',
                    url: 'estimate/burial',
                    breadcrumbs: false,
                },
            ],
        },
        {
            title: '예약 관리',
            type: 'collapse',
            children: [
                {
                    title: '장례식',
                    type: 'item',
                    url: 'reserve/funeral',
                    breadcrumbs: false,
                },
                {
                    title: '발인',
                    type: 'item',
                    url: 'reserve/burial',
                    breadcrumbs: false,
                },
            ],
        },
        {
            title: '장례관리',
            type: 'item',
            url: 'funeral',
            breadcrumbs: false,
        },
        {
            title: '주문/배송 관리',
            type: 'collapse',
            includePaths: ['order/flower/:flowerId', 'order/gift/:giftId'],
            children: [
                {
                    title: '근조화환',
                    type: 'item',
                    url: 'order/flower',
                    breadcrumbs: false,
                },
                {
                    title: '답례품',
                    type: 'item',
                    url: 'order/gift',
                    breadcrumbs: false,
                },
            ],
        },
        {
            title: '상품관리',
            type: 'collapse',
            includePaths: ['product/flower/:flowerId', 'product/gift/:giftId'],
            children: [
                {
                    title: '근조화환',
                    type: 'item',
                    url: 'product/flower',
                    breadcrumbs: false,
                },
                {
                    title: '답례품',
                    type: 'item',
                    url: 'product/gift',
                    breadcrumbs: false,
                },
            ],
        },
        {
            title: '장지 서비스',
            type: 'collapse',
            includePaths: [
                'cemetery/payment/:paymentId',
                'cemetery/request/:requestId',
            ],
            children: [
                {
                    title: '납부관리',
                    type: 'item',
                    url: 'cemetery/payment',
                    breadcrumbs: false,
                },
                {
                    title: '요청 관리',
                    type: 'item',
                    url: 'cemetery/request',
                    breadcrumbs: false,
                },
                {
                    title: '온라인 추모',
                    type: 'item',
                    url: 'cemetery/commemorate',
                    breadcrumbs: false,
                },
            ],
        },
        {
            title: '재무관리',
            type: 'collapse',
            includePaths: [
                'financial/claim/:claimId',
                'financial/withdraw/:withdrawId',
                'financial/settlement/:settlementId',
                'financial/payment/:paymentId',
            ],
            children: [
                {
                    title: '청구관리',
                    type: 'item',
                    url: 'financial/claim',
                    breadcrumbs: false,
                },
                {
                    title: '출금관리',
                    type: 'item',
                    url: 'financial/withdraw',
                    breadcrumbs: false,
                },
                {
                    title: '정산관리',
                    type: 'item',
                    url: 'financial/settlement',
                    breadcrumbs: false,
                },
                {
                    title: '결재내역',
                    type: 'item',
                    url: 'financial/payment',
                    breadcrumbs: false,
                },
                {
                    title: '캐시관리',
                    type: 'item',
                    url: 'financial/cash',
                    breadcrumbs: false,
                },
            ],
        },
        {
            title: '컨텐츠 관리',
            type: 'item',
            url: 'content',
        },
        {
            title: '온라인 문의',
            type: 'item',
            url: 'inquire',
        },
        {
            title: '고객센터',
            type: 'collapse',
            includePaths: [
                'service/news/add',
                'service/news/:newsId',
                'service/news/:newsId/edit',
                'service/questions/add',
                'service/questions/:questionId',
                'service/questions/:questionId/edit',
                'service/terms/edit',
            ],
            children: [
                {
                    title: '소식',
                    type: 'item',
                    url: 'service/news',
                    breadcrumbs: false,
                },
                {
                    title: '자주하는 질문',
                    type: 'item',
                    url: 'service/questions',
                    breadcrumbs: false,
                },
                {
                    title: '약관',
                    type: 'item',
                    url: 'service/terms',
                    breadcrumbs: false,
                },
            ],
        },
        {
            title: '메시지',
            type: 'item',
            url: 'message',
        },
        {
            title: '통계',
            type: 'item',
            url: 'statistics',
        },
        {
            title: 'Google Analytics',
            type: 'item',
        },
    ],
};

export default menuItem;
