import Title from '../../../Components/Title';
import GiftImage from './../../../resources/img/ic-gift.svg';
import OrignImage from './../../../resources/img/gift-origin.svg';
import OpenImage from './../../../resources/img/open.svg';
import { moneyWithCommas } from '../../../common/util';
import styled from 'styled-components';
import PageHeader from './../../../Components/PageHeader';
import ImageZoom from '../../../Components/ImageZoom';

const Wrapper = styled.div`
    h5 {
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        color: #191919;
    }
    h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: #303742;
        margin-bottom: 10px;
    }

    .m-b-5 {
        margin-bottom: 5px;
    }

    .container {
        margin-bottom: 45px;
        .wreath-img {
            width: 300px;
            height: 300px;
            border: 1px solid #e0e0e0;
        }
        .product-content {
            width: 100%;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            margin-left: 60px;
            padding: 55px 0;
            .price {
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                color: #333333;
                margin: 10px 0 55px 0;
            }
        }
        .img-box {
            width: 400px;
            position: relative;
            .open-btn {
                position: absolute;
                right: 10px;
                bottom: 10px;
                padding: 13px;
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }
`;

const delivery = 3000;

function GiftDetail() {
    return (
        <Wrapper>
            <PageHeader
                title="답례품 상품관리 상세"
                breadcrumbItems={[
                    { name: '답례품 상품관리' },
                    { name: '답례품 상품관리 상세' },
                ]}
            />
            <div className="container flex">
                <img className="wreath-img" src={GiftImage} alt="wreath" />
                <div className="product-content">
                    <h5>Lin 히말라야 핑크소름 200g</h5>
                    <div className="price">{moneyWithCommas(3500)}</div>
                    {delivery ? (
                        <div className="f-w-500 m-b-5">
                            배송비{moneyWithCommas(delivery)}
                        </div>
                    ) : (
                        <>
                            <div className="f-w-500 m-b-5">무료배송</div>
                            <li>오전 9시 ~오후 8시까지 3시간 이내 도착</li>
                            <li>
                                배송지역 엽체, 꽃 수급 상황, 계절에 따라
                                원산지가 달라질 수 있습니다.
                            </li>
                        </>
                    )}
                </div>
            </div>

            <div className="container">
                <h4>원산지</h4>
                <ImageZoom src={OrignImage} alt="원산지" />
                {/* <div className="img-box">
                    <img src={OrignImage} alt="원산지" />
                    <button className="open-btn">
                        <img src={OpenImage} alt="열기" />
                    </button>
                </div> */}
            </div>
        </Wrapper>
    );
}
export default GiftDetail;
