import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import DatePickerTable from '../../../Components/Table/DatePickerTable';
import Title from '../../../Components/Title';
import Pagination from '../../../Components/Table/Pagination';
import Table from '../../../Components/Table/index';
import { useNavigate } from 'react-router-dom';
import { moneyWithCommas } from '../../../common/util';

function ManagePayment() {
    const { control } = useForm();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();

    const payments = [
        {
            id: 0,
            cemetery: '화성하늘공원수목장림',
            deceased: '배희호',
            contractor: '배수진',
            item: '관리비',
            payment: '신용카드 3645************',
            price: 230000,
            dueDatePayment: '2021.11.25',
            remainDate: 12,
            status: '1',
        },
        {
            id: 1,
            cemetery: '(재)용인추모원 자연장지',
            deceased: '홍인수',
            contractor: '배수진',
            item: '이용료',
            payment: '신용카드 3645************',
            price: 3500000,
            dueDatePayment: '2021.11.20',
            remainDate: 7,
            status: '0',
        },
        {
            id: 2,
            cemetery: '별그리다(별의숲)',
            deceased: '오경선',
            contractor: '배수진',
            item: '이용료',
            payment: '신용카드 3645************',
            price: 3500000,
            dueDatePayment: '2022.02.06',
            remainDate: -245,
            status: '2',
        },
    ];

    const paymentTableCellProps = [
        { align: 'center' },
        {},
        { align: 'center' },
        { align: 'center' },
        { align: 'right' },
        { align: 'center' },
        { align: 'right' },
        { align: 'center' },
    ];

    return (
        <>
            <Title header={{ title: '납부관리' }} />
            <DatePickerTable
                title={'납부일'}
                title2={'고인'}
                control={control}
                select
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">배송상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                        <MenuItem value={2}>요청</MenuItem>
                        <MenuItem value={3}>예약됨</MenuItem>
                        <MenuItem value={4}>불발</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    { content: '장지' },
                    { content: '고인' },
                    { content: '납부항목' },
                    { content: '금액' },
                    { content: '납부기한' },
                    { content: '남은 기간' },
                    { content: '납부상태' },
                ]}
                tableCellProps={paymentTableCellProps}
                items={payments.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate(`${idx}`);
                        }}
                    >
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },
                            {
                                content: ele.cemetery,
                            },
                            {
                                content: ele.deceased,
                            },
                            {
                                content: ele.item,
                            },
                            {
                                content: moneyWithCommas(ele.price),
                            },
                            {
                                content: ele.dueDatePayment,
                            },
                            {
                                content: (
                                    <Box
                                        color={
                                            ele.remainDate < 0 &&
                                            'primary5.main'
                                        }
                                    >
                                        {ele.remainDate > 0
                                            ? '-'
                                            : ele.remainDate < 0
                                            ? '+'
                                            : ''}{' '}
                                        {Math.abs(ele.remainDate)}일
                                    </Box>
                                ),
                            },
                            {
                                content: (
                                    <Box
                                        color={
                                            ele.status === '2' &&
                                            'primary5.main'
                                        }
                                    >
                                        {ele.status === '0'
                                            ? '납부 완료'
                                            : ele.status === '1'
                                            ? '납부 전'
                                            : ele.status === '2'
                                            ? '미납'
                                            : ''}
                                    </Box>
                                ),
                            },
                        ].map(({ content }, idx, columns) => (
                            <TableCell
                                key={idx}
                                {...paymentTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
        </>
    );
}

export default ManagePayment;
