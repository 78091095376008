import React, { useState } from 'react';
import {
    TableCell,
    TableRow,
    TableContainer,
    Table as MuiTable,
    Typography,
    TableBody,
} from '@mui/material';
import { Box, styled } from '@mui/system';

import Title from '../../Components/Title';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Table from './../../Components/Table/index';
import { useNavigate } from 'react-router-dom';
import { moneyWithCommas } from '../../common/util';
import PageHeader from '../../Components/PageHeader';

const StyleTableCell = styled(TableCell)({
    minWidth: '145px',
    background: 'rgba(48, 55, 66, 0.07)',
    borderRight: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
    fontWeight: 500,
});

const StyleTypography = styled(Typography)({
    fontSize: '16px',
    fontWeight: '500',
    fontHeight: '23px',
    marginBottom: '10px',
});

function UserDetail() {
    const navigate = useNavigate();

    const useCemeteries = [
        {
            id: 0,
            range: '2021년 11월 14일 ~ 2036년 11월 13일 (15년)',
            decease: '홍경수',
            cemetery: '유토피아추모관',
            usePrice: 3500000,
            managePrice: 230000,
        },
    ];
    const useCemeteryTableCellProps = [
        { align: 'center' },
        {},
        { width: 120, align: 'center' },
        { width: 150, align: 'center' },
        { width: 150, align: 'center' },
        { width: 150, align: 'center' },
    ];
    const useFunerals = [
        {
            id: 0,
            range: '2021년 11월 14일 ~ 2036년 11월 13일 (15년)',
            decease: '홍경수',
            funeralHall: true,
            cemetery: true,
            crematorium: true,
            vehicle: true,
            price: 9550000,
        },
    ];
    const useFuneralTableCellProps = [
        { align: 'center' },
        {},
        { width: 120, align: 'center' },
        { width: 70, align: 'center', padding: 'none' },
        { width: 70, align: 'center', padding: 'none' },
        { width: 70, align: 'center', padding: 'none' },
        { width: 70, align: 'center', padding: 'none' },
        { width: 150, align: 'center' },
    ];

    return (
        <>
            <PageHeader
                title="회원 정보"
                breadcrumbItems={[{ name: '회원 관리' }, { name: '회원 정보' }]}
            />
            <StyleTypography>회원정보</StyleTypography>
            <TableContainer>
                <MuiTable
                    sx={{
                        borderWidth: '1px 0px',
                        borderStyle: 'solid',
                        borderColor: '#E0E0E0',
                        borderRight: '1px solid #F2F2F2',
                    }}
                >
                    <TableBody>
                        <TableRow>
                            <StyleTableCell>이름</StyleTableCell>
                            <TableCell sx={{ width: '40%' }}>
                                홍길동 (010-2345-6789)
                            </TableCell>
                            <StyleTableCell>가입방식</StyleTableCell>
                            <TableCell sx={{ width: '40%' }}>카카오</TableCell>
                        </TableRow>
                        <TableRow>
                            <StyleTableCell>가입일</StyleTableCell>
                            <TableCell>2021.05.11</TableCell>
                            <StyleTableCell>마지막 접속</StyleTableCell>
                            <TableCell>11일 전</TableCell>
                        </TableRow>
                    </TableBody>
                </MuiTable>
            </TableContainer>

            <Box my={'45px'}>
                <StyleTypography>고인 모심</StyleTypography>
                <Table
                    numeric
                    columns={[
                        { content: '이용기간' },
                        { content: '고인명' },
                        { content: '장지' },
                        { content: '이용료' },
                        { content: '관리비' },
                    ]}
                    tableCellProps={useCemeteryTableCellProps}
                    items={useCemeteries.map((ele, idx) => (
                        <TableRow
                            key={idx}
                            // onClick={() => navigate(`/user/${idx}`)}
                            sx={{ cursor: 'pointer' }}
                        >
                            {[
                                { content: idx + 1 },
                                { content: ele.range },
                                { content: ele.decease },
                                { content: ele.cemetery },
                                { content: moneyWithCommas(ele.usePrice) },
                                { content: moneyWithCommas(ele.managePrice) },
                            ].map(({ content }, idx) => (
                                <TableCell
                                    key={idx}
                                    {...useCemeteryTableCellProps[idx]}
                                >
                                    {content}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                />
            </Box>
            <Box>
                <StyleTypography>징례 진행</StyleTypography>
                <Table
                    numeric
                    columns={[
                        { content: '장례기간' },
                        { content: '고인명' },
                        { content: '장례식' },
                        { content: '장지' },
                        { content: '화장장' },
                        { content: '차량' },
                        { content: '금액' },
                    ]}
                    tableCellProps={useFuneralTableCellProps}
                    items={useFunerals.map((ele, idx) => (
                        <TableRow
                            key={idx}
                            // onClick={() => navigate(`/user/${idx}`)}
                            sx={{ cursor: 'pointer' }}
                        >
                            {[
                                { content: idx + 1 },
                                { content: ele.range },
                                { content: ele.decease },
                                {
                                    content: ele.funeralHall ? (
                                        <RadioButtonUncheckedIcon />
                                    ) : (
                                        ''
                                    ),
                                },
                                {
                                    content: ele.cemetery ? (
                                        <RadioButtonUncheckedIcon />
                                    ) : (
                                        ''
                                    ),
                                },
                                {
                                    content: ele.crematorium ? (
                                        <RadioButtonUncheckedIcon />
                                    ) : (
                                        ''
                                    ),
                                },
                                {
                                    content: ele.vehicle ? (
                                        <RadioButtonUncheckedIcon />
                                    ) : (
                                        ''
                                    ),
                                },
                                { content: moneyWithCommas(ele.price) },
                            ].map(({ content }, idx) => (
                                <TableCell
                                    key={idx}
                                    {...useFuneralTableCellProps[idx]}
                                >
                                    {content}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                />
            </Box>
        </>
    );
}
export default UserDetail;
