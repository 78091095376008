import PageHeader from '../../Components/PageHeader';
import Title from '../../Components/Title';
import MessageForm from './MessageForm';

function AddMessage() {
    const onSubmit = (data) => {};
    return (
        <>
            <PageHeader
                title="메세지 추가"
                breadcrumbItems={[{ name: '메세지' }, { name: '메세지 추가' }]}
            />
            <MessageForm onSubmit={onSubmit} />
        </>
    );
}

export default AddMessage;
