import {
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TableCell,
    TableRow,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import DatePickerTable from '../../../Components/Table/DatePickerTable';
import Title from '../../../Components/Title';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useForm } from 'react-hook-form';
import Table from '../../../Components/Table';
import Pagination from '../../../Components/Table/Pagination';
import { useNavigate } from 'react-router-dom';
import EditWreathOrderNotifyReceivePhoneModal from './EditWreathOrderNotifyReceivePhoneModal';
import { moneyWithCommas } from '../../../common/util';

function OrderFlower() {
    const { control: searchControl } = useForm();

    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const navigate = useNavigate();

    const Flowers = [
        {
            orderNum: 202111120001244,
            orderDate: '2021.11.12 21:31',
            product: '근조 3단 화환',
            price: 49000,
            sendName: '한습관',
            sendPhone: '010-2345-6789',
            receiveName: '故 홍경수',
            address: '서울특별시 동작구 동작경희병원 장례식장 5호실',
            receivePhone: '010-2345-6789',
            shippingStatus: 0,
            deliveryDate: '2021.11.12  23:02',
        },
        {
            orderNum: 202111120001244,
            orderDate: '2021.11.12 21:31',
            product: '근조 3단 화환',
            price: 49000,
            sendName: '한습관',
            sendPhone: '010-2345-6789',
            receiveName: '故 홍경수',
            address: '서울특별시 동작구 동작경희병원 장례식장 5호실',
            receivePhone: '010-2345-6789',
            shippingStatus: 1,
        },
    ];

    const OrderFlowerTableCellProps = [
        { width: '60px', align: 'center' },
        { width: '160px' },
        { width: '120px' },
        { width: '150px' },
        {},
        { width: '150px', align: 'center' },
    ];

    return (
        <>
            <Title header={{ title: '근조화환 주문관리' }} />
            <DatePickerTable
                title={'기간'}
                title2={'고인'}
                control={searchControl}
            />
            <Box
                sx={{
                    minWidth: 120,
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography fontWeight={500}>
                        자동 주문접수 (수발주 전송)
                    </Typography>
                    <FormControlLabel
                        control={
                            <Switch
                                defaultChecked
                                size="small"
                                color="primary4"
                            />
                        }
                        sx={{ margin: '0 20px 0 3px' }}
                    />
                    <Typography mr={1}>
                        주문알림 수신 휴대폰번호:010-2345-6789
                    </Typography>
                    <EditWreathOrderNotifyReceivePhoneModal />
                </Box>

                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">배송상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                        <MenuItem value={2}>요청</MenuItem>
                        <MenuItem value={3}>예약됨</MenuItem>
                        <MenuItem value={4}>불발</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    {
                        content: '주문번호/주문일',
                    },
                    {
                        content: '상품',
                    },
                    {
                        content: '보내는 분',
                    },
                    {
                        content: '받는 분',
                    },
                    {
                        content: '배송상태',
                    },
                ]}
                tableCellProps={OrderFlowerTableCellProps}
                items={Flowers.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/order/flower/${idx}`)}
                    >
                        {[
                            { content: page * itemsPerPage + idx + 1 },
                            {
                                content: (
                                    <>
                                        <Box fontWeight={500}>
                                            {ele.orderNum}
                                        </Box>
                                        <Box color={'#888888'}>
                                            {ele.orderDate}
                                        </Box>
                                    </>
                                ),
                            },
                            {
                                content: (
                                    <>
                                        <Box>{ele.product}</Box>
                                        <Box color={'#888888'}>
                                            {moneyWithCommas(ele.price)}
                                        </Box>
                                    </>
                                ),
                            },
                            {
                                content: (
                                    <>
                                        <Box fontWeight={500}>
                                            {ele.sendName}
                                        </Box>
                                        <Box color={'#888888'}>
                                            {ele.sendPhone}
                                        </Box>
                                    </>
                                ),
                            },
                            {
                                content: (
                                    <>
                                        <Box fontWeight={500}>
                                            {ele.receiveName}
                                        </Box>
                                        <Box color={'#888888'}>
                                            <li>{ele.address}</li>
                                            <li>{ele.receivePhone}</li>
                                        </Box>
                                    </>
                                ),
                            },
                            {
                                content:
                                    ele.shippingStatus === 0 ? (
                                        <>
                                            <Box sx={{ fontWeight: 500 }}>
                                                배송완료
                                            </Box>
                                            <Box sx={{ color: '#888888' }}>
                                                {ele.deliveryDate}
                                            </Box>
                                        </>
                                    ) : ele.shippingStatus === 1 ? (
                                        <Select
                                            value={1}
                                            fullWidth
                                            IconComponent={
                                                KeyboardArrowDownIcon
                                            }
                                            size="small"
                                            sx={{ height: '40px' }}
                                        >
                                            <MenuItem value={1}>
                                                주문완료
                                            </MenuItem>
                                        </Select>
                                    ) : (
                                        ''
                                    ),
                                props: {
                                    onClick: (e) => {
                                        e.stopPropagation();
                                    },
                                },
                            },
                        ].map(({ content, props }, idx) => (
                            <TableCell
                                key={idx}
                                sx={{ verticalAlign: 'top' }}
                                {...OrderFlowerTableCellProps[idx]}
                                {...props}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
        </>
    );
}
export default OrderFlower;
