import Title from '../Components/Title';
import { useState, useCallback } from 'react';
import ColumnTable from '../Components/Table/Colunm';
import PasswordModal from '../Components/Modal/Password';

function AdminInformation() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = useCallback(() => {
        setOpen(false);
    }, []);

    const columnContents = [
        {
            title: '이름',
            content: '홍길동',
        },
        {
            title: '아이디',
            content: 'Kildong',
        },
        {
            title: '비밀번호',
            content: (
                <button style={{ cursor: 'pointer' }} onClick={handleOpen}>
                    비밀번호 변경
                </button>
            ),
            color: '#007AFF',
        },
    ];
    return (
        <>
            <Title header={{ title: '관리자 정보' }} />
            <ColumnTable columnContents={columnContents} />
            <PasswordModal open={open} close={handleClose} />
        </>
    );
}

export default AdminInformation;
