import Breadcrumb from './Breadcrumb';
import PrevPageButton from './PrevPageButton';
import Title from './Title';

function PageHeader({
    title = '',
    breadcrumbItems = null,
    prevPageBtn = false,
}) {
    return (
        <>
            {breadcrumbItems && <Breadcrumb items={breadcrumbItems} />}
            <Title
                header={{ title }}
                defaultMt={Boolean(!breadcrumbItems)}
                defaultMb={Boolean(!prevPageBtn)}
            />
            {prevPageBtn && <PrevPageButton />}
        </>
    );
}

export default PageHeader;
