import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { moneyWithCommas } from '../../../common/util';
import DatePickerTable from '../../../Components/Table/DatePickerTable';
import Title from '../../../Components/Title';
import Table from '../../../Components/Table/index';
import Pagination from '../../../Components/Table/Pagination';
import ColumnTable from '../../../Components/Table/Colunm';
import PageHeader from '../../../Components/PageHeader';

function PaymentDetail() {
    const navigate = useNavigate();

    const payment = {
        id: 0,
        date: '2021.11.12 04:43',
        payer: '우상욱',
        price: 1000000,
        content: '장례비용 납부',
        payMethod: '입금',
        payStatusCd: 0,
    };

    const columnContents = [
        { title: '결제일', content: payment.date },
        { title: '결제자', content: payment.payer },
        { title: '내용', content: payment.content },
        { title: '금액', content: moneyWithCommas(payment.price) },
        { title: '결제방식', content: payment.payMethod },
        {
            title: '결제상태',
            content:
                payment.payStatusCd === 0
                    ? '결제완료'
                    : payment.payStatusCd === 1
                    ? '취소'
                    : payment.payStatusCd === 2
                    ? '캐쉬환불'
                    : '',
        },
    ];

    return (
        <>
            <PageHeader
                title="결재내역 상세"
                breadcrumbItems={[
                    { name: '결재내역' },
                    { name: '결재내역 상세' },
                ]}
            />
            <ColumnTable columnContents={columnContents} />
        </>
    );
}

export default PaymentDetail;
