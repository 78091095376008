import { TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Table from '../../Components/Table';
import DatePickerTable from '../../Components/Table/DatePickerTable';
import Title from '../../Components/Title';
import Pagination from './../../Components/Table/Pagination';

function OnlineCommemorate() {
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const { control } = useForm();
    const navigate = useNavigate();

    const commemorates = [
        {
            id: 0,
            createdDate: '2021.11.25',
            cemetery: '화성하늘공원수목장림',
            deceased: '배회호',
            content:
                '11월의 마지막 날이야. 이번 겨울에는 온 세상에 하얀 이불 덮어 줄 눈 이 많이 온',
        },
        {
            id: 1,
            createdDate: '2021.11.25',
            cemetery: '(재)용인추모원 자연장지',
            deceased: '홍인수',
            content: '보고 싶습니다.',
        },
    ];

    const commemorateTableCellProps = [
        { align: 'center' },
        { align: 'center' },
        {},
        { align: 'center' },
        {},
    ];

    return (
        <>
            <Title header={{ title: '온라인 추모' }} />
            <DatePickerTable
                title={'납부일'}
                title2={'고인'}
                control={control}
            />
            <Table
                numeric
                reverse
                columns={[
                    { content: '기록일' },
                    { content: '장지' },
                    { content: '고인' },
                    { content: '내용' },
                ]}
                tableCellProps={commemorateTableCellProps}
                items={commemorates.map((ele, idx) => (
                    <TableRow key={idx}>
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },
                            {
                                content: ele.createdDate,
                            },
                            {
                                content: ele.cemetery,
                            },
                            {
                                content: ele.deceased,
                            },
                            {
                                content: ele.content,
                            },
                        ].map(({ content }, idx, columns) => (
                            <TableCell
                                key={idx}
                                {...commemorateTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
        </>
    );
}

export default OnlineCommemorate;
