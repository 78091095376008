import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToken, login, logout } from './../redux/reducer/user';

function useGetMe() {
    const dispatch = useDispatch();
    const { token } = useSelector((state) => state.user);

    useEffect(() => {
        // 토큰이 있는경우 토큰관련 초기화 작업 실행
        if (token) {
            dispatch(setToken(token));
        }
    }, [dispatch, token]);

    // 유저정보 가져오기
    useQuery(['getMe'], {
        queryFn: () => axios.get('admin/me').then((res) => res.data),
        onSuccess: (data) => {
            const { success, userInfo } = data;
            // 토큰이 유효하지 않을시 로그아웃 처리
            if (!success) {
                dispatch(logout);
            }
            // 유저정보 가져오기
            dispatch(login(userInfo));
        },
        // 토큰이 있는 상태에서만 활성화
        enabled: !!token,
    });
}

export default useGetMe;
