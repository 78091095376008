import React from 'react';
import { moneyWithCommas } from '../../../common/util';
import { Box } from '@mui/material';
import Title from '../../../Components/Title';
import ColumnTable from '../../../Components/Table/Colunm';
import PageHeader from './../../../Components/PageHeader';

function SettlementDetail() {
    const settlement = {
        id: 0,
        date: '2021.11.12',
        partnerType: '장례지도사',
        partnerName: '우상욱',
        price: 429000,
        bankAccount: '기업은행 01023456789 (예금주: 우상욱)',
        settlementStatusCd: '0',
        sendDate: '2021.11.12 09:25',
    };

    const columnContents = [
        { title: '협력사 종류', content: settlement.partnerType },
        { title: '협력사명', content: settlement.partnerName },
        { title: '정산날짜', content: settlement.date },
        { title: '정산금액', content: moneyWithCommas(settlement.price) },
        { title: '수령계좌', content: settlement.bankAccount },
        {
            title: '지급상태',
            content:
                settlement.settlementStatusCd === '0' ? (
                    `지급완료 (${settlement.sendDate})`
                ) : settlement.settlementStatusCd === '1' ? (
                    <Box color={'primary5.main'}>예정</Box>
                ) : (
                    ''
                ),
        },
    ];

    return (
        <>
            <PageHeader
                title="정산 관리 상세"
                breadcrumbItems={[
                    { name: '정산 관리' },
                    { name: '정산 관리 상세' },
                ]}
            />
            <ColumnTable columnContents={columnContents} />
        </>
    );
}

export default SettlementDetail;
