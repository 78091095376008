import React, { useEffect } from 'react';
import {
    BrowserRouter,
    Route,
    Router,
    Routes,
    useNavigate,
} from 'react-router-dom';
import DefaultPageLayout from './Layouts/DefaultPageLayout';
import Account from './Pages/ManageAccount';
import AddAdmin from './Pages/ManageAccount/AddAdmin';
import AdminInformation from './Pages/AdminInformation';
import Login from './Pages/Login';
import ManageUser from './Pages/ManageUser';
import FuneralDirector from './Pages/ManagePartner/FuneralDirector';
import './style/style.css';
import Funeral from './Pages/ManagePartner/Funeral';
import AddFuneral from './Pages/ManagePartner/Funeral/AddFuneral';
import FuneralDetail from './Pages/ManagePartner/Funeral/FuneralDetail';
import Tomb from './Pages/ManagePartner/Tomb';
import Vehicle from './Pages/ManagePartner/Vehicle';
import FuneralFlower from './Pages/ManagePartner/FuneralFlower';
import Gift from './Pages/ManagePartner/Gift';
import Estimate from './Pages/Estimate/Funeral';
import Burial from './Pages/Estimate/Burial';
import ReserveFuneral from './Pages/Reserve/Funeral';
import ReserveBurial from './Pages/Reserve/Burial';
import ManageFuneral from './Pages/ManageFuneral';
import OrderFlower from './Pages/Order/Flower';
import OrderGift from './Pages/Order/OrderGift';
import ProductsGift from './Pages/ManageProducts/Gift';
import ProductsFlower from './Pages/ManageProducts/Flower';
import ManagePayment from './Pages/FuneralService/ManagePayment';
import FuneralService from './Pages/FuneralService';
import ManageRequest from './Pages/FuneralService/ManageRequest';
import OnlineCommemorate from './Pages/FuneralService/OnlineCommemorate';
import ManageClaim from './Pages/ManageFinancial/Claim/ManageClaim';
import ManageWithdraw from './Pages/ManageFinancial/Withdraw/ManageWithdraw';
import ManageSettlement from './Pages/ManageFinancial/Settlement/ManageSettlement';
import PaymentHistory from './Pages/ManageFinancial/Payment/PaymentHistory';
import ManageCash from './Pages/ManageFinancial/ManageCash';
import ManageContent from './Pages/ManageContent';
import Inquire from './Pages/Inquire';
import News from './Pages/ServiceCenter/News';
import Questions from './Pages/ServiceCenter/Questions';
import Terms from './Pages/ServiceCenter/Term';
import AdminDetail from './Pages/ManageAccount/AdminDetail';
import AdminEdit from './Pages/ManageAccount/AdminEdit';
import UserDetail from './Pages/ManageUser/UserDetail';
import EstimateDetail from './Pages/Estimate/Funeral/EstimateDetail';
import useGetMe from './hooks/useGetMe';
import useGetLoginStatus from './hooks/useGetLoginStatus';
import { useSelector } from 'react-redux';
import DashBoard from './Pages/DashBoard';
import Message from './Pages/Message';
import MessageDetail from './Pages/Message/MessageDetail';
import BurialDetail from './Pages/Estimate/Burial/BurialDetail';
import ManageFuneralDetail from './Pages/ManageFuneral/ManageFuneralDetail';
import PaymentDetail from './Pages/ManageFinancial/Payment/PaymentDetail';
import SettlementDetail from './Pages/ManageFinancial/Settlement/SettlementDetail';
import WithdrawDetail from './Pages/ManageFinancial/Withdraw/WithdrawDetail';
import NewsDetail from './Pages/ServiceCenter/News/NewsDetail';
import AddNews from './Pages/ServiceCenter/News/AddNews';
import AddQuestion from './Pages/ServiceCenter/Questions/AddQuestion';
import QuestionDetail from './Pages/ServiceCenter/Questions/QuestionDetail';
import RequestDetail from './Pages/FuneralService/ManageRequest/RequestDetail';
import FuneralServicePaymentDetail from './Pages/FuneralService/ManagePayment/PaymentDetail';
import AddMessage from './Pages/Message/AddMessage';
import EditMessage from './Pages/Message/EditMessage';
import EditFlower from './Pages/ManagePartner/FuneralFlower/EditFlower';
import EditGift from './Pages/ManagePartner/Gift/EditGift';
import EditNews from './Pages/ServiceCenter/News/EditNews';
import EditQuestion from './Pages/ServiceCenter/Questions/EditQuestion';
import EditTerm from './Pages/ServiceCenter/Term/EditTerm';
import OrderFlowerDetail from './Pages/Order/FlowerDetail';
import FlowerDetail from './Pages/ManageProducts/Flower/FlowerDetail';
import GiftDetail from './Pages/ManageProducts/Gift/GiftDetail';
import Statistics from './Pages/Statistics';
import OrderGiftDetail from './Pages/Order/GiftDetail';
import ClaimDetail from './Pages/ManageFinancial/Claim/ClaimDetail';

function App() {
    const loginStatus = useGetLoginStatus();
    const navigate = useNavigate();
    useGetMe();
    const me = useSelector((state) => state.user);

    useEffect(() => {
        // 로그아웃 상태일때 로그인 페이지로 이동
        if (loginStatus.isFail) {
            navigate('/login');
        }
    }, [loginStatus, navigate]);

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<DefaultPageLayout />}>
                {/* 관리자 정보 */}
                <Route path="/" element={<DashBoard />} />
                <Route
                    path="admin-information"
                    element={<AdminInformation />}
                />
                <Route path="info" element={<AdminInformation />} />

                {/* 관리자 계정관리 */}
                <Route path="account" element={<Account />} />
                <Route path="account/add" element={<AddAdmin />} />
                <Route path="account/:accountId" element={<AdminDetail />} />
                <Route path="account/:accountId/edit" element={<AdminEdit />} />
                {/* 회원관리 */}
                <Route path="user" element={<ManageUser />} />
                <Route path="user/:userId" element={<UserDetail />} />
                {/* 협력사 관리 */}
                <Route path="partner/director" element={<FuneralDirector />} />
                {/* 협력사 관리 - 장례식장 */}
                <Route path="partner/funeral" element={<Funeral />} />
                <Route path="partner/funeral/add" element={<AddFuneral />} />
                <Route
                    path="partner/funeral/:funeralId"
                    element={<FuneralDetail />}
                />
                <Route path="partner/tomb" element={<Tomb />} />
                <Route path="partner/vehicle" element={<Vehicle />} />
                <Route path="partner/flower" element={<FuneralFlower />} />
                <Route
                    path="partner/flower/:flowerId"
                    element={<FuneralFlower />}
                />
                <Route
                    path="partner/flower/:flowerId/edit"
                    element={<EditFlower />}
                />
                <Route path="partner/gift" element={<Gift />} />
                <Route path="partner/gift/:giftId" element={<Gift />} />
                <Route
                    path="partner/gift/:giftId/edit"
                    element={<EditGift />}
                />
                {/* 견적 관리 */}
                <Route path="estimate/funeral" element={<Estimate />} />
                <Route
                    path="estimate/funeral/:funeralEstimateId"
                    element={<EstimateDetail />}
                />
                <Route path="estimate/burial" element={<Burial />} />
                <Route
                    path="estimate/burial/:burialEstimateId"
                    element={<BurialDetail />}
                />
                {/* 예약 관리 */}
                <Route path="reserve/funeral" element={<ReserveFuneral />} />
                <Route path="reserve/burial" element={<ReserveBurial />} />
                {/* 장례관리 */}
                <Route path="funeral" element={<ManageFuneral />} />
                <Route
                    path="funeral/:userId"
                    element={<ManageFuneralDetail />}
                />
                {/* 주문/배송 관리 */}
                <Route path="order/flower" element={<OrderFlower />} />
                <Route
                    path="order/flower/:flowerId"
                    element={<OrderFlowerDetail />}
                />
                <Route path="order/gift" element={<OrderGift />} />
                <Route
                    path="order/gift/:giftId"
                    element={<OrderGiftDetail />}
                />
                {/* 상품관리 */}
                <Route path="product/flower" element={<ProductsFlower />} />
                <Route
                    path="product/flower/:flowerId"
                    element={<FlowerDetail />}
                />
                <Route path="product/gift" element={<ProductsGift />} />
                <Route path="product/gift/:giftId" element={<GiftDetail />} />
                {/* 장지 서비스 */}
                <Route path="cemetery/payment" element={<ManagePayment />} />
                <Route
                    path="cemetery/payment/:paymentId"
                    element={<FuneralServicePaymentDetail />}
                />
                <Route path="cemetery/request" element={<ManageRequest />} />
                <Route
                    path="cemetery/request/:requestId"
                    element={<RequestDetail />}
                />
                <Route
                    path="cemetery/commemorate"
                    element={<OnlineCommemorate />}
                />
                {/* 재무관리 */}
                <Route path="financial/claim" element={<ManageClaim />} />
                <Route
                    path="financial/claim/:claimId"
                    element={<ClaimDetail />}
                />
                <Route path="financial/withdraw" element={<ManageWithdraw />} />
                <Route
                    path="financial/withdraw/:withdrawId"
                    element={<WithdrawDetail />}
                />
                <Route
                    path="financial/settlement"
                    element={<ManageSettlement />}
                />
                <Route
                    path="financial/settlement/:settlementId"
                    element={<SettlementDetail />}
                />
                <Route path="financial/payment" element={<PaymentHistory />} />
                <Route
                    path="financial/payment/:paymentId"
                    element={<PaymentDetail />}
                />
                <Route path="financial/cash" element={<ManageCash />} />
                {/* 컨텐츠 관리 */}
                <Route path="content" element={<ManageContent />} />
                {/* 온라인 문의 */}
                <Route path="inquire" element={<Inquire />} />
                {/* 고객센터 */}
                <Route path="service/news" element={<News />} />
                <Route path="service/news/add" element={<AddNews />} />
                <Route path="service/news/:newsId" element={<NewsDetail />} />
                <Route
                    path="service/news/:newsId/edit"
                    element={<EditNews />}
                />
                <Route path="service/questions" element={<Questions />} />
                <Route path="service/questions/add" element={<AddQuestion />} />
                <Route
                    path="service/questions/:questionId"
                    element={<QuestionDetail />}
                />
                <Route
                    path="service/questions/:questionId/edit"
                    element={<EditQuestion />}
                />
                <Route path="service/terms" element={<Terms />} />
                <Route path="service/terms/edit" element={<EditTerm />} />
                {/* 메시지 */}
                <Route path="message" element={<Message />} />
                <Route path="message/:messageId" element={<MessageDetail />} />
                <Route
                    path="message/:messageId/edit"
                    element={<EditMessage />}
                />
                <Route path="message/add" element={<AddMessage />} />
                {/* 통계 */}
                <Route path="statistics" element={<Statistics />} />
                {/* Google Analytics */}
            </Route>
        </Routes>
    );
}

export default App;
