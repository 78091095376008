import React, { useCallback, useState } from 'react';
import { Controlled as MediumImageZoom } from 'react-medium-image-zoom';
import styled from 'styled-components';
import ZoomImage from './../resources/img/open.svg';
import 'react-medium-image-zoom/dist/styles.css';

const Wrapper = styled.div`
    display: inline-block;
    position: relative;

    img.zoom-image {
        object-fit: contain;
        object-position: center center;
        cursor: pointer;
    }

    [data-rmiz-modal-img] {
        cursor: pointer;
    }

    .zoom-btn {
        position: absolute;
        bottom: 10px;
        right: 10px;
        padding: 13px;
        background: rgba(0, 0, 0, 0.1);
    }
`;

function ImageZoom({ src, alt = '' }) {
    const [isZoomed, setIsZoomed] = useState(false);

    const handleZoomChange = useCallback((shouldZoom) => {
        setIsZoomed(shouldZoom);
    }, []);

    return (
        <Wrapper>
            <MediumImageZoom
                isZoomed={isZoomed}
                onZoomChange={handleZoomChange}
                zoomMargin={120}
            >
                <img
                    className="zoom-image"
                    src={src}
                    alt={alt}
                    loading={'lazy'}
                />
            </MediumImageZoom>
            <button
                className="zoom-btn"
                onClick={() => {
                    setIsZoomed(true);
                }}
            >
                <img src={ZoomImage} alt="zoom" />
            </button>
        </Wrapper>
    );
}

export default ImageZoom;
