import React, { useState } from 'react';

import {
    TableCell,
    TableRow,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import { Box } from '@mui/system';

import Title from '../../../Components/Title';
import { useNavigate } from 'react-router-dom';
import Table from '../../../Components/Table/index';
import Pagination from '../../../Components/Table/Pagination';
import ModalLayout from '../../../Components/Modal/ModalLayout';
import VehicleEditModal from './VehicleEditModal';
import { useForm } from 'react-hook-form';

function Vehicle() {
    const form = useForm();
    const { reset } = form;
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();

    const [vehicle, setVehicle] = useState(null);
    const [isOpenVehicleEdit, setIsOpenVehicleEdit] = useState(false);
    const [isOpenVehicleAdd, setIsOpenVehicleAdd] = useState(false);

    const vehicles = [
        {
            id: 0,
            area1: '서울',
            area2: '송파구',
            name: '리모클럽',
            phone: '02-578-5785',
        },
        {
            id: 1,
            area1: '서울',
            area2: '강남구',
            name: '장의버스대여',
            phone: '02-578-5785',
        },
        {
            id: 2,
            area1: '서울',
            area2: '강남구',
            name: '캐딜락운구차',
            phone: '02-578-5785',
        },
    ];

    const vehicleTableCellProps = [
        { align: 'center' },
        {},
        {},
        { sx: { width: '150px' } },
    ];

    return (
        <>
            <Title header={{ title: '장의 차량' }} />
            <Box
                sx={{
                    minWidth: 120,
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">운영 상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    { content: '지역' },
                    { content: '업체명' },
                    { content: '전화번호' },
                ]}
                tableCellProps={vehicleTableCellProps}
                items={vehicles.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            setVehicle(ele);
                            setIsOpenVehicleEdit(true);
                        }}
                    >
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },
                            {
                                content: `${ele.area1} ${ele.area2}`,
                            },
                            { content: ele.name },
                            { content: ele.phone },
                        ].map(({ content }, idx, columns) => (
                            <TableCell
                                key={idx}
                                {...vehicleTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: '17px',
                    position: 'relative',

                    '& > div:first-of-type, & > div:last-child': {
                        flex: 1,
                    },

                    '& > div:last-child': {
                        textAlign: 'right',
                    },
                }}
            >
                <div />
                <Pagination count={10} page={page} handleChange={setPage} />
                <div>
                    <Button
                        variant="contained"
                        size="Large"
                        color="primary2"
                        onClick={() => {
                            setIsOpenVehicleAdd(true);
                        }}
                    >
                        업체 추가
                    </Button>
                </div>
            </Box>
            <>
                <VehicleEditModal
                    form={form}
                    open={isOpenVehicleEdit}
                    close={() => {
                        setIsOpenVehicleEdit(false);
                    }}
                    vehicle={vehicle}
                    mode="edit"
                />
                <VehicleEditModal
                    form={form}
                    open={isOpenVehicleAdd}
                    close={() => {
                        setIsOpenVehicleAdd(false);
                    }}
                    mode="add"
                />
            </>
        </>
    );
}

export default Vehicle;
