import { Box, TextField } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import ColumnTable from '../../Components/Table/Colunm';
import Editor from './../../Components/Editor/index';
import { Button } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';

function MessageForm({ message, onSubmit = () => {}, isEdit = false }) {
    const navigate = useNavigate();
    const { control, handleSubmit, watch } = useForm();
    const { messageId } = useParams();

    console.log(watch());

    const columnContents = [
        {
            title: '제목',
            content: (
                <Controller
                    control={control}
                    name="title"
                    defaultValue={message && message.title}
                    rules={{ required: '제목을 입력해주세요.' }}
                    render={({ field }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                        </>
                    )}
                />
            ),
        },
        {
            title: '문자',
            content: (
                <Controller
                    control={control}
                    name="message"
                    defaultValue={message && message.message}
                    rules={{ required: '문자를 입력해주세요.' }}
                    render={({ field }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                        </>
                    )}
                />
            ),
        },
        {
            title: '내용',
            content: (
                <Controller
                    control={control}
                    name="content"
                    defaultValue={message && message.content}
                    rules={{ required: '내용을 입력해주세요.' }}
                    render={({ field: { value, onChange } }) => (
                        <>
                            <Editor value={value} onChange={onChange} />
                        </>
                    )}
                />
            ),
        },
    ].map((ele) => ({
        ...ele,
        contentSx: {
            padding: '6px',
        },
    }));

    const isFilledInput = (() => {
        const { title, message, content } = watch();
        return title && message && content;
    })();

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ColumnTable columnContents={columnContents} />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    mt: '10px',
                    gap: '15px',
                }}
            >
                <Button
                    variant="outlined"
                    color="primary3"
                    onClick={() => {
                        if (isEdit) {
                            return navigate(`/message/${messageId}`);
                        }
                        navigate('/message');
                    }}
                >
                    취소
                </Button>
                <Button
                    variant="contained"
                    disabled={!isFilledInput}
                    type="submit"
                >
                    {isEdit ? '저장' : '추가'}
                </Button>
            </Box>
        </form>
    );
}

export default MessageForm;
