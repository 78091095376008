import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

const QuillWrapper = styled.div`
    .quill {
        background: #fff;
        .ql-container {
            font-family: 'Noto Sans KR', sans-serif;

            .ql-editor {
                min-height: 400px;
            }
        }
    }
`;

function Editor({ value, onChange }) {
    return (
        <QuillWrapper>
            <ReactQuill theme="snow" value={value} onChange={onChange} />
        </QuillWrapper>
    );
}

export default Editor;
