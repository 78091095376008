import React, { useState } from 'react';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TableRow,
    TableCell,
} from '@mui/material';

import { Box } from '@mui/system';

import Title from '../../../Components/Title';
import DatePickerTable from '../../../Components/Table/DatePickerTable';
import Table from '../../../Components/Table';
import Pagination from '../../../Components/Table/Pagination';

import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

function Estimate() {
    const { control } = useForm();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();

    const estimate = [
        {
            requestDate: '2021.11.05',
            funeralPeriod: '2021.11.12~2021.11.14 (3일)',
            HopeArea: '서울 성북구',
            deceased: '홍경수',
            requester: '홍길동',
            Estimate: 4,
        },
        {
            requestDate: '어제',
            funeralPeriod: '2021.11.12~2021.11.14 (3일)',
            HopeArea: '서울 구로구',
            deceased: '홍경수',
            requester: '홍길동',
            Estimate: 5,
        },
        {
            requestDate: '오늘',
            funeralPeriod: '2021.11.12~2021.11.14 (3일)',
            HopeArea: '서울 동작구',
            deceased: '홍경수',
            requester: '홍길동',
            Estimate: 0,
        },
    ];

    const estimateTableCellProps = [
        { width: '60px', align: 'center' },
        { width: '120px' },
        {},
        { width: '140px' },
        { width: '120px', align: 'center' },
        { width: '120px', align: 'center' },
        { width: '100px', align: 'center' },
    ];

    return (
        <>
            <Title header={{ title: '장례식 견적 관리' }} />
            <DatePickerTable title={'기간'} title2={'고인'} control={control} />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">운영 상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    {
                        content: '요청일',
                    },
                    {
                        content: '장례기간',
                    },
                    {
                        content: '희망지역',
                    },
                    {
                        content: '고인명',
                    },
                    {
                        content: '요청자',
                    },
                    {
                        content: '견적서',
                    },
                ]}
                tableCellProps={estimateTableCellProps}
                items={estimate.map((ele, idx) => (
                    <TableRow key={idx} onClick={() => navigate(`${idx}`)}>
                        {[
                            { content: page * itemsPerPage + (idx + 1) },
                            { content: ele.requestDate },
                            { content: ele.funeralPeriod },
                            { content: ele.HopeArea },
                            { content: ele.deceased },
                            { content: ele.requester },
                            { content: ele.Estimate },
                        ].map(({ content }, idx) => (
                            <TableCell
                                key={idx}
                                {...estimateTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ marginTop: '25px' }}
            />
        </>
    );
}
export default Estimate;
