import { Box, Button, TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import Title from '../../../../Components/Title';
import ColumnTable from '../../../../Components/Table/Colunm/index';
import styled from 'styled-components';
import DialogModal from '../../../../Components/Modal/DialogModal';
import useFuneralColumnContents from './hooks/useFuneralColumnContents';
import useClaimColumnContents from './hooks/useClaimColumnContents';
import useCostColumnContents from './hooks/useCostColumnContents';
import PageHeader from '../../../../Components/PageHeader';

const Wrapper = styled.div`
    .claim-wrapper {
        margin-bottom: 45px;

        .title {
            font-weight: 500;
            font-size: 16px;
            color: #303742;
            margin-bottom: 10px;
        }
    }

    .plus {
        color: #ff5160;
    }

    .minus {
        color: #485ba1;
    }

    .fw-b {
        font-weight: 500;
    }
    .fw-sb {
        font-weight: 700;
    }
`;

function ClaimDetail() {
    const [isCheckFullPayment, setIsCheckFullPayment] = useState(false);

    const estimate = {
        room: {
            name: '특2호실',
            price: 20000,
        },
        shrineFlower: {
            name: '꽃장식',
            price: 30000,
        },
        portrait: {
            name: '소',
            price: 100000,
        },
        manClothes: {
            name: '남성 상복',
            price: 30000,
            cnt: 2,
        },
        womanClothes: {
            name: '여성 상복',
            price: 30000,
            cnt: 2,
        },
        move: {
            name: '관내',
            price: 100000,
        },
        food: {
            name: '접객 음식',
            price: 12000,
        },
        wash: {
            name: '수시비',
            price: 100000,
        },
        sepulchre: {
            name: '안치',
            price: 100000,
        },
        shroud: {
            name: '염습비',
            price: 100000,
        },
        shroudClothes: {
            name: '수의',
            price: 30000,
        },
        coffin: {
            name: '오동나무관',
            price: 30000,
        },
        clean: {
            name: '청소 및 폐기물 처리',
            price: 100000,
        },
        manpowerDirector: {
            price: 250000,
            cnt: 1,
            days: 1,
        },
        manpowerHelper: {
            price: 9160,
            cnt: 1,
            hours: 1,
        },
        etc: [
            {
                name: '기타',
                price: 50000,
            },
        ],
    };

    const changeEstimate = {
        room: {
            name: '특1호실',
            price: 10000,
        },
        shrineFlower: {
            name: '꽃장식',
            price: '30000',
        },
        portrait: {
            name: '대',
            price: 0,
        },
        manClothes: {
            name: '남성 상복',
            price: 0,
            cnt: 1,
        },
        womanClothes: {
            name: '여성 상복',
            price: 0,
            cnt: 1,
        },
        move: {
            name: '관외',
            price: 0,
        },
        food: {
            name: '접객 음식',
            price: 0,
        },
        wash: {
            name: '수시비',
            price: 0,
        },
        sepulchre: {
            name: '안치',
            price: 0,
        },
        shroud: {
            name: '염습비',
            price: 0,
        },
        shroudClothes: {
            name: '수의',
            price: 30000,
        },
        coffin: {
            name: '오동나무관',
            price: 30000,
        },
        clean: {
            name: '청소 및 폐기물 처리',
            price: 0,
        },
        manpowerDirector: {
            price: 250000,
            cnt: 3,
            days: 1,
        },
        manpowerHelper: {
            price: 9160,
            cnt: 2,
            hours: 1,
        },
        etc: [],
    };

    const funeralColumnContents = useFuneralColumnContents();
    const claimColumnContents = useClaimColumnContents();
    const costColumnContents = useCostColumnContents({
        estimate,
        changeEstimate,
    });

    return (
        <Wrapper>
            <PageHeader
                title="청구 관리 상세"
                breadcrumbItems={[
                    { name: '청구 관리' },
                    { name: '청구 관리 상세' },
                ]}
            />
            <div className="claim-wrapper">
                <ColumnTable columnContents={funeralColumnContents} />
            </div>
            <div className="claim-wrapper">
                <div className="title">청구 내용</div>
                <ColumnTable columnContents={claimColumnContents} />
                <Box sx={{ mt: '10px', ml: 'auto', textAlign: 'end' }}>
                    <Button
                        variant="contained"
                        onClick={() => {
                            setIsCheckFullPayment(true);
                        }}
                    >
                        완납
                    </Button>
                </Box>
            </div>
            <div className="claim-wrapper">
                <div className="title">비용 내역</div>
                <ColumnTable columnContents={costColumnContents} />
            </div>

            <DialogModal
                open={isCheckFullPayment}
                close={() => {
                    setIsCheckFullPayment(false);
                }}
                rightBtnChildren={'완납'}
                handleClickRightBtn={() => {
                    setIsCheckFullPayment(false);
                }}
            >
                완납처리 하시겠습니까?
            </DialogModal>
        </Wrapper>
    );
}

export default ClaimDetail;
