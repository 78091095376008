import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { moneyWithCommas } from '../../../common/util';
import DatePickerTable from '../../../Components/Table/DatePickerTable';
import Title from '../../../Components/Title';
import Table from '../../../Components/Table/index';
import Pagination from '../../../Components/Table/Pagination';

function PaymentHistory() {
    const { control } = useForm();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const payments = [
        {
            id: 0,
            date: '2021.11.12 04:43',
            payer: '우상욱',
            price: 1000000,
            content: '장례비용 납부',
            payMethod: '입금',
            payStatusCd: 0,
        },
        {
            id: 0,
            date: '2021.11.12 04:43',
            payer: '우상욱',
            price: 1000000,
            content: '발인예약',
            payMethod: '신용카드 0102************',
            payStatusCd: 1,
        },
        {
            id: 0,
            date: '2021.11.12 04:43',
            payer: '이미소',
            price: 1000000,
            content: '장지 부가서비스',
            payMethod: '입금',
            payStatusCd: 2,
        },
    ];

    const paymentTableCellProps = [
        { align: 'center' },
        {},
        {},
        {},
        { align: 'right' },
        {},
        { align: 'center' },
    ];

    return (
        <>
            <Title header={{ title: '결재 내역' }} />
            <DatePickerTable
                title={'기간'}
                title2={'협력사명'}
                control={control}
                select
            />
            <Box
                sx={{
                    minWidth: 120,
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <FormControl
                    sx={{ m: '8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">결제종류</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                        <MenuItem value={2}>지급전</MenuItem>
                        <MenuItem value={3}>지급완료</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    { content: '결제일' },
                    { content: '결제자' },
                    { content: '내용' },
                    { content: '금액' },
                    { content: '결제방식' },
                    { content: '결제상태' },
                ]}
                tableCellProps={paymentTableCellProps}
                items={payments.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate(`${idx}`);
                        }}
                    >
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },
                            {
                                content: ele.date,
                            },
                            { content: ele.payer },
                            { content: ele.content },
                            {
                                content: moneyWithCommas(ele.price),
                            },
                            { content: ele.payMethod },
                            {
                                content:
                                    ele.payStatusCd === 0
                                        ? '결제완료'
                                        : ele.payStatusCd === 1
                                        ? '취소'
                                        : ele.payStatusCd === 2
                                        ? '캐시환불'
                                        : '',
                            },
                        ].map(({ content }, idx, columns) => (
                            <TableCell
                                key={idx}
                                {...paymentTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
        </>
    );
}

export default PaymentHistory;
