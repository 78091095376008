import React, { useState } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Switch,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
} from '@mui/material';
import { Box } from '@mui/material';
import Title from '../../Components/Title';
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom';
import PasswordModal from '../../Components/Modal/Password';
import ColumnTable from '../../Components/Table/Colunm';
import { Controller, useForm } from 'react-hook-form';
import { TextField } from '@mui/material';
import ErrorMessage from '../../Components/Form/ErrorMessage';
import { IndeterminateCheckBox } from '@mui/icons-material';
import PageHeader from '../../Components/PageHeader';

const checkbox = [
    { id: '1', label: '회원관리' },
    { id: '2', label: '협력사 관리' },
    { id: '3', label: '견적 관리' },
    { id: '4', label: '예약 관리' },
    { id: '5', label: '장례 관리' },
    { id: '6', label: '주문/배송 관리' },
    { id: '7', label: '상품 관리' },
    { id: '8', label: '장지 서비스' },
    { id: '9', label: '재무 관리' },
    { id: '10', label: '데이터 관리' },
    { id: '11', label: '컨텐츠 관리' },
    { id: '12', label: '온라인 관리' },
    { id: '13', label: '고객센터' },
    { id: '14', label: '메일링' },
    { id: '15', label: '메시지' },
    { id: '16', label: '통계' },
    { id: '17', label: 'Google Analytics' },
];

function AdminEdit() {
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm();

    const { accountId } = useParams();

    const onSubmit = (data) => {
        console.log(data);
    };

    const columnContents = [
        {
            title: '아이디',
            padding: '6px',
            content: (
                <Controller
                    control={control}
                    name="id"
                    defaultValue={'ijnbm455'}
                    rules={{ required: '아이디를 입력하세요.' }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '이름',
            padding: '6px',
            content: (
                <Controller
                    control={control}
                    name="name"
                    defaultValue={'kildong'}
                    rules={{ required: '이름을 입력하세요.' }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '비밀번호',
            content: (
                <>
                    ijnbm455
                    <Typography
                        component="span"
                        onClick={handleOpen}
                        sx={{ color: '#007AFF', ml: '24px' }}
                    >
                        비밀번호 변경
                    </Typography>
                </>
            ),
        },
        {
            title: '권한',
            padding: '6px',
            content: (
                <>
                    {checkbox.map((checkbox, idx) => (
                        <FormControlLabel
                            key={idx}
                            label={checkbox.label}
                            sx={{ margin: '0 10px 0 0', fontSize: '14px' }}
                            control={
                                <Checkbox
                                    size="small"
                                    style={{ color: 'rgba(0, 0, 0, 0.6)' }}
                                    checkedIcon={<IndeterminateCheckBox />}
                                />
                            }
                        />
                    ))}
                </>
            ),
        },
    ];

    return (
        <>
            <PageHeader
                title="관리자 계정관리"
                breadcrumbItems={[
                    { name: '관리자 계정관리' },
                    { name: '관리자 계정정보' },
                    { name: '관리자 수정' },
                ]}
                prevPageBtn
            />

            <form onSubmit={handleSubmit(onSubmit)}>
                <ColumnTable columnContents={columnContents} />
                <PasswordModal open={open} close={handleClose} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: '10px',
                    }}
                >
                    <Button
                        variant="outlined"
                        size="large"
                        color="error"
                        sx={{ mr: '15px' }}
                        onClick={() => navigate(`/account/${accountId}`)}
                    >
                        취소
                    </Button>
                    <Button variant="contained" size="large" type="submit">
                        완료
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default AdminEdit;
