import { useState } from 'react';
import { Button, TableRow, TableCell } from '@mui/material';
import { Box } from '@mui/system';
import Title from '../../../Components/Title';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import Table from '../../../Components/Table';
import { numberWithCommas } from '../../../common/util';
import Pagination from '../../../Components/Table/Pagination';
import { useNavigate } from 'react-router-dom';

function News() {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const newsList = [
        {
            id: 0,
            title: '서버 증설로 인한 안정적 환경 구축',
            target: {
                family: true,
                director: true,
                funeralHall: false,
                cemetery: true,
            },
            createdDate: '2021.11.12',
            view: 10098,
        },
        {
            id: 1,
            title: '묘적도 작성대행 신청',
            target: {
                family: true,
                director: false,
                funeralHall: false,
                cemetery: false,
            },
            createdDate: '2021.11.12',
            view: 950,
        },
    ];

    const newsListTableCellProps = [
        { align: 'center' },
        {},
        { align: 'center' },
        { align: 'center' },
        { align: 'center' },
        { align: 'center' },
        {},
        { align: 'center' },
    ];

    return (
        <>
            <Title header={{ title: '소식' }} />
            <Table
                numeric
                reverse
                tableCellProps={newsListTableCellProps}
                columns={[
                    { content: '제목' },
                    { content: '유족' },
                    { content: '장례지도사' },
                    { content: '장례식장' },
                    { content: '장지' },
                    { content: '등록일' },
                    { content: '뷰' },
                ]}
                items={newsList.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate(`${idx}`);
                        }}
                    >
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },
                            {
                                content: ele.title,
                            },
                            {
                                content: ele.target.family && (
                                    <RadioButtonUncheckedIcon
                                        fontSize="small"
                                        sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                                    />
                                ),
                            },
                            {
                                content: ele.target.director && (
                                    <RadioButtonUncheckedIcon
                                        fontSize="small"
                                        sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                                    />
                                ),
                            },
                            {
                                content: ele.target.funeralHall && (
                                    <RadioButtonUncheckedIcon
                                        fontSize="small"
                                        sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                                    />
                                ),
                            },
                            {
                                content: ele.target.cemetery && (
                                    <RadioButtonUncheckedIcon
                                        fontSize="small"
                                        sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                                    />
                                ),
                            },
                            { content: ele.createdDate },
                            { content: numberWithCommas(ele.view) },
                        ].map(({ content }, idx, columns) => (
                            <TableCell
                                key={idx}
                                {...newsListTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    mt: '17px',

                    '& > div': {
                        flex: 1,
                    },
                }}
            >
                <div />
                <Pagination count={10} page={page} handleChange={setPage} />
                <Box textAlign={'right'}>
                    <Button
                        variant="contained"
                        size="Large"
                        color="primary2"
                        onClick={() => {
                            navigate('add');
                        }}
                    >
                        소식 추가
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default News;
