import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { moneyWithCommas } from '../../common/util';
import DatePickerTable from '../../Components/Table/DatePickerTable';
import Pagination from '../../Components/Table/Pagination';
import Title from '../../Components/Title';
import Table from './../../Components/Table/index';

function ManageCash() {
    const { control } = useForm();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const cashLogs = [
        {
            id: 0,
            date: '2021.11.12 04:43',
            user: '우상욱',
            price: 1000000,
            statusCd: 1,
            content: '장지 부가서비스 신청',
        },
        {
            id: 1,
            date: '2021.11.12 04:43',
            user: '우상욱',
            price: 1000000,
            statusCd: 0,
            content: '장지 부가서비스 환불',
        },
        {
            id: 2,
            date: '2021.11.12 04:43',
            user: '우상욱',
            price: 1000000,
            statusCd: 0,
            content: '장례예약 환불',
        },
        {
            id: 3,
            date: '2021.11.12 04:43',
            user: '우상욱',
            price: 1000000,
            statusCd: 0,
            content: '발인예약 환불',
        },
    ];

    const cashLogTableCellProps = [
        { align: 'center' },
        { align: 'left' },
        { align: 'center' },
        { align: 'right' },
        { align: 'center' },
        { align: 'left' },
    ];

    return (
        <>
            <Title header={{ title: '캐시 관리' }} />
            <DatePickerTable
                title={'기간'}
                title2={'협력사명'}
                control={control}
                select
            />
            <Box
                sx={{
                    minWidth: 120,
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                        <MenuItem value={2}>지급전</MenuItem>
                        <MenuItem value={3}>지급완료</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    { content: '날짜' },
                    { content: '사용자' },
                    { content: '금액' },
                    { content: '구분' },
                    { content: '내용' },
                ]}
                tableCellProps={cashLogTableCellProps}
                items={cashLogs.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        // sx={{ cursor: 'pointer' }}
                        // onClick={() => {
                        //     navigate(`${idx}`);
                        // }}
                    >
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },
                            {
                                content: ele.date,
                            },
                            { content: ele.user },
                            { content: moneyWithCommas(ele.price) },
                            {
                                content:
                                    ele.statusCd === 0
                                        ? '캐시지급'
                                        : ele.statusCd === 1
                                        ? '캐시사용'
                                        : '',
                            },
                            { content: ele.content },
                        ].map(({ content }, idx, columns) => (
                            <TableCell
                                key={idx}
                                {...cashLogTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
        </>
    );
}

export default ManageCash;
