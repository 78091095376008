import { Box, Drawer } from '@mui/material';
import MenuList from './MenuList';

const Sidebar = () => (
    <Box component="nav" sx={{ width: '200px' }} aria-label="mailbox folders">
        <Drawer
            variant="permanent"
            sx={{
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: '200px',
                    boxSizing: 'border-box',
                    position: 'static',
                    '& .MuiList-padding': {
                        padding: '0px',
                    },
                },
                '& .active': {
                    '& .MuiButtonBase-root': {
                        background: 'rgba(195, 169, 129, 0.3)',
                    },
                },
                height: '100%',
                background: '#FFFFFF',
                borderWidth: '0px 1px',
                borderStyle: 'solid',
                borderColor: '#E0E0E0',
            }}
        >
            <MenuList />
        </Drawer>
    </Box>
);

export default Sidebar;
