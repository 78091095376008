import { useState } from 'react';
import DialogModal from '../../../Components/Modal/DialogModal';
import PageHeader from '../../../Components/PageHeader';
import Title from './../../../Components/Title';
import NewsForm from './NewsForm';

function EditNews() {
    // 뉴스 삭제 모달 오픈여부
    const [isOpenRemoveNewsModal, setIsOpenRemoveNewsModal] = useState(false);

    const news = {
        createdDate: '2021.11.07 07:43',
        target: {
            family: true,
            director: true,
            funeralHall: false,
            cemetery: false,
        },
        title: '묘적도 작성대행 신청',
        content:
            '<p><strong>수시란 시신을 바르게 한다는 뜻으로 시신이 굳어지기 전에 수족(手足)을 골고루 주물로 굽힘이 없이 바르게 펴고 묶어 주는 절차를 말합니다. 환자가 무릎이나 다른 부위가 굽어진 상태로 운명하였을 경우 수의를 입히거나 입관 시킬 때 어려움을 겪게 되므로 임종 후 바로 반듯이 해 주어야 합니다. 수시용품 수시복, 탈지면, 끈, 수건, 홑이불, 환자용 기저귀, 병풍, 상, 촛대(초), 향로(향), 사진, 긴 자</strong></p><p><strong>안녕하세요 </strong></p>',
    };

    return (
        <>
            <PageHeader
                title="소식 수정"
                breadcrumbItems={[
                    { name: '소식' },
                    { name: '소식 상세' },
                    { name: '소식 수정' },
                ]}
            />
            <NewsForm news={news} isEdit onSubmit={() => {}} />
            <DialogModal
                open={isOpenRemoveNewsModal}
                close={() => {
                    setIsOpenRemoveNewsModal(false);
                }}
                rightBtnProps={{ color: 'error' }}
                rightBtnChildren="삭제"
                handleClickRightBtn={() => {
                    setIsOpenRemoveNewsModal(false);
                }}
            >
                삭제하시겠습니까?
            </DialogModal>
        </>
    );
}

export default EditNews;
