import { useRef, useState } from 'react';
import {
    TableCell,
    TableRow,
    TableContainer,
    Table,
    TextField,
    Button,
} from '@mui/material';
import Title from '../../../Components/Title';
import styled from '@emotion/styled';
import { Box } from '@mui/system';
import PhotoIcon from '@mui/icons-material/Photo';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from '../../../Components/Form/ErrorMessage';
import ColumnTable from '../../../Components/Table/Colunm';
import { addHyphenPhone, checkIsValidPhone } from './../../../common/util';
import PageHeader from './../../../Components/PageHeader';

const StyledTableCell = styled(TableCell)`
    width: 145px;
    background: rgba(48, 55, 66, 0.07);
    border: 1px solid #e0e0e0;
    border-width: '0 1px 1px 0';
    font-weight: 500;
`;

function AddFuneral() {
    const navigate = useNavigate();
    const {
        register,
        control,
        watch,
        setValue,
        setError,
        formState: { errors },
    } = useForm();

    const postcode = useRef(
        new window.daum.Postcode({
            oncomplete: function (data) {
                console.log(data);
                setValue('address', data.address);
            },
        })
    );
    const [photoSrc, setPhotoSrc] = useState(null);
    const fileReader = useRef(
        (() => {
            const reader = new FileReader();
            reader.addEventListener('load', ({ target }) => {
                setPhotoSrc(target.result);
            });
            return reader;
        })()
    );

    const searchAddress = () => {
        postcode.current.open();
    };

    const columnContents = [
        {
            title: '장례식장명',
            padding: '6px',
            content: (
                <Controller
                    control={control}
                    name="name"
                    defaultValue={''}
                    rules={{ required: '장례식장명을 입력해주세요.' }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '사진',
            padding: '6px',
            content: (
                <>
                    <Button
                        variant="outlined"
                        component="label"
                        color="primary3"
                        size="small"
                        sx={{ mb: '5px' }}
                    >
                        업로드
                        <input
                            hidden
                            accept="image/gif, image/jpeg, image/png"
                            type="file"
                            {...register('photo', {
                                // 파일이 올바른 타입인지 체크
                                onChange: (e) => {
                                    const { files } = e.target;
                                    const [file] = files;
                                    if (file) {
                                        const { type } = file;
                                        // 파일이 지정한 타입에 해당하다면 정상적으로 값설정
                                        if (
                                            type === 'image/gif' ||
                                            type === 'image/jpeg' ||
                                            type === 'image/png'
                                        ) {
                                            fileReader.current.readAsDataURL(
                                                file
                                            );
                                            return setValue('photo', file);
                                        }
                                        setError('photo', {
                                            type: 'isNotValidPhoto',
                                            message:
                                                'jpeg, png, gif타입의 이미지를 업로드해주세요.',
                                        });
                                    }

                                    return setValue('photo', null);
                                },
                            })}
                        />
                    </Button>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '375px',
                            height: '210px',
                            background: 'rgba(0, 0, 0, 0.1)',
                            border: '1px solid rgba(0, 0, 0, 0.23)',
                            borderRadius: '4px',

                            '& > img': {
                                maxWidth: '100%',
                                maxHeight: '100%',
                                objectFit: 'cover',
                                padding: '10px',
                            },
                        }}
                    >
                        {photoSrc ? (
                            <img src={photoSrc} alt="funeral" />
                        ) : (
                            <PhotoIcon
                                fontSize="large"
                                sx={{ color: ' rgba(0, 0, 0, 0.54)' }}
                            />
                        )}
                    </Box>
                    <ErrorMessage error={errors && errors.photo} />
                </>
            ),
        },
        {
            title: '주소',
            padding: '6px',
            content: (
                <>
                    <Button
                        variant="outlined"
                        size="small"
                        color="primary3"
                        sx={{ mb: '5px' }}
                        onClick={searchAddress}
                    >
                        주소 찾기
                    </Button>
                    <Controller
                        control={control}
                        name="address"
                        rules={{ required: '주소를 입력해주세요.' }}
                        defaultValue={''}
                        render={({ field, fieldState: { error } }) => (
                            <>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    disabled
                                    sx={{
                                        '& .Mui-disabled': {
                                            WebkitTextFillColor: 'inherit',
                                            color: 'inherit',
                                        },
                                    }}
                                    {...field}
                                />
                                <ErrorMessage error={error} />
                            </>
                        )}
                    />
                </>
            ),
        },
        {
            title: '연락처',
            padding: '6px',
            content: (
                <Controller
                    control={control}
                    name="phone"
                    defaultValue={''}
                    rules={{
                        required: '연락처를 입력해주세요.',
                        onChange: (e) => {
                            const value = e.target.value;
                            setValue('phone', addHyphenPhone(value));
                        },
                        validate: {
                            isCorrectPattern: (v) =>
                                checkIsValidPhone(v) ||
                                '연락처를 양식에 맞게 입력해주세요.',
                        },
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
    ];

    return (
        <>
            <PageHeader
                title="장례식장"
                breadcrumbItems={[
                    { name: '장례식장' },
                    { name: '장례식장 추가' },
                ]}
            />
            <ColumnTable columnContents={columnContents} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: '10px',
                    pl: '20px',
                }}
            >
                {/* <Button variant="outlined" size="large" color="error">
                    삭제
                </Button> */}
                <div />
                <Box>
                    <Button
                        variant="outlined"
                        size="large"
                        color="primary3"
                        sx={{ mr: '15px' }}
                        onClick={() => navigate(-1)}
                    >
                        취소
                    </Button>
                    <Button variant="contained" size="large" color="primary">
                        추가
                    </Button>
                </Box>
            </Box>
        </>
    );
}

export default AddFuneral;
