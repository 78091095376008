import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { moneyWithCommas } from '../../../common/util';
import Table from '../../../Components/Table';
import DatePickerTable from '../../../Components/Table/DatePickerTable';
import Title from '../../../Components/Title';
import Pagination from '../../../Components/Table/Pagination';

function ManageRequest() {
    const { control } = useForm();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();
    const requestTableCellProps = [
        { align: 'center' },
        { align: 'center' },
        {},
        { align: 'center' },
        {},
        { align: 'right' },
        { align: 'center' },
    ];
    const requests = [
        {
            id: 0,
            requestedDate: '2021.11.25',
            cemetery: '화성하늘공원수목장림',
            deceased: '배회호',
            content: '헌화 해 주세요 (장미 꽃 다발 50,000원)',
            price: 50000,
            status: '1',
        },
        {
            id: 1,
            requestedDate: '2021.11.25',
            cemetery: '(재)용인추모원 자연장지',
            deceased: '홍인수',
            content: '새로운 사진을 찍어주세요.',
            price: 30000,
            status: '0',
        },
    ];

    return (
        <>
            <Title header={{ title: '요청 관리' }} />
            <DatePickerTable
                title={'납부일'}
                title2={'고인'}
                control={control}
                select
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">배송상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                        <MenuItem value={2}>요청</MenuItem>
                        <MenuItem value={3}>예약됨</MenuItem>
                        <MenuItem value={4}>불발</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Table
                numeric
                reverse
                columns={[
                    { content: '요청일' },
                    { content: '장지' },
                    { content: '고인' },
                    { content: '요청' },
                    { content: '금액' },
                    { content: '처리상태' },
                ]}
                tableCellProps={requestTableCellProps}
                items={requests.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate(`${idx}`);
                        }}
                    >
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },
                            {
                                content: ele.requestedDate,
                            },
                            { content: ele.cemetery },
                            { content: ele.deceased },
                            { content: ele.content },
                            { content: moneyWithCommas(ele.price) },
                            {
                                content:
                                    ele.status === '0' ? (
                                        '완료'
                                    ) : ele.status === '1' ? (
                                        <Box color="primary5.main">처리전</Box>
                                    ) : (
                                        ''
                                    ),
                            },
                        ].map(({ content }, idx, columns) => (
                            <TableCell
                                key={idx}
                                {...requestTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
        </>
    );
}

export default ManageRequest;
