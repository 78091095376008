import regexp from './regexp';
const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

// 숫자에 컴마추가
export function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',');
}

export function moneyWithCommas(x) {
    return `${numberWithCommas(x)}원`;
}

// 전화번호에 자동으로 hyphen입력
export function addHyphenPhone(x) {
    return x
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, '$1-$2-$3');
}

// 전화번호가 정규표현식에 유효한지 체크
export function checkIsValidPhone(v) {
    return regexp.mobilePhone.test(v) || regexp.phone.test(v);
}

export function getDiffDays(date, date2) {
    if (Object.prototype.toString.call(date) !== '[object Date]') {
        date = new Date(date);
    }
    if (Object.prototype.toString.call(date2) !== '[object Date]') {
        date2 = new Date(date2);
    }

    const time1 = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate()
    ).getTime();
    const time2 = new Date(
        date2.getFullYear(),
        date2.getMonth() + 1,
        date2.getDate()
    ).getTime();

    // const sign = time2 - time1 > 0 ? 1 : -1;

    return Math.floor(Math.abs(time2 - time1) / DAY);
}

export function getDiffMinutes(date, date2) {
    if (Object.prototype.toString.call(date) !== '[object Date]') {
        date = new Date(date);
    }
    if (Object.prototype.toString.call(date2) !== '[object Date]') {
        date2 = new Date(date2);
    }

    const time1 = new Date(
        date.getFullYear(),
        date.getMonth() + 1,
        date.getDate(),
        date.getHours(),
        date.getMinutes()
    ).getTime();
    const time2 = new Date(
        date2.getFullYear(),
        date2.getMonth() + 1,
        date2.getDate(),
        date2.getHours(),
        date2.getMinutes()
    ).getTime();

    // const sign = time2 - time1 > 0 ? 1 : -1;

    return Math.floor(Math.abs(time2 - time1) / MINUTE);
}

export function getTimeString(date = new Date(), type = 'default') {
    if (Object.prototype.toString.call(date) !== '[object Date]') {
        date = new Date(date);
    }

    const hours = date.getHours();
    const minutes = date.getMinutes();

    const isAm = hours < 12;

    if (type === 'default') {
        return `${isAm ? '오전' : '오후'} ${hours > 12 ? hours - 12 : hours}:${
            minutes < 10 ? '0' + minutes : minutes
        }`;
    }
}

// date를 string 형식으로 출력
export function getDateString(date = new Date(), type = 'default') {
    if (Object.prototype.toString.call(date) !== '[object Date]') {
        date = new Date(date);
    }

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();

    if (type === 'default') {
        return `${year}년 ${month > 9 ? month : '0' + month}월 ${
            day > 9 ? day : '0' + day
        }일`;
    } else if (type === 'number') {
        return `${year}-${month > 9 ? month : '0' + month}-${
            day > 9 ? day : '0' + day
        }`;
    } else if (type === 'point') {
        return `${year}.${month > 9 ? month : '0' + month}.${
            day > 9 ? day : '0' + day
        }`;
    }
}
