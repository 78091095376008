import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableRow,
    TableCell,
} from '@mui/material';
import React, { useEffect } from 'react';
import Title from './../Components/Title';
import styled from 'styled-components';
import ColumnTable from '../Components/Table/Colunm';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Tooltip,
    Title as ChartTitle,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

// 차트JS 모듈 등록
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, ChartTitle);

const Wrapper = styled.div`
    .numerical-statistics {
        .numerical-statistics-header {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .title {
                font-weight: 500;
                font-size: 16px;
                color: #303742;
            }

            .select {
                display: flex;
                align-items: center;
                gap: 10px;
            }
        }

        .numerical-statistics-body {
            background: #fff;
            .chart {
                margin-bottom: 10px;
                padding: 10px 10px 0;
            }
        }
    }

    .price-statistics {
        margin-top: 45px;
        .title {
            font-weight: 500;
            font-size: 16px;
            color: #303742;
        }

        .chart-wrapper {
            margin-top: 10px;

            p {
                font-weight: 500;
                font-size: 14px;
                color: #333333;
            }
        }
    }
`;

function Statistics() {
    // 통계 데이터
    const statisticsData = [...new Array(12).keys()].map((_, idx) => ({
        month: idx,
        funeral: 1432,
        funeralEstimate: 1432,
        funeralEstimateRate: 3.4,
        funeralReserveRequest: 1432,
        funeralReserveRate: 0.82,
        balinEstimate: 1432,
        cemeteryEstimate: 1432,
        cemeteryEstimateRate: 1.3,
        cemeteryReserveRate: 0.95,
    }));
    const statisticsDataArray = statisticsData.reduce(
        (prev, curr) => {
            Object.keys(prev).forEach((key) => {
                const arr = prev[key].data;
                if (key === 'month') {
                    const value = String(curr[key] + 1).padStart(2, '0');
                    arr.push(`${value}월`);
                    return;
                }
                arr.push(curr[key]);
            });

            return prev;
        },
        {
            funeral: { field: '장례', data: [] },
            month: { field: '기간', data: [] },
            funeralEstimate: { field: '장례견적', data: [] },
            funeralEstimateRate: { field: '견적율', data: [] },
            funeralReserveRequest: { field: '예약요청', data: [] },
            funeralReserveRate: { field: '예약율', data: [] },
            balinEstimate: { field: '발인견적', data: [] },
            cemeteryEstimate: { field: '장지견적', data: [] },
            cemeteryEstimateRate: { field: '견적율', data: [] },
            cemeteryReserveRate: { field: '예약율', data: [] },
        }
    );

    const columnContents = Object.keys(statisticsDataArray).map(
        (key, rowIdx) => {
            const tableRowSx = {
                borderBottom:
                    [1, 5].find((ele) => ele === rowIdx) !== undefined
                        ? '2px solid #AAAAAA'
                        : 'none',
            };

            return {
                children: (
                    <TableRow
                        sx={{
                            '&:last-child td, &:last-child th': {
                                borderBottom: 0,
                            },
                            ...tableRowSx,
                        }}
                    >
                        <TableCell
                            sx={{
                                borderRight: '1px solid #E0E0E0',
                                fontWeight:
                                    [0, 2, 6].find((ele) => ele === rowIdx) !==
                                    undefined
                                        ? '500'
                                        : '400',
                                verticalAlign: 'baseline',
                                whiteSpace: 'nowrap',
                            }}
                        >
                            {statisticsDataArray[key].field}
                        </TableCell>
                        {statisticsDataArray[key].data.map((ele, cellIdx) => {
                            const tableCellSx = {
                                borderRight:
                                    [0, 3, 6, 9].find(
                                        (ele) => ele === cellIdx
                                    ) !== undefined
                                        ? '0.5px solid #E0E0E0'
                                        : 'none',
                            };

                            return (
                                <TableCell
                                    key={cellIdx}
                                    sx={{ ...tableCellSx }}
                                    align="center"
                                >
                                    {ele}
                                </TableCell>
                            );
                        })}
                    </TableRow>
                ),
            };
        }
    );

    return (
        <>
            <Title header={{ title: '통계' }} />
            <Wrapper>
                <div className="numerical-statistics">
                    <div className="numerical-statistics-header">
                        <div className="title">수치통계</div>
                        <div className="select">
                            <FormControl
                                sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                                size="small"
                            >
                                <InputLabel id="statistics-select-label">
                                    기간
                                </InputLabel>
                                <Select
                                    label="기간"
                                    labelId="statistics-select-label"
                                    defaultValue={'2022.01'}
                                >
                                    <MenuItem value="2022.01">2022.01</MenuItem>
                                    <MenuItem value="2022.02">2022.02</MenuItem>
                                </Select>
                            </FormControl>
                            부터 12개월
                        </div>
                    </div>
                    <div className="numerical-statistics-body">
                        <Bar
                            className="chart"
                            options={{
                                plugins: { legend: { display: false } },
                            }}
                            data={{
                                labels: statisticsDataArray.month.data,
                                datasets: [
                                    {
                                        label: '',
                                        backgroundColor:
                                            'rgba(46, 125, 50, 0.3)',
                                        hoverBackgroundColor: '#2E7D32',
                                        data: statisticsDataArray.funeral.data,
                                    },
                                ],
                            }}
                        />
                        <ColumnTable columnContents={columnContents} />
                    </div>
                </div>

                <div className="price-statistics">
                    <div className="title">금액통계</div>
                    <div className="chart-wrapper">
                        <p>총 장례 평균금액: ₩5,285,900</p>
                        <Bar
                            className="chart"
                            options={{
                                plugins: {
                                    legend: { display: false },
                                    title: {
                                        display: true,
                                        text: '총 장례 비용',
                                        position: 'top',
                                        align: 'start',
                                    },
                                },
                            }}
                            data={{
                                labels: [
                                    '300만원',
                                    '400만원',
                                    '500만원',
                                    '600만원',
                                    '700만원',
                                    '800만원',
                                    '900만원',
                                    '1000만원',
                                ],
                                datasets: [
                                    {
                                        label: '',
                                        backgroundColor:
                                            'rgba(48, 55, 66, 0.3)',
                                        hoverBackgroundColor: '#303742',
                                        data: [1, 7, 5, 7, 9, 11, 8, 7],
                                    },
                                ],
                            }}
                        />
                    </div>
                    <div className="chart-wrapper">
                        <p>총 장례식장 평균금액: ₩5,285,900</p>
                        <Bar
                            className="chart"
                            options={{
                                plugins: {
                                    legend: { display: false },
                                    title: {
                                        display: true,
                                        text: '총 장례식장 비용',
                                        position: 'top',
                                        align: 'start',
                                    },
                                },
                            }}
                            data={{
                                labels: [
                                    '300만원',
                                    '400만원',
                                    '500만원',
                                    '600만원',
                                    '700만원',
                                    '800만원',
                                    '900만원',
                                    '1000만원',
                                ],
                                datasets: [
                                    {
                                        label: '',
                                        backgroundColor:
                                            'rgba(48, 55, 66, 0.3)',
                                        hoverBackgroundColor: '#303742',
                                        data: [1, 7, 5, 7, 9, 11, 8, 7],
                                    },
                                ],
                            }}
                        />
                    </div>
                    <div className="chart-wrapper">
                        <p>총 발인 평균금액: ₩5,285,900</p>
                        <Bar
                            className="chart"
                            options={{
                                plugins: {
                                    legend: { display: false },
                                    title: {
                                        display: true,
                                        text: '총 발인 비용',
                                        position: 'top',
                                        align: 'start',
                                    },
                                },
                            }}
                            data={{
                                labels: [
                                    '300만원',
                                    '400만원',
                                    '500만원',
                                    '600만원',
                                    '700만원',
                                    '800만원',
                                    '900만원',
                                    '1000만원',
                                ],
                                datasets: [
                                    {
                                        label: '',
                                        backgroundColor:
                                            'rgba(48, 55, 66, 0.3)',
                                        hoverBackgroundColor: '#303742',
                                        data: [1, 7, 5, 7, 9, 11, 8, 7],
                                    },
                                ],
                            }}
                        />
                    </div>
                    <div className="chart-wrapper">
                        <p>총 장지 평균금액: ₩5,285,900</p>
                        <Bar
                            className="chart"
                            options={{
                                plugins: {
                                    legend: { display: false },
                                    title: {
                                        display: true,
                                        text: '총 장지 비용',
                                        position: 'top',
                                        align: 'start',
                                    },
                                },
                            }}
                            data={{
                                labels: [
                                    '300만원',
                                    '400만원',
                                    '500만원',
                                    '600만원',
                                    '700만원',
                                    '800만원',
                                    '900만원',
                                    '1000만원',
                                ],
                                datasets: [
                                    {
                                        label: '',
                                        backgroundColor:
                                            'rgba(48, 55, 66, 0.3)',
                                        hoverBackgroundColor: '#303742',
                                        data: [1, 7, 5, 7, 9, 11, 8, 7],
                                    },
                                ],
                            }}
                        />
                    </div>
                </div>
            </Wrapper>
        </>
    );
}

export default Statistics;
