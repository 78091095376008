import React from 'react';

import {
    TableContainer,
    Switch,
    Button,
    makeStyles,
    TableRow,
    TableCell,
} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Title from '../../Components/Title';
import { Box } from '@mui/system';
import Table from '../../Components/Table';

function ManageAccount() {
    const navigate = useNavigate();
    const accounts = [
        {
            name: '이경민',
            userId: 'kkmin',
            gradeId: 0,
            lastAccess: '2021.10.12 13:26',
        },
        {
            name: '이성재',
            userId: 'sung',
            gradeId: 1,
            lastAccess: '2021.10.12 13:26',
        },
        {
            name: '한성민',
            userId: 'min',
            gradeId: 2,
            lastAccess: '2021.10.12 13:26',
        },
        {
            name: '홍길동',
            userId: 'kildong',
            gradeId: 2,
            lastAccess: '2021.10.12 13:26',
        },
        {
            name: '한습관',
            userId: 'han',
            gradeId: 2,
            lastAccess: '2021.10.12 13:26',
        },
    ];
    const accountTableCellProps = [
        { width: 60, align: 'center' },
        { width: 120 },
        { width: 120 },
        {},
        { width: 150, align: 'center' },
        { width: 120, align: 'center', padding: 'none' },
    ];

    return (
        <>
            <Title header={{ title: '관리자 계정관리' }} />
            <Table
                reverse
                numeric
                columns={[
                    {
                        content: '이름',
                    },
                    {
                        content: '아이디',
                    },
                    {
                        content: '관리',
                    },
                    {
                        content: '마지막 접속',
                    },
                    {
                        content: '로그인 제한',
                    },
                ]}
                tableCellProps={accountTableCellProps}
                items={accounts.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        onClick={() => navigate(`/account/${idx}`)}
                        sx={{ cursor: 'pointer' }}
                    >
                        {[
                            { content: idx + 1 },
                            { content: ele.name },
                            { content: ele.userId },
                            {
                                content:
                                    ele.gradeId === 0
                                        ? '마스터'
                                        : ele.gradeId === 1
                                        ? '부마스터'
                                        : ele.gradeId === 2
                                        ? '일반관리자'
                                        : '',
                            },
                            { content: ele.lastAccess },
                            {
                                content: ele.gradeId === 2 && (
                                    <Switch color="error" />
                                ),
                                props: {
                                    onClick: (e) => {
                                        e.stopPropagation();
                                    },
                                },
                            },
                        ].map(({ content, props = {} }, idx) => (
                            <TableCell
                                key={idx}
                                {...accountTableCellProps[idx]}
                                {...props}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Box
                sx={{ display: 'flex', justifyContent: 'flex-end', mt: '10px' }}
            >
                <Link to="/account/add">
                    <Button variant="contained" size="large">
                        관리자 추가
                    </Button>
                </Link>
            </Box>
        </>
    );
}

export default ManageAccount;
