import { Link } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import styled from 'styled-components';

const Wrapper = styled.div`
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    color: #1565c0;
    gap: 8px;
    margin-top: 14px;
    margin-bottom: 9px;
`;

function PrevPageButton() {
    return (
        <Link to="./..">
            <Wrapper>
                <ArrowBack sx={{ fontSize: '18px' }} />
                이전
            </Wrapper>
        </Link>
    );
}

export default PrevPageButton;
