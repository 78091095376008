import React, { useState } from 'react';
import ModalLayout from '../../../Components/Modal/ModalLayout';
import styled from 'styled-components';
import { BACKDROP_TYPES } from './../../../Components/Modal/ModalLayout';

const Wrapper = styled.div`
    min-width: 540px;
    padding: 0 30px 30px;

    h1 {
        font-weight: 500;
        font-size: 24px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e0e0e0;
        margin: -9px 0 15px;
    }

    .date {
        font-weight: 400;
        font-size: 12px;
        color: #333;
        margin-bottom: 5px;
    }
    .title {
        font-weight: 400;
        font-size: 16px;
        color: #333;
        margin-bottom: 15px;
    }
`;

function RequestResultViewModal({ requestResult, children }) {
    const [isOpenRequestResultView, setIsOpenRequestResultView] =
        useState(false);

    return (
        <>
            <ModalLayout
                open={isOpenRequestResultView}
                close={() => {
                    setIsOpenRequestResultView(false);
                }}
                backdropType={BACKDROP_TYPES.OPACITY}
                modalContentWrapperProps={{ sx: { borderRadius: 0 } }}
            >
                <Wrapper>
                    <h1>처리결과</h1>
                    <div className="date">{requestResult.date}</div>
                    <div className="title">{requestResult.title}</div>
                    <div className="content">{requestResult.content}</div>
                </Wrapper>
            </ModalLayout>
            <button
                style={{ textAlign: 'center', width: '100%', padding: '16px' }}
                onClick={(e) => {
                    setIsOpenRequestResultView(true);
                }}
            >
                {children}
            </button>
        </>
    );
}

export default RequestResultViewModal;
