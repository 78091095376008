import clsx from 'clsx';
import { styled, Box, borderRadius } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import { forwardRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Button, ButtonGroup, IconButton, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

const BackdropUnstyled = forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'MuiBackdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});

BackdropUnstyled.displayName = 'BackdropUnstyled';

const Modal = styled(ModalUnstyled)`
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Backdrop = styled(BackdropUnstyled)`
    z-index: -1;
    position: fixed;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    -webkit-tap-highlight-color: transparent;
`;

export default function PasswordModal({ open, close }) {
    // const [disable, setDisable] = useState(true);
    // const disableOn =() => input =setDisable(true)

    const { control, watch, handleSubmit } = useForm();

    const onSubmit = (data) => {
        const { password } = data;
    };

    const isDisableBtn = (() => {
        const { password } = watch();
        // 비밀번호 6자 미만이면 버튼 비활성화
        const isDisable = !password || password.length < 6;

        return isDisable;
    })();

    return (
        <Modal disableAutoFocus open={open} components={{ Backdrop }}>
            <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                    width: 300,
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        padding: '5px 5px 0',
                    }}
                >
                    <IconButton
                        size="small"
                        sx={{ padding: '5px' }}
                        onClick={close}
                    >
                        <CloseIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                    </IconButton>
                </Box>
                <Box
                    sx={{
                        fontWeight: '500',
                        fontSize: '16px',
                        lineHeight: '23px',
                        textAlign: 'center',
                        padding: '0px 40px 30px ',
                    }}
                >
                    비밀번호 변경
                    <Controller
                        name="password"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                            <TextField
                                fullWidth
                                type="password"
                                helperText="영문, 숫자, 특수문자로 6자 이상"
                                size="small"
                                sx={{ pt: '15px' }}
                                FormHelperTextProps={{
                                    style: { textAlign: 'center' },
                                }}
                                {...field}
                            />
                        )}
                    />
                </Box>
                <ButtonGroup
                    disableElevation
                    fullWidth
                    size="large"
                    sx={{
                        height: '50px',
                        fontSize: '16px',
                        fontWeight: '500',
                        borderTop: '1px solid #E0E0E0',
                        borderRadius: '0px',
                        borderTopLeftRadius: '0',
                        borderTopRightRadius: '0',
                        '& > button': {
                            width: '50%',
                            margin: '0 !important',
                        },
                    }}
                >
                    <Button
                        variant="text"
                        fullWidth
                        sx={{
                            borderRadius: '0',
                            borderBottomLeftRadius: '10px',
                            color: '#888888',
                        }}
                        onClick={close}
                    >
                        취소
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        fullWidth
                        disabled={isDisableBtn}
                        sx={{
                            borderRadius: '0',
                            borderBottomRightRadius: '10px',
                        }}
                    >
                        변경
                    </Button>
                </ButtonGroup>
            </Box>
        </Modal>
    );
}
