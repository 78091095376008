import { Box, TableCell, TableRow } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { moneyWithCommas } from '../../../common/util';
import PageHeader from '../../../Components/PageHeader';
import Table from '../../../Components/Table';
import ColumnTable from '../../../Components/Table/Colunm';
import Title from './../../../Components/Title';

function PaymentDetail() {
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const payments = [
        {
            id: 0,
            cemetery: '화성하늘공원수목장림',
            deceased: '배희호',
            contractor: '배수진',
            item: '관리비',
            payment: '신용카드 3645************',
            price: 230000,
            dueDatePayment: '2021.11.25',
            remainDate: 12,
            status: '1',
        },
        {
            id: 1,
            cemetery: '(재)용인추모원 자연장지',
            deceased: '홍인수',
            contractor: '배수진',
            item: '이용료',
            payment: '신용카드 3645************',
            price: 3500000,
            dueDatePayment: '2021.11.20',
            remainDate: 7,
            status: '0',
        },
        {
            id: 2,
            cemetery: '별그리다(별의숲)',
            deceased: '오경선',
            contractor: '배수진',
            item: '이용료',
            payment: '신용카드 3645************',
            price: 3500000,
            dueDatePayment: '2022.02.06',
            remainDate: -245,
            status: '2',
        },
    ];
    const payment = payments[0];
    const columnContents = [
        { title: '장지', content: payment.cemetery },
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell
                        sx={{
                            width: '145px',
                            background: 'rgba(48, 55, 66, 0.07)',
                            borderRight: '1px solid #E0E0E0',
                            fontWeight: 500,
                            verticalAlign: 'baseline',
                        }}
                    >
                        고인
                    </TableCell>
                    <TableCell>{payment.deceased}</TableCell>
                    <TableCell
                        sx={{
                            width: '145px',
                            background: 'rgba(48, 55, 66, 0.07)',
                            borderRight: '1px solid #E0E0E0',
                            fontWeight: 500,
                            verticalAlign: 'baseline',
                        }}
                    >
                        계약자
                    </TableCell>
                    <TableCell>{payment.contractor}</TableCell>
                </TableRow>
            ),
        },
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell
                        sx={{
                            width: '145px',
                            background: 'rgba(48, 55, 66, 0.07)',
                            borderRight: '1px solid #E0E0E0',
                            fontWeight: 500,
                            verticalAlign: 'baseline',
                        }}
                    >
                        납부 항목
                    </TableCell>
                    <TableCell>{payment.item}</TableCell>
                    <TableCell
                        sx={{
                            width: '145px',
                            background: 'rgba(48, 55, 66, 0.07)',
                            borderRight: '1px solid #E0E0E0',
                            fontWeight: 500,
                            verticalAlign: 'baseline',
                        }}
                    >
                        금액
                    </TableCell>
                    <TableCell>{moneyWithCommas(payment.price)}</TableCell>
                </TableRow>
            ),
        },
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell
                        sx={{
                            width: '145px',
                            background: 'rgba(48, 55, 66, 0.07)',
                            borderRight: '1px solid #E0E0E0',
                            fontWeight: 500,
                            verticalAlign: 'baseline',
                        }}
                    >
                        납부기한
                    </TableCell>
                    <TableCell>{payment.dueDatePayment}</TableCell>
                    <TableCell
                        sx={{
                            width: '145px',
                            background: 'rgba(48, 55, 66, 0.07)',
                            borderRight: '1px solid #E0E0E0',
                            fontWeight: 500,
                            verticalAlign: 'baseline',
                        }}
                    >
                        남은 기간
                    </TableCell>
                    <TableCell>
                        {payment.remainDate > 0
                            ? '-'
                            : payment.remainDate < 0
                            ? '+'
                            : ''}{' '}
                        {Math.abs(payment.remainDate)}일
                    </TableCell>
                </TableRow>
            ),
        },
        {
            title: '납부 상태',
            content:
                payment.status === '0'
                    ? '납부 완료'
                    : payment.status === '1'
                    ? '납부 전'
                    : payment.status === '2'
                    ? '미납'
                    : '',
        },
    ];
    const paymentTableCellProps = [
        { align: 'center' },
        { align: 'center' },
        {},
        { align: 'right' },
        {},
    ];

    return (
        <>
            <PageHeader
                title="납부 관리 상세"
                breadcrumbItems={[
                    { name: '납부 상품관리' },
                    { name: '납부 상품관리 상세' },
                ]}
            />
            <Box
                sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#303742',
                    mb: '10px',
                }}
            >
                주문내역
            </Box>
            <ColumnTable columnContents={columnContents} maxColumnCnt={4} />
            <Box
                sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#303742',
                    mt: '45px',
                    mb: '10px',
                }}
            >
                납부이력
            </Box>
            <Table
                numeric
                reverse
                tableCellProps={paymentTableCellProps}
                columns={[
                    { content: '납부항목' },
                    { content: '결제' },
                    { content: '금액' },
                    { content: '납부일' },
                ]}
                items={payments.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate(`/cemetery/payment/${idx}`);
                        }}
                    >
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },

                            {
                                content: ele.item,
                            },
                            { content: ele.payment },
                            {
                                content: moneyWithCommas(ele.price),
                            },
                            {
                                content: ele.dueDatePayment,
                            },
                        ].map(({ content }, idx, columns) => (
                            <TableCell
                                key={idx}
                                {...paymentTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
        </>
    );
}

export default PaymentDetail;
