import { useMemo } from 'react';
import {
    TableRow as MuiTableRow,
    TableCell as MuiTableCell,
    Box,
} from '@mui/material';
import { moneyWithCommas } from '../../../../../common/util';
import styled from 'styled-components';

const TableRow = styled(MuiTableRow)`
    &.p-s {
        td {
            padding: 10px 16px;
        }
    }
`;
const TableCell = styled(MuiTableCell)``;

function useClaimColumnContents() {
    const commonTableRowStyle = useMemo(
        () => ({
            '&:last-child td, &:last-child th': {
                borderBottom: 0,
            },
        }),
        []
    );
    const commonTableLabelColumnStyle = useMemo(
        () => ({
            width: '145px',
            background: 'rgba(48, 55, 66, 0.07)',
            borderRight: '1px solid #E0E0E0',
            fontWeight: 500,
            color: '#191919',
        }),
        []
    );

    const claimColumnContents = useMemo(
        () => [
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                            borderBottom: '1.05px solid #303742',
                        }}
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                                fontWeight: 700,
                            }}
                        >
                            총비용
                        </TableCell>
                        <TableCell align="right" sx={{ fontWeight: 700 }}>
                            {moneyWithCommas(18724000)}
                        </TableCell>
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                                fontWeight: 700,
                            }}
                        >
                            선 결제 금액
                        </TableCell>
                        <TableCell align="right" sx={{ fontWeight: 700 }}>
                            {moneyWithCommas(5280000)}
                        </TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                        }}
                        className="p-s"
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            장례시작 비용
                        </TableCell>
                        <TableCell align="right">
                            {moneyWithCommas(15494000)}
                        </TableCell>
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            예약 선 결제
                        </TableCell>
                        <TableCell align="right">
                            {moneyWithCommas(2000000)}
                        </TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                        }}
                        className="p-s"
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            장지 비용
                        </TableCell>
                        <TableCell align="right">
                            {moneyWithCommas(3230000)}
                        </TableCell>
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            온라인 부조
                        </TableCell>
                        <TableCell align="right">
                            {moneyWithCommas(3280000)}
                        </TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                        }}
                        className="p-s"
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            화장장 비용
                        </TableCell>
                        <TableCell align="right">
                            {moneyWithCommas(120000)}
                        </TableCell>
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        />
                        <TableCell />
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                        }}
                        className="p-s"
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            장의차량 비용
                        </TableCell>
                        <TableCell align="right">
                            {moneyWithCommas(2200000)}
                        </TableCell>
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        />
                        <TableCell />
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                            borderTop: '2px solid #303742',
                        }}
                        className="p-s"
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                                fontWeight: 400,
                                fontSize: '12px',
                                padding: '7.5px 16px',
                            }}
                        >
                            <Box sx={{ fontWeight: 700, fontSize: '14px' }}>
                                청구 금액
                            </Box>
                            <Box>(총비용 - 선결제 금액)</Box>
                        </TableCell>
                        <TableCell
                            colSpan={3}
                            align="right"
                            sx={{ fontWeight: 700, padding: '7.5px 16px' }}
                        >
                            {moneyWithCommas(2200000)}
                        </TableCell>
                    </TableRow>
                ),
            },
        ],
        []
    );

    return claimColumnContents;
}

export default useClaimColumnContents;
