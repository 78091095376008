import React, { useState } from 'react';

import {
    TableCell,
    TableRow,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@mui/material';
import { Box } from '@mui/system';

import Title from '../../../Components/Title';
import DatePickerTable from '../../../Components/Table/DatePickerTable';
import { useForm } from 'react-hook-form';
import Pagination from '../../../Components/Table/Pagination';
import Table from '../../../Components/Table';
import EstimateModal from '../../../Components/Modal/EstimateModal';
import { moneyWithCommas } from '../../../common/util';

function ReserveFuneral() {
    const { control } = useForm();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const ReserveFuneral = [
        {
            requestDate: '2021.11.05',
            funeralPeriod: '2021.11.12~2021.11.14 (3일)',
            funeral: '주식회사 영재장례식장',
            deceased: '홍경수',
            price: 11240000,
            reservation: 2,
        },

        {
            requestDate: '어제',
            funeralPeriod: '2021.11.12~2021.11.14 (3일)',
            funeral: '서울보라매병원장례식장',
            deceased: '홍경수',
            price: 8400000,
            reservation: 1,
        },
        {
            requestDate: '오늘',
            funeralPeriod: '2021.11.12~2021.11.14 (3일)',
            funeral: '동작경희병원장례식장',
            deceased: '홍경수',
            price: 9500000,
            reservation: 0,
        },
    ];

    const ReserveFuneralTableCellProps = [
        { width: '60px', align: 'center' },
        { width: '120px' },
        {},
        { width: '180px' },
        { width: '120px', align: 'center' },
        { width: '140px', align: 'right' },
        { width: '100px', align: 'center', padding: 'none' },
    ];

    const estimate = {
        children: [
            {
                title: '견적금액',
                children: [{ totalPrice: 9550000 }],
            },
            {
                title: '장례기간',
                children: [
                    {
                        content: '2021년 11월 12일 ~ 2021년 11월 14일 (3일)',
                    },
                ],
            },
            {
                title: '장래식장',
                children: [
                    {
                        content: '동작경희병원 장례식장',
                        address: '서울 동작구 상도로 146 동작경희병원',
                    },
                ],
            },
            {
                title: '빈소',
                children: [
                    { content: '5호 (45평)', price: 2000000 },
                    {
                        content: '제단 꽃장식 9호',
                        price: 400000,
                    },
                    { content: '영정사진', price: 100000 },
                ],
            },
            {
                title: '상복',
                children: [
                    { content: '남성 3벌', price: 135000 },
                    { content: '여성 5벌', price: 175000 },
                ],
            },

            {
                title: '예상조문객',
                children: [
                    {
                        content: '49명 이하 (접객음식)',
                        price: 3000000,
                    },
                ],
            },
            {
                title: '운구',
                children: [{ content: '관내 이동', price: 100000 }],
            },
            {
                title: '수시',
                children: [{ content: '수시비', price: 200000 }],
            },
            {
                title: '안치',
                children: [{ content: '2일', price: 3500000 }],
            },
            {
                title: '염습',
                children: [{ content: '염습비', price: 30000 }],
            },
            {
                title: '입관',
                children: [
                    { content: '목화순면수의', price: 80000 },
                    { content: '향나무 2단관', price: 1350000 },
                ],
            },
            {
                title: '청소',
                children: [
                    {
                        content: '청소 및 폐기물 처리',
                        price: 300000,
                    },
                ],
            },
            {
                title: '인력파견',
                children: [
                    {
                        content: '장례지도사 1인',
                        price: 300000,
                    },
                    { content: '접객도우미 0인', price: 0 },
                ],
            },
            { subTitle: '예약정보' },
            { title: '고인명', children: [{ content: '홍경수' }] },
            { title: '상주명', children: [{ content: '홍서경' }] },
            { title: '요청자', children: [{ content: '홍길동' }] },
            { title: '연락처', children: [{ content: '010-0987-1234' }] },
            { title: '운구요청', children: [{ content: '필요' }] },
            {
                title: '고인위치',
                children: [
                    {
                        content: '서울시 동작구 흑성동 335',
                        map: (
                            <Box
                                sx={{
                                    height: '180px',
                                    background: '#918080',
                                    mt: '20px',
                                    width: '388px',
                                }}
                            />
                        ),
                    },
                ],
            },
        ],
    };

    return (
        <>
            <Title header={{ title: '장례식 예약' }} />
            <DatePickerTable title={'기간'} title2={'고인'} control={control} />
            <Box
                sx={{
                    minWidth: 120,
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <FormControl sx={{ m: '8px', minWidth: 120 }} size="small">
                    <InputLabel htmlFor="grouped-select">지역</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1} sx={{ textAlign: 'left' }}>
                            전국
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">예약 상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                        <MenuItem value={2}>요청</MenuItem>
                        <MenuItem value={3}>예약됨</MenuItem>
                        <MenuItem value={4}>불발</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    { content: '요청일' },
                    { content: '장례기간' },
                    { content: '장례식장' },
                    { content: '고인명' },
                    { content: '견적금액' },
                    { content: '예약' },
                ]}
                tableCellProps={ReserveFuneralTableCellProps}
                items={ReserveFuneral.map((ele, idx) => (
                    <TableRow key={idx} onClick={handleOpen}>
                        {[
                            { content: page * itemsPerPage + (idx + 1) },
                            { content: ele.requestDate },
                            { content: ele.funeralPeriod },
                            { content: ele.funeral },
                            { content: ele.deceased },
                            { content: moneyWithCommas(ele.price) },
                            {
                                content:
                                    ele.reservation === 0 ? (
                                        <Box
                                            component="button"
                                            width={'100%'}
                                            padding={'16px'}
                                            color="primary5.main"
                                        >
                                            요청
                                        </Box>
                                    ) : ele.reservation === 1 ? (
                                        <Box
                                            component="button"
                                            width={'100%'}
                                            padding={'16px'}
                                            color="primary4.main"
                                        >
                                            예약됨
                                        </Box>
                                    ) : ele.reservation === 2 ? (
                                        <Box
                                            width={'100%'}
                                            padding={'16px'}
                                            color="#888888"
                                        >
                                            불발
                                        </Box>
                                    ) : (
                                        ''
                                    ),
                            },
                        ].map(({ content }, idx) => (
                            <TableCell
                                key={idx}
                                {...ReserveFuneralTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ marginTop: '25px' }}
            />
            <EstimateModal
                estimate={estimate}
                title={'장례식 견적서'}
                open={open}
                close={handleClose}
            />
        </>
    );
}

export default ReserveFuneral;
