import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import DialogModal from '../../../Components/Modal/DialogModal';
import ColumnTable from '../../../Components/Table/Colunm';
import Title from './../../../Components/Title';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../Components/PageHeader';

function QuestionDetail() {
    // 질문 삭제 모달 오픈여부
    const [isOpenRemoveNewsModal, setIsOpenRemoveNewsModal] = useState(false);
    const navigate = useNavigate();

    const question = {
        createdDate: '2021.11.07 04:43',
        target: {
            family: true,
            director: true,
            funeralHall: false,
            cemetery: false,
        },
        title: '환불계좌를 등록하지 않았는데 환불 시 어떻게 입금되나요?',
        content: '<h1><strong>자주하는 질문 내용</strong></h1>',
    };

    const columnContents = [
        { title: '작성일', content: question.createdDate },
        {
            title: '대상',
            content: [...Object.keys(question.target)]
                .filter((key) => question.target[key])
                .map((key) => {
                    if (key === 'family') {
                        return '유족';
                    }
                    if (key === 'director') {
                        return '장례지도사';
                    }
                    if (key === 'funeralHall') {
                        return '장례식장';
                    }
                    if (key === 'cemetery') {
                        return '장지';
                    }
                    return '';
                })
                .join(', '),
        },
        { title: '제목', content: question.title },
        {
            title: '내용',
            content: parse(question.content),
        },
    ];

    return (
        <>
            <PageHeader
                title="자주하는 질문 상세"
                breadcrumbItems={[
                    { name: '자주하는 질문' },
                    { name: '자주하는 질문 상세' },
                ]}
            />
            <ColumnTable columnContents={columnContents} />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mt: '10px',
                }}
            >
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        setIsOpenRemoveNewsModal(true);
                    }}
                >
                    삭제
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        navigate('edit');
                    }}
                >
                    수정
                </Button>
            </Box>
            <DialogModal
                open={isOpenRemoveNewsModal}
                close={() => {
                    setIsOpenRemoveNewsModal(false);
                }}
                rightBtnProps={{ color: 'error' }}
                rightBtnChildren="삭제"
                handleClickRightBtn={() => {
                    setIsOpenRemoveNewsModal(false);
                }}
            >
                삭제하시겠습니까?
            </DialogModal>
        </>
    );
}

export default QuestionDetail;
