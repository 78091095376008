import React, { useState } from 'react';
import { Button, Checkbox, FormControlLabel, Switch } from '@mui/material';
import { Box } from '@mui/material';
import { Link } from 'react-router-dom';
import PasswordModal from '../../Components/Modal/Password';
import ColumnTable from '../../Components/Table/Colunm';
import PageHeader from './../../Components/PageHeader';
import DialogModal from '../../Components/Modal/DialogModal';

const checkbox = [
    { id: '1', label: '회원관리' },
    { id: '2', label: '협력사 관리' },
    { id: '3', label: '견적 관리' },
    { id: '4', label: '예약 관리' },
    { id: '5', label: '장례 관리' },
    { id: '6', label: '주문/배송 관리' },
    { id: '7', label: '상품 관리' },
    { id: '8', label: '장지 서비스' },
    { id: '9', label: '재무 관리' },
    { id: '10', label: '데이터 관리' },
    { id: '11', label: '컨텐츠 관리' },
    { id: '12', label: '온라인 관리' },
    { id: '13', label: '고객센터' },
    { id: '14', label: '메일링' },
    { id: '15', label: '메시지' },
    { id: '16', label: '통계' },
    { id: '17', label: 'Google Analytics' },
];

function AddAdmin() {
    const [isOpenRemoveModal, setIsOpenRemoveModal] = useState(false);

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const account = {
        id: 0,
        name: '이경민',
        userId: 'kkmin',
        gradeId: 0,
        lastAccess: '2021.10.12 13:26',
    };

    const columnContents = [
        {
            title: '아이디',
            content: 'ijnbm455',
            bgColor: '#fff',
        },
        {
            title: '이름',
            content: 'kildong',
            bgColor: '#fff',
        },
        {
            title: '비밀번호',
            content: (
                <div style={{ display: 'flex' }}>
                    ijnbm455
                    <Box
                        component="button"
                        onClick={handleOpen}
                        sx={{ color: '#007AFF', ml: '24px' }}
                    >
                        비밀번호 변경
                    </Box>
                </div>
            ),
            bgColor: '#fff',
        },
        {
            title: '권한',
            padding: '6px',
            content: (
                <>
                    {checkbox.map((checkbox, idx) => (
                        <FormControlLabel
                            key={idx}
                            label={checkbox.label}
                            sx={{
                                margin: '0 10px 0 0',
                                fontSize: '14px',
                            }}
                            disableTypography
                            control={
                                <Checkbox
                                    size="small"
                                    indeterminate
                                    style={{ color: 'rgba(0, 0, 0, 0.6)' }}
                                    disabled
                                />
                            }
                        />
                    ))}
                </>
            ),
            bgColor: '#fff',
        },
        {
            title: '로그인 제한',
            content: (
                <Box sx={{ color: '#888888' }}>
                    <Switch color="error" />
                    로그인을 제한할 경우 로그인이 되지 않습니다.
                </Box>
            ),
            padding: '7px 4px',
            bgColor: '#fff',
        },
    ];

    return (
        <>
            <PageHeader
                title="관리자 계정관리"
                breadcrumbItems={[
                    { name: '관리자 계정관리' },
                    { name: '관리자 계정정보' },
                ]}
                prevPageBtn
            />
            <ColumnTable columnContents={columnContents} />
            <PasswordModal open={open} close={handleClose} />
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: '10px',
                }}
            >
                <Button
                    variant="outlined"
                    size="large"
                    color="error"
                    sx={{ ml: '20px' }}
                    onClick={() => setIsOpenRemoveModal(true)}
                >
                    삭제
                </Button>
                <Link to={'edit'}>
                    <Button variant="contained" size="large">
                        수정
                    </Button>
                </Link>
            </Box>
            <DialogModal
                open={isOpenRemoveModal}
                close={() => {
                    setIsOpenRemoveModal(false);
                }}
                handleClickRightBtn={() => {
                    setIsOpenRemoveModal(false);
                }}
                rightBtnProps={{ color: 'error' }}
                rightBtnChildren={'삭제'}
            >
                삭제하시겠습니까?
            </DialogModal>
        </>
    );
}

export default AddAdmin;
