import React from 'react';
import { useNavigate } from 'react-router-dom';
import Title from './../../../Components/Title';
import QuestionForm from './QuestionForm';
import PageHeader from './../../../Components/PageHeader';

function AddQuestion() {
    const navigate = useNavigate();

    return (
        <>
            <PageHeader
                title="자주하는 질문 추가"
                breadcrumbItems={[
                    { name: '자주하는 질문' },
                    { name: '자주하는 질문 추가' },
                ]}
            />
            <QuestionForm
                onSubmit={() => {
                    navigate('/service/questions');
                }}
            />
        </>
    );
}

export default AddQuestion;
