import PageHeader from '../../Components/PageHeader';
import Title from '../../Components/Title';
import MessageForm from './MessageForm';

function EditMessage() {
    const onSubmit = (data) => {};
    const message = {
        title: '제목입니다',
        message: '문자입니다',
        content: '<p><strong>내용입니다.</strong></p>',
    };

    return (
        <>
            <PageHeader
                title="메세지 수정"
                breadcrumbItems={[
                    { name: '메세지' },
                    { name: '메세지 상세' },
                    { name: '메세지 수정' },
                ]}
            />

            <MessageForm onSubmit={onSubmit} isEdit message={message} />
        </>
    );
}

export default EditMessage;
