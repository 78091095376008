import { TableCell, TableRow } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import Table from '../../Components/Table';
import Pagination from '../../Components/Table/Pagination';
import Title from '../../Components/Title';
import InquireConsultModal from './InquireConsultModal';

function Inquire() {
    const [isOpenInquireConsultModal, setIsOpenInquireConsultModal] =
        useState(false);

    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const inquires = [
        {
            type: '사용자',
            inquirer: '홍길동',
            content: '예약이 불발되었는데 돈은 언제 돌려줍니까',
            inquireDate: '2021.11.12 04:43',
        },
    ];

    const inquireTableCellProps = [{ align: 'center' }, {}, {}, {}, {}];

    return (
        <>
            <Title header={{ title: '온라인 문의' }} />
            <Table
                numeric
                reverse
                columns={[
                    { content: '구분' },
                    { content: '문의자' },
                    { content: '내용' },
                    { content: '날짜' },
                ]}
                tableCellProps={inquireTableCellProps}
                items={inquires.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            setIsOpenInquireConsultModal(true);
                        }}
                    >
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },
                            {
                                content: ele.type,
                            },
                            { content: ele.inquirer },
                            { content: ele.content },
                            { content: ele.inquireDate },
                        ].map(({ content }, idx, columns) => (
                            <TableCell
                                key={idx}
                                {...inquireTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
            <InquireConsultModal
                open={isOpenInquireConsultModal}
                close={() => {
                    setIsOpenInquireConsultModal(false);
                }}
            />
        </>
    );
}

export default Inquire;
