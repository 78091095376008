import React from 'react';
import Title from './../../../Components/Title';
import { numberWithCommas } from './../../../common/util';
import { Box } from '@mui/material';
import ColumnTable from '../../../Components/Table/Colunm';
import PageHeader from './../../../Components/PageHeader';

function WithdrawDetail() {
    const withdraw = {
        id: 0,
        requester: '홍길동',
        price: 1000000,
        bankAccount: '기업은행 01023456789',
        requestDate: '2021.11.12 15:20',
        sendStatusCd: '0',
        sendDate: '2021.11.12 09:25',
    };

    const columnContents = [
        { title: '요청자', content: withdraw.requester },
        { title: '요청금액', content: numberWithCommas(withdraw.price) },
        { title: '수령계좌', content: withdraw.bankAccount },
        { title: '요청일', content: withdraw.requestDate },
        {
            title: '지급상태',
            content:
                withdraw.sendStatusCd === '0' ? (
                    `지급완료 (${withdraw.sendDate})`
                ) : withdraw.sendStatusCd === '1' ? (
                    <Box color={'primary5.main'}>예정</Box>
                ) : (
                    ''
                ),
        },
    ];

    return (
        <>
            <PageHeader
                title="출금 관리 상세"
                breadcrumbItems={[
                    { name: '출금 관리' },
                    { name: '출금 관리 상세' },
                ]}
            />
            <ColumnTable columnContents={columnContents} />
        </>
    );
}

export default WithdrawDetail;
