import styled from '@emotion/styled';
import { Button, Table, TableContainer, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { set } from 'date-fns';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ColumnTable from '../../../Components/Table/Colunm';
import Title from '../../../Components/Title';
import ErrorMessage from './../../../Components/Form/ErrorMessage';

function EditGift() {
    const navigate = useNavigate();
    const { control, handleSubmit } = useForm();
    const { giftId } = useParams();

    const giftBusiness = {
        name: '고려기프트(주)',
        businessNumber: '210-81-60114',
        representative: '이흥원, 김금숙',
        address: '서울특별시 도봉구 도봉로 191 가길 8 (도봉동)',
        phone: '02-956-6700',
    };

    const columnContents = [
        {
            title: '협회명',
            content: (
                <Controller
                    control={control}
                    name="name"
                    rules={{ required: '협회명을 입력하세요.' }}
                    defaultValue={giftBusiness.name}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '사업자번호',
            content: (
                <Controller
                    control={control}
                    name="businessNumber"
                    rules={{ required: '사업자번호를 입력하세요.' }}
                    defaultValue={giftBusiness.businessNumber}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '대표자명',
            content: (
                <Controller
                    control={control}
                    name="representative"
                    rules={{ required: '대표자명을 입력하세요.' }}
                    defaultValue={giftBusiness.representative}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '주소',
            content: (
                <Controller
                    control={control}
                    name="address"
                    rules={{ required: '주소를 입력하세요.' }}
                    defaultValue={giftBusiness.address}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '잔화번호',
            content: (
                <Controller
                    control={control}
                    name="phone"
                    rules={{ required: '전화번호를 입력하세요.' }}
                    defaultValue={giftBusiness.phone}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
    ].map((ele) => ({
        ...ele,
        contentSx: { padding: '6px' },
    }));

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <>
            <Title header={{ title: '답례품' }} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <ColumnTable columnContents={columnContents} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: '10px',
                    }}
                >
                    <Button
                        variant="outlined"
                        size="large"
                        color="secondary"
                        sx={{ mr: '15px' }}
                        onClick={() => {
                            navigate(`/partner/gift/${giftId}`);
                        }}
                    >
                        취소
                    </Button>
                    <Button variant="contained" size="large" type="submit">
                        저장
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default EditGift;
