import styled from '@emotion/styled';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import React from 'react';
import ErrorMessage from './../../Form/ErrorMessage';

function ColumnTable({ columnContents, maxColumnCnt = 2 }) {
    return (
        <TableContainer>
            <Table
                sx={{
                    borderWidth: '1px 0px 1px',
                    borderStyle: 'solid',
                    borderColor: '#E0E0E0',
                    borderRight: '1px solid #F2F2F2',
                }}
            >
                <TableBody>
                    {columnContents.map((columnContent, idx) => (
                        <React.Fragment key={idx}>
                            {columnContent.children || (
                                <TableRow
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            borderBottom: 0,
                                        },
                                    }}
                                >
                                    <TableCell
                                        sx={{
                                            width: '145px',
                                            background: columnContent.bgColor
                                                ? null
                                                : 'rgba(48, 55, 66, 0.07)',
                                            borderRight: '1px solid #E0E0E0',
                                            fontWeight: 500,
                                            verticalAlign: 'baseline',
                                        }}
                                        colSpan={1}
                                    >
                                        {columnContent.title}
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            padding: columnContent.padding,
                                            color: columnContent.color,
                                            ...columnContent.contentSx,
                                        }}
                                        onClick={columnContent.onclick}
                                        // 2번째 cell은 maxColumnCnt - 1개만큼 차지
                                        colSpan={maxColumnCnt - 1}
                                        {...columnContent.contentProps}
                                    >
                                        {columnContent.content}
                                        <ErrorMessage
                                            error={columnContent.error}
                                        />
                                    </TableCell>
                                </TableRow>
                            )}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default ColumnTable;
