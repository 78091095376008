import React, { useState } from 'react';
import Title from '../../../Components/Title';
import { TableCell, TableRow, Box } from '@mui/material';
import { moneyWithCommas } from '../../../common/util';
import ColumnTable from '../../../Components/Table/Colunm';
import { useNavigate } from 'react-router-dom';
import Pagination from './../../../Components/Table/Pagination';
import Table from './../../../Components/Table/index';
import RequestResultViewModal from './RequestResultViewModal';
import PageHeader from './../../../Components/PageHeader';

function RequestDetail() {
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();
    const requestTableCellProps = [
        { align: 'center' },
        { align: 'center' },
        {},
        { align: 'center' },
        {},
        { align: 'right' },
        { align: 'center' },
    ];
    const requests = [
        {
            id: 0,
            requestedDate: '2021.11.25',
            cemetery: '화성하늘공원수목장림',
            deceased: '배회호',
            content: '헌화 해 주세요 (장미 꽃 다발 50,000원)',
            price: 50000,
            status: '1',
        },
        {
            id: 1,
            requestedDate: '2021.11.25',
            cemetery: '(재)용인추모원 자연장지',
            deceased: '홍인수',
            content: '새로운 사진을 찍어주세요.',
            price: 30000,
            status: '0',
            requestResult: {
                date: '2021년 11월 12일 ~ 2021년 11월 14일 (3일)',
                title: '장미꽃 다발 헌화',
                content: (
                    <>
                        <img
                            style={{
                                width: '200px',
                                height: '200px',
                                objectFit: 'cover',
                            }}
                            alt="request result"
                            src="https://s3-alpha-sig.figma.com/img/d7cc/ec53/e87258f84702db00b1343dadf2b0af3a?Expires=1662336000&Signature=cgeL4x2aYBia~SinpCMx~Agm-E3y3d88ApGLvB2HXIkxRImVGaG0b5b7wd2a5xByWSbfO0BTThUdG2uwEp~~U4B91cKYqH9SdcaoE82uzvwO5waI65RngfqlKcka0bK9SV~YPjzCF0-sykqf2jrHIFerMstCAqC628iqBZNqrAlgmit7q62HQ2Z3-4gb7xihTQ-TcUIhlw9IBLLZvLVLoFHfJCLk3gy5gsEcHT10yYvDTvH6kZ5HawzaJFhDEB4m-YGKS70Z1sEQk2r3h5xflJwFLvvLoHicOM5CKRtYgSV9CutfoQ4ocGqKxGOMvbkKR0kTAmQ6XTWUSSehgMWpWQ__&Key-Pair-Id=APKAINTVSUGEWH5XD5UA"
                        />
                        사진 올려드립니다.
                    </>
                ),
            },
        },
    ];
    const request = requests[1];
    const columnContents = [
        { title: '장지', content: request.cemetery },
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell
                        sx={{
                            width: '145px',
                            background: 'rgba(48, 55, 66, 0.07)',
                            borderRight: '1px solid #E0E0E0',
                            fontWeight: 500,
                            verticalAlign: 'baseline',
                        }}
                    >
                        고인
                    </TableCell>
                    <TableCell>{request.deceased}</TableCell>
                    <TableCell
                        sx={{
                            width: '145px',
                            background: 'rgba(48, 55, 66, 0.07)',
                            borderRight: '1px solid #E0E0E0',
                            fontWeight: 500,
                            verticalAlign: 'baseline',
                        }}
                    >
                        계약자
                    </TableCell>
                    <TableCell>{request.deceased}</TableCell>
                </TableRow>
            ),
        },
        { title: '요청', content: request.content },
        { title: '금액', content: moneyWithCommas(request.price) },
        { title: '요청일', content: request.requestedDate },
        {
            title: '처리상태',
            content:
                request.status === '0' ? (
                    '완료'
                ) : request.status === '1' ? (
                    <Box color="primary5.main">처리전</Box>
                ) : (
                    ''
                ),
        },
    ];
    if (request.status === '0') {
        columnContents.push({
            title: '처리결과',
            content: <div>{request.requestResult.content}</div>,
        });
    }

    return (
        <>
            <PageHeader
                title="요청 관리 상세"
                breadcrumbItems={[
                    { name: '요청 관리' },
                    { name: '요청 관리 상세' },
                ]}
            />
            <Box
                sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#303742',
                    mb: '10px',
                }}
            >
                요청내역
            </Box>
            <ColumnTable columnContents={columnContents} maxColumnCnt={4} />
            <Box
                sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#303742',
                    mt: '45px',
                    mb: '10px',
                }}
            >
                납부내역
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    { content: '요청일' },
                    { content: '장지' },
                    { content: '고인' },
                    { content: '요청' },
                    { content: '금액' },
                    { content: '처리상태' },
                ]}
                tableCellProps={requestTableCellProps}
                items={requests.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={(e) => {
                            // TableRow혹은 TableCell을통해 onClick이벤트가 발생하는경우만 핸들러 실행
                            if (
                                e.target.nodeName === 'TD' ||
                                e.target.nodeName === 'TR'
                            ) {
                                navigate(`/cemetery/request/${idx}`);
                            }
                        }}
                    >
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },
                            {
                                content: ele.requestedDate,
                            },
                            { content: ele.cemetery },
                            { content: ele.deceased },
                            { content: ele.content },
                            { content: moneyWithCommas(ele.price) },
                            {
                                content:
                                    ele.status === '0' ? (
                                        <RequestResultViewModal
                                            requestResult={ele.requestResult}
                                        >
                                            완료
                                        </RequestResultViewModal>
                                    ) : ele.status === '1' ? (
                                        <Box
                                            color="primary5.main"
                                            padding={'16px'}
                                        >
                                            처리전
                                        </Box>
                                    ) : (
                                        ''
                                    ),
                                props: { padding: 'none' },
                            },
                        ].map(({ content, props }, idx, columns) => (
                            <TableCell
                                key={idx}
                                {...requestTableCellProps[idx]}
                                {...props}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
        </>
    );
}

export default RequestDetail;
