import { forwardRef, useMemo } from 'react';
import clsx from 'clsx';
import { styled, Box } from '@mui/system';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

const BackdropUnstyled = forwardRef((props, ref) => {
    const { open, className, ...other } = props;
    return (
        <div
            className={clsx({ 'MuiBackdrop-open': open }, className)}
            ref={ref}
            {...other}
        />
    );
});
BackdropUnstyled.displayName = 'BackdropUnstyled';

const Modal = styled(ModalUnstyled)`
    position: fixed;
    z-index: 1300;
    right: 0;
    bottom: 0;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const BACKDROP_TYPES = {
    OPACITY: 'OPACITY',
    TRANSPARENT: 'TRANSPARENT',
};

const BackdropConstructor = ({ type = BACKDROP_TYPES.TRANSPARENT }) => {
    // 배경화면이 투명
    if (type === BACKDROP_TYPES.TRANSPARENT) {
        return styled(BackdropUnstyled)`
            z-index: -1;
            position: fixed;
            right: 0;
            bottom: 0;
            top: 0;
            left: 0;
            -webkit-tap-highlight-color: transparent;
            background: transparent;
        `;
    }
    // 배경화면에 opacity효과
    if (type === BACKDROP_TYPES.OPACITY) {
        return styled(BackdropUnstyled)`
            z-index: -1;
            position: fixed;
            right: 0;
            bottom: 0;
            top: 0;
            left: 0;
            -webkit-tap-highlight-color: transparent;
            background: rgba(0, 0, 0, 0.1);
        `;
    }
};

function ModalLayout({
    open,
    close,
    headerChildren,
    children,
    modalContentWrapperProps,
    backdropType = BACKDROP_TYPES.TRANSPARENT,
}) {
    const Backdrop = useMemo(
        () => BackdropConstructor({ type: backdropType }),
        [backdropType]
    );

    return (
        <Modal disableAutoFocus open={open} components={{ Backdrop }}>
            <Box
                {...modalContentWrapperProps}
                sx={{
                    background: '#FFFFFF',
                    boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.15)',
                    borderRadius: '10px',
                    minWidth: '300px',
                    ...(modalContentWrapperProps &&
                        modalContentWrapperProps.sx),
                }}
            >
                {headerChildren ? (
                    headerChildren
                ) : (
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            padding: '5px 5px 0',
                        }}
                    >
                        <IconButton
                            size="small"
                            sx={{ padding: '5px' }}
                            onClick={close}
                        >
                            <CloseIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                        </IconButton>
                    </Box>
                )}
                {children}
            </Box>
        </Modal>
    );
}

export default ModalLayout;
