import { Button } from '@mui/material';
import { Box } from '@mui/system';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import ColumnTable from '../../../Components/Table/Colunm';
import Title from '../../../Components/Title';

function Gift() {
    const navigate = useNavigate();
    const { control, handleSubmit } = useForm();
    const giftBusiness = {
        id: 0,
        name: '고려기프트(주)',
        businessNumber: '210-81-60114',
        representative: '이흥원, 김금숙',
        address: '서울특별시 도봉구 도봉로 191 가길 8 (도봉동)',
        phone: '02-956-6700',
    };

    const columnContents = [
        {
            title: '협회명',
            content: giftBusiness.name,
        },
        {
            title: '사업자번호',
            content: giftBusiness.businessNumber,
        },
        {
            title: '대표자명',
            content: giftBusiness.representative,
        },
        {
            title: '주소',
            content: giftBusiness.address,
        },
        {
            title: '잔화번호',
            content: giftBusiness.phone,
        },
    ].map((ele) => ({
        ...ele,
        contentSx: { padding: '16px' },
    }));

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <>
            <Title header={{ title: '답례품' }} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <ColumnTable columnContents={columnContents} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: '10px',
                    }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                            navigate(`/partner/gift/${giftBusiness.id}/edit`);
                        }}
                    >
                        변경
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default Gift;
