import React, { useState } from 'react';

import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TableRow,
    TableCell,
} from '@mui/material';
import { Box, styled } from '@mui/system';

import Title from '../../../Components/Title';
import DatePickerTable from '../../../Components/Table/DatePickerTable';
import { useForm } from 'react-hook-form';
import Table from '../../../Components/Table';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import EstimateModal from '../../../Components/Modal/EstimateModal';
import { moneyWithCommas } from '../../../common/util';
import Pagination from '../../../Components/Table/Pagination';

function ReserveBurial() {
    const { control } = useForm();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const reserveBurial = [
        {
            requestDate: '2021.11.05',
            endDate: '2021.11.14 (목)',
            tomb: '',
            crematorium: true,
            vehicle: true,
            deceased: '홍경수',
            price: 11240000,
            reservation: 2,
        },

        {
            requestDate: '어제',
            endDate: '2021.11.14 (목)',
            tomb: true,
            crematorium: true,
            vehicle: true,
            deceased: '홍경수',
            price: 11240000,
            reservation: 1,
        },
        {
            requestDate: '오늘',
            endDate: '2021.11.14 (목)',
            tomb: true,
            crematorium: true,
            vehicle: true,
            deceased: '홍경수',
            price: 9500000,
            reservation: 0,
        },
    ];

    const ReserveBurialTableCellProps = [
        { width: '60px', align: 'center' },
        { width: '120px' },
        { width: '' },
        { width: '70px', align: 'center', padding: 'none' },
        { width: '70px', align: 'center', padding: 'none' },
        { width: '70px', align: 'center', padding: 'none' },
        { width: '120px', align: 'center' },
        { width: '140px', align: 'right' },
        { width: '100px', align: 'center', padding: 'none' },
    ];

    const estimate = {
        children: [
            {
                title: '견적금액',
                children: [{ totalPrice: 3450000 }],
            },
            {
                title: '발인일',
                children: [{ content: '2021년 11월 14일 (목)' }],
            },
            {
                title: '장래식장',
                children: [
                    {
                        content: '동작경희병원 장례식장',
                        address: '서울 동작구 상도로 146 동작경희병원',
                    },
                ],
            },
            {
                title: '장지',
                children: [
                    {
                        tomb: '서울시립자연장시설',
                        address: '경기도 파주시 광탄면 용미리 산107',
                    },
                    {
                        content: (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                봉안
                                <ChevronRightIcon />
                                실내/믿음관
                                <ChevronRightIcon />
                                F2
                            </Box>
                        ),
                    },
                ],
            },
            {
                title: '화장장',
                children: [
                    {
                        tomb: '서울시립승화원(고양시)',
                        address: '경기도 고양시 덕양구 통일로 504',
                    },
                    { content: '07:00 ~ 09:00', price: 120000 },
                ],
            },
            {
                title: '차량',
                children: [
                    { content: '리무진 5인승 1대', price: 600000 },
                    { content: '버스 45인승 2대', price: 2000000 },
                ],
            },
            { subTitle: '계약자 정보' },

            { title: '고인명', children: [{ content: '홍경수' }] },
            { title: '계약자명', children: [{ content: '홍길동' }] },
            { title: '휴대전화', children: [{ content: '010-0987-1234' }] },
            { subTitle: '화장장 연고자 정보' },

            { title: '설명', children: [{ content: '홍서경 (내국인)' }] },
            {
                title: '생년월일',
                children: [{ content: '1991년 05월 17일 (남자)' }],
            },
            { title: '휴대전화', children: [{ content: '010-2345-6789' }] },
            {
                title: '주소',
                children: [{ content: '부산 부산진구 당감동 산34' }],
            },
            { title: '고인과의 관계', children: [{ content: '자녀' }] },
            {
                title: '고인위치',
                children: [
                    {
                        map: (
                            <Box
                                sx={{
                                    height: '180px',
                                    background: '#918080',
                                    mt: '20px',
                                    width: '388px',
                                }}
                            />
                        ),
                    },
                ],
            },
        ],
    };

    return (
        <>
            <Title header={{ title: '발인 예약' }} />
            <DatePickerTable title={'기간'} title2={'고인'} control={control} />
            <Box
                sx={{
                    minWidth: 120,
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <FormControl sx={{ m: '8px', minWidth: 120 }} size="small">
                    <InputLabel htmlFor="grouped-select">지역</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1} sx={{ textAlign: 'left' }}>
                            전국
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">예약 상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                        <MenuItem value={2}>요청</MenuItem>
                        <MenuItem value={3}>예약됨</MenuItem>
                        <MenuItem value={4}>불발</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    {
                        content: '요청일',
                    },
                    {
                        content: '발인일',
                    },
                    {
                        content: '장지',
                    },
                    {
                        content: '화장장',
                    },
                    {
                        content: '차량',
                    },
                    {
                        content: '고인명',
                    },
                    {
                        content: '견적금액',
                    },
                    {
                        content: '예약',
                    },
                ]}
                tableCellProps={ReserveBurialTableCellProps}
                items={reserveBurial.map((ele, idx) => (
                    <TableRow key={idx} onClick={handleOpen}>
                        {[
                            { content: page * itemsPerPage + (idx + 1) },
                            { content: ele.requestDate },
                            { content: ele.endDate },
                            {
                                content: ele.tomb && (
                                    <RadioButtonUncheckedIcon fontSize="small" />
                                ),
                            },
                            {
                                content: ele.crematorium && (
                                    <RadioButtonUncheckedIcon fontSize="small" />
                                ),
                            },
                            {
                                content: ele.vehicle && (
                                    <RadioButtonUncheckedIcon fontSize="small" />
                                ),
                            },
                            { content: ele.deceased },
                            { content: moneyWithCommas(ele.price) },
                            {
                                content:
                                    ele.reservation === 0 ? (
                                        <Box
                                            component="button"
                                            width={'100%'}
                                            padding={'16px'}
                                            color="primary5.main"
                                        >
                                            요청
                                        </Box>
                                    ) : ele.reservation === 1 ? (
                                        <Box
                                            component="button"
                                            width={'100%'}
                                            padding={'16px'}
                                            color="primary4.main"
                                        >
                                            예약됨
                                        </Box>
                                    ) : ele.reservation === 2 ? (
                                        <Box
                                            width={'100%'}
                                            padding={'16px'}
                                            color="#888888"
                                        >
                                            불발
                                        </Box>
                                    ) : (
                                        ''
                                    ),
                            },
                        ].map(({ content }, idx) => (
                            <TableCell
                                key={idx}
                                {...ReserveBurialTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ marginTop: '25px' }}
            />
            <EstimateModal
                estimate={estimate}
                title={'발인 예약'}
                open={open}
                close={handleClose}
            />
        </>
    );
}

export default ReserveBurial;
