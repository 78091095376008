import React, { useState } from 'react';

import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TableRow,
    TableCell,
} from '@mui/material';
import { Box } from '@mui/system';

import Title from '../../../Components/Title';
import DatePickerTable from '../../../Components/Table/DatePickerTable';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { useForm } from 'react-hook-form';
import Table from '../../../Components/Table';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../Components/Table/Pagination';

function ReserveBurial() {
    const { control } = useForm();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();

    const Burial = [
        {
            requestDate: '2021.11.05',
            endDate: '2021.11.14 (목)',
            HopeArea: '경기도 수원시',
            crematorium: true,
            vehicle: true,
            Estimate: 4,
        },
        {
            requestDate: '어제',
            endDate: '2021.11.14 (목)',
            HopeArea: '서울 동작구',
            tomb: true,
            crematorium: true,
            vehicle: true,
            Estimate: 5,
        },
        {
            requestDate: '오늘',
            endDate: '2021.11.14 (목)',
            HopeArea: '서울 성북구',
            tomb: true,
            crematorium: true,
            vehicle: true,
            Estimate: 0,
        },
    ];

    const burialTableCellProps = [
        { width: '60px', align: 'center' },
        { width: '120px' },
        {},
        { width: '140px' },
        { width: '70px', align: 'center', padding: 'none' },
        { width: '70px', align: 'center', padding: 'none' },
        { width: '70px', align: 'center', padding: 'none' },
        { width: '100px', align: 'center' },
    ];

    return (
        <>
            <Title header={{ title: '발인 견적 관리' }} />
            <DatePickerTable title={'기간'} title2={'고인'} control={control} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">운영 상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    {
                        content: '요청일',
                    },
                    {
                        content: '발인일',
                    },
                    {
                        content: '희망지역',
                    },
                    {
                        content: '장지',
                    },
                    {
                        content: '화장장',
                    },
                    {
                        content: '차량',
                    },
                    {
                        content: '견적서',
                    },
                ]}
                tableCellProps={burialTableCellProps}
                items={Burial.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/estimate/burial/${idx}`)}
                    >
                        {[
                            { content: page * itemsPerPage + (idx + 1) },
                            { content: ele.requestDate },
                            { content: ele.endDate },
                            { content: ele.HopeArea },
                            {
                                content: ele.tomb && (
                                    <RadioButtonUncheckedIcon
                                        fontSize="small"
                                        sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                                    />
                                ),
                            },
                            {
                                content: ele.crematorium && (
                                    <RadioButtonUncheckedIcon
                                        fontSize="small"
                                        sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                                    />
                                ),
                            },
                            {
                                content: ele.vehicle && (
                                    <RadioButtonUncheckedIcon
                                        fontSize="small"
                                        sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
                                    />
                                ),
                            },
                            { content: ele.Estimate },
                        ].map(({ content }, idx) => (
                            <TableCell key={idx} {...burialTableCellProps[idx]}>
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ marginTop: '25px' }}
            />
        </>
    );
}
export default ReserveBurial;
