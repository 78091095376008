import React, { useState } from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';

import styled from '@emotion/styled';
import Title from '../../../Components/Title';
import ColumnTable from '../../../Components/Table/Colunm';
import EstimateModal from '../../../Components/Modal/EstimateModal';
import Table from '../../../Components/Table';
import { Box } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import { moneyWithCommas } from '../../../common/util';
import Pagination from '../../../Components/Table/Pagination';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const SubTitle = styled(Box)`
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    color: #303742;
    margin-bottom: 10px;
`;

const StyledTableCell = {
    minWidth: '145px',
    background: 'rgba(48, 55, 66, 0.07)',
    borderRight: '1px solid #E0E0E0',
    fontWeight: 500,
    verticalAlign: 'baseline',
    color: '#191919',
};

function BurialDetail() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const columnContents = [
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell sx={StyledTableCell}>발인일</TableCell>
                    <TableCell width={'50%'}>2021.11.14 (목)</TableCell>
                    <TableCell sx={StyledTableCell}>장례식장</TableCell>
                    <TableCell width={'50%'}>동작경희병원장례식장</TableCell>
                </TableRow>
            ),
        },
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell sx={StyledTableCell}>고인명</TableCell>
                    <TableCell width={'50%'}>홍경수</TableCell>
                    <TableCell sx={StyledTableCell}>종교</TableCell>
                    <TableCell width={'50%'}>서울</TableCell>
                </TableRow>
            ),
        },
        {
            title: '장지종료',
            content: (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        자연장
                        <ChevronRightIcon sx={{ margin: '0 12px 0 12px' }} />
                        수목장
                    </Box>
                </>
            ),
            padding: '0 16px',
        },
        {
            title: '화장장 관내지역',
            content: (
                <>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        경기
                        <ChevronRightIcon sx={{ margin: '0 12px 0 12px' }} />
                        경기
                    </Box>
                </>
            ),
            padding: '0 16px',
        },
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell sx={StyledTableCell}>의전 리무진</TableCell>
                    <TableCell width={'50%'}>5인 1대 </TableCell>
                    <TableCell sx={StyledTableCell}>장의버스</TableCell>
                    <TableCell width={'50%'}>40인승 1대</TableCell>
                </TableRow>
            ),
        },
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell sx={StyledTableCell}>고인명</TableCell>
                    <TableCell width={'50%'}>홍경수</TableCell>
                    <TableCell sx={StyledTableCell}>요청자</TableCell>
                    <TableCell width={'50%'}>홍길동</TableCell>
                </TableRow>
            ),
        },
    ];

    const receivedEstimate = [
        {
            id: 0,
            funeral: '서울보라매병원장례식장',
            funeralAddress: '경기 파주시 광탄면 용미리 산107',
            crematorium: '서울시립승화원(고양시)',
            crematoriumAddress: '경기도 고양시 덕양구 통일로 504',
            operatingTime: '07:00 ~ 09:00 ',
            funeralDirector: '홍경수',
            price: 8400000,
            reservation: true,
        },
    ];

    const BurialTableCellPropsDetail = [
        {
            align: 'center',
            width: '60px',
        },
        {},
        {},
        {
            align: 'center',
            width: '120px',
        },
        {
            align: 'right',
            width: '150px',
        },
        {
            align: 'center',
            width: '100px',
            padding: 'none',
        },
    ];

    const estimate = {
        children: [
            {
                title: '견적금액',
                children: [{ totalPrice: 3450000 }],
            },
            {
                title: '발인일',
                children: [{ content: '2021년 11월 14일 (목)' }],
            },
            {
                title: '장래식장',
                children: [
                    {
                        content: '동작경희병원 장례식장',
                        address: '서울 동작구 상도로 146 동작경희병원',
                    },
                ],
            },
            {
                title: '장지',
                children: [
                    {
                        tomb: '서울시립자연장시설',
                        address: '경기도 파주시 광탄면 용미리 산107',
                    },
                    {
                        content: (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                봉안
                                <ChevronRightIcon />
                                실내/믿음관
                                <ChevronRightIcon />
                                F2
                            </Box>
                        ),
                    },
                    {
                        content: '안치 위치',
                        map: (
                            <Box
                                sx={{
                                    height: '180px',
                                    background: '#918080',
                                    mt: '20px',
                                    width: '388px',
                                }}
                            />
                        ),
                    },
                ],
            },
            {
                title: '화장장',
                children: [
                    {
                        tomb: '서울시립승화원(고양시)',
                        address: '경기도 고양시 덕양구 통일로 504',
                    },
                    { content: '07:00 ~ 09:00', price: 120000 },
                ],
            },
            {
                title: '차량',
                children: [
                    { content: '리무진 5인승 1대', price: 600000 },
                    { content: '버스 45인승 2대', price: 2000000 },
                ],
            },
        ],
    };

    return (
        <>
            <Title header={{ title: '발인 견적 관리' }} />
            <SubTitle>견적 요청</SubTitle>
            <ColumnTable columnContents={columnContents} maxColumnCnt={4} />
            <SubTitle mt={'45px'}>받은 견적</SubTitle>
            <Table
                numeric
                reverse
                columns={[
                    {
                        content: '장지',
                    },
                    {
                        content: '화장장',
                    },
                    {
                        content: '장례지도사',
                    },
                    {
                        content: '견적금액',
                    },
                    {
                        content: '예약',
                    },
                ]}
                tableCellProps={BurialTableCellPropsDetail}
                items={receivedEstimate.map((ele, idx) => (
                    <TableRow key={idx} onClick={() => handleOpen()}>
                        {[
                            { content: page * itemsPerPage + (idx + 1) },
                            {
                                content: (
                                    <>
                                        <Box fontWeight={700}>
                                            {ele.funeral}
                                        </Box>
                                        <Box color={'#888888'}>
                                            {ele.funeralAddress}
                                        </Box>
                                    </>
                                ),
                            },
                            {
                                content: (
                                    <>
                                        <Box fontWeight={700}>
                                            {ele.crematorium}
                                        </Box>
                                        <Box color={'#888888'}>
                                            {ele.crematoriumAddress}
                                        </Box>
                                        <Box mt={'5px'}>
                                            {ele.operatingTime}
                                        </Box>
                                    </>
                                ),
                            },
                            { content: ele.funeralDirector },
                            { content: moneyWithCommas(ele.price) },
                            { content: ele.reservation && <CheckIcon /> },
                        ].map(({ content }, idx) => (
                            <TableCell
                                key={idx}
                                {...BurialTableCellPropsDetail[idx]}
                                sx={{ verticalAlign: 'baseline' }}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
            <EstimateModal
                estimate={estimate}
                title={'발인 견적서'}
                open={open}
                close={handleClose}
            />
        </>
    );
}
export default BurialDetail;
