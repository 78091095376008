import {
    TableCell as MuiTableCell,
    TableRow as MuiTableRow,
    Box,
} from '@mui/material';
import React, { useMemo } from 'react';
import { moneyWithCommas } from '../../../../../common/util';
import { styled } from '@mui/system';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const TableRow = styled(MuiTableRow)`
    &:not(.b-n) > td:nth-child(2) {
        border-right: 1px solid #e0e0e0;
    }
`;
const TableCell = styled(MuiTableCell)`
    padding: 10px 16px;
    width: 50%;
`;

function useCostColumnContents({ estimate, changeEstimate }) {
    const commonTableRowStyle = useMemo(
        () => ({
            '&:last-child td, &:last-child th': {
                borderBottom: 0,
            },
        }),
        []
    );
    const commonTableLabelColumnStyle = useMemo(
        () => ({
            minWidth: '145px',
            width: '145px',
            background: 'rgba(48, 55, 66, 0.07)',
            borderRight: '1px solid #E0E0E0',
            fontWeight: 500,
            color: '#191919',
        }),
        []
    );

    const costColumnContents = useMemo(
        () => [
            {
                children: (
                    <TableRow
                        className="b-n"
                        sx={{
                            ...commonTableRowStyle,
                            background: '#303742',
                            '& > td': {
                                padding: '16px',
                            },
                        }}
                    >
                        <TableCell
                            sx={{
                                width: '145px',
                                fontWeight: 500,
                                fontSize: '18px',
                                color: '#fff',
                            }}
                        />
                        <TableCell
                            align="center"
                            sx={{
                                fontWeight: 500,
                                fontSize: '18px',
                                color: '#fff',
                            }}
                        >
                            견적서
                        </TableCell>
                        <TableCell
                            align="center"
                            sx={{
                                fontWeight: 500,
                                fontSize: '18px',
                                color: '#fff',
                            }}
                        >
                            변경 견적서
                        </TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                        }}
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            장례식
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            align="center"
                            sx={{ background: 'rgba(48, 55, 66, 0.07)' }}
                        >
                            <div>
                                <Box
                                    sx={{
                                        '& .role': {
                                            fontWeight: 500,
                                            fontSize: '12px',
                                            color: 'rgba(0,0,0,0.6)',
                                        },

                                        '& .name': {
                                            fontWeight: 700,
                                            fontSize: '14px',
                                            color: '#191919',
                                        },

                                        '& .phone': {
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            color: '#191919',
                                        },
                                    }}
                                >
                                    <div>
                                        <span className="role">장례지도사</span>{' '}
                                        <span className="name">우상욱</span>
                                    </div>
                                    <div className="phone">010-5436-3465</div>
                                </Box>
                            </div>
                        </TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                        }}
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            장례기간
                        </TableCell>
                        <TableCell colSpan={2} sx={{ fontSize: '14px' }}>
                            2021.11.12 ~ 2021.11.14 (3일)
                        </TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                        }}
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            장례식장
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            sx={{
                                fontSize: '14px',
                                '& .address': {
                                    color: '#666',
                                },
                            }}
                        >
                            <div className="name">동작경희병원 장례식장</div>
                            <div className="address">
                                서울 동작구 상도로 146 동작경희병원
                            </div>
                        </TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <>
                        {[
                            {
                                name: '빈소',
                                keys: ['room', 'shrineFlower', 'portrait'],
                            },
                            {
                                name: '상복',
                                keys: ['manClothes', 'womanClothes'],
                            },
                            {
                                name: '예상조문객',
                                keys: ['food'],
                            },
                            { name: '운구', keys: ['move'] },
                            { name: '수시', keys: ['wash'] },
                            { name: '안치', keys: ['sepulchre'] },
                            { name: '염습', keys: ['shroud'] },
                            { name: '입관', keys: ['shroudClothes', 'coffin'] },
                            { name: '청소', keys: ['clean'] },
                            {
                                name: '인력파견',
                                keys: ['manpowerDirector', 'manpowerHelper'],
                            },
                            // 기타 항목 미구현
                            {
                                name: '기타',
                                keys: [...estimate.etc, ...changeEstimate.etc],
                            },
                        ].map(({ name, keys }, idx) =>
                            keys.map((key, idx) => {
                                const getItem = ({ estimate }) => {
                                    const item = estimate[key];
                                    if (key === 'manpowerDirector') {
                                        return {
                                            name: `장례지도사 ${item.cnt}인`,
                                            price:
                                                item.price *
                                                item.cnt *
                                                item.days,
                                        };
                                    }
                                    if (key === 'manpowerHelper') {
                                        return {
                                            name: `장례도우미 ${item.cnt}인`,
                                            price:
                                                item.price *
                                                item.cnt *
                                                item.hours,
                                        };
                                    }
                                    // 기타 항목 미구현
                                    if (name === '기타') {
                                        return (
                                            estimate.etc[idx] || {
                                                name: '기타',
                                                price: 0,
                                            }
                                        );
                                    }
                                    return item || { name: '', price: 0 };
                                };

                                const currEstimateItem = getItem({ estimate });
                                const changeEstimateItem = getItem({
                                    estimate: changeEstimate,
                                });

                                return (
                                    <TableRow
                                        key={idx}
                                        sx={{
                                            '&:last-child td, &:last-child th':
                                                {
                                                    borderBottom: 0,
                                                },
                                        }}
                                    >
                                        {idx === 0 && (
                                            <TableCell
                                                sx={{
                                                    width: '145px',
                                                    fontWeight: 500,
                                                    background:
                                                        'rgba(48, 55, 66, 0.07)',
                                                    borderRight:
                                                        '1px solid #E0E0E0',
                                                    verticalAlign: 'baseline',
                                                }}
                                                rowSpan={keys.length}
                                            >
                                                {name}
                                            </TableCell>
                                        )}

                                        <TableCell
                                            sx={{
                                                borderRight:
                                                    '1px solid #E0E0E0',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                <span className="name">
                                                    {currEstimateItem.name}
                                                </span>
                                                <span className="price">
                                                    {moneyWithCommas(
                                                        currEstimateItem.price
                                                    )}
                                                </span>
                                            </Box>
                                        </TableCell>
                                        <TableCell>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent:
                                                        'space-between',
                                                    fontSize: '14px',
                                                }}
                                            >
                                                {Boolean(
                                                    currEstimateItem.name ===
                                                        changeEstimateItem.name &&
                                                        Number(
                                                            currEstimateItem.price
                                                        ) ===
                                                            Number(
                                                                changeEstimateItem.price
                                                            )
                                                ) || (
                                                    <>
                                                        <span className="name">
                                                            {
                                                                changeEstimateItem.name
                                                            }
                                                        </span>
                                                        <Box
                                                            sx={{
                                                                fontWeight: 500,
                                                                '&.plus': {
                                                                    color: '#ff5160',
                                                                },
                                                                '&.minus': {
                                                                    color: '#485BA1',
                                                                },
                                                            }}
                                                            className={`price ${
                                                                changeEstimateItem.price -
                                                                    currEstimateItem.price >
                                                                0
                                                                    ? 'plus'
                                                                    : changeEstimateItem.price -
                                                                          currEstimateItem.price <
                                                                      0
                                                                    ? 'minus'
                                                                    : ''
                                                            }`}
                                                        >
                                                            {changeEstimateItem.price -
                                                                currEstimateItem.price >
                                                            0
                                                                ? '+'
                                                                : changeEstimateItem.price -
                                                                      currEstimateItem.price <
                                                                  0
                                                                ? '-'
                                                                : ''}{' '}
                                                            {moneyWithCommas(
                                                                Math.abs(
                                                                    changeEstimateItem.price -
                                                                        currEstimateItem.price
                                                                )
                                                            )}
                                                        </Box>
                                                    </>
                                                )}
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })
                        )}
                    </>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                            borderTop: '1.05px solid #303742',
                            borderBottom: '2px solid #303742',
                        }}
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            장례식 견적서
                        </TableCell>
                        <TableCell
                            sx={{
                                borderRight: '1px solid #E0E0E0',
                                padding: '7px 16px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    gap: '10px',
                                }}
                            >
                                <div>견적 금액</div>
                                <Box sx={{ fontWeight: 500, fontSize: '18px' }}>
                                    {moneyWithCommas(9550000)}
                                </Box>
                            </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '7px 16px' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'space-between',

                                    '& > .MuiBox-root': {
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        gap: '10px',
                                    },
                                }}
                            >
                                <Box>
                                    <Box sx={{ fontSize: '12px' }}>총 차액</Box>
                                    <Box
                                        className="plus"
                                        sx={{
                                            fontWeight: 500,
                                            '&.plus': {
                                                color: '#ff5160',
                                            },
                                            '&.minus': {
                                                color: '#485BA1',
                                            },
                                        }}
                                    >
                                        + {moneyWithCommas(9550000)}
                                    </Box>
                                </Box>
                                <Box>
                                    <Box>실 금액</Box>
                                    <Box
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '18px',
                                        }}
                                    >
                                        {moneyWithCommas(15494000)}
                                    </Box>
                                </Box>
                            </Box>
                        </TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                        }}
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                                background:
                                    'linear-gradient(0deg, rgba(195, 169, 129, 0.2), rgba(195, 169, 129, 0.2)), #FFFFFF',
                            }}
                        >
                            발인
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            align="center"
                            sx={{
                                background:
                                    'linear-gradient(0deg, rgba(195, 169, 129, 0.2), rgba(195, 169, 129, 0.2)), #FFFFFF',
                            }}
                        >
                            <div>
                                <Box
                                    sx={{
                                        '& .role': {
                                            fontWeight: 500,
                                            fontSize: '12px',
                                            color: 'rgba(0,0,0,0.6)',
                                        },

                                        '& .name': {
                                            fontWeight: 700,
                                            fontSize: '14px',
                                            color: '#191919',
                                        },

                                        '& .phone': {
                                            fontWeight: 400,
                                            fontSize: '14px',
                                            color: '#191919',
                                        },
                                    }}
                                >
                                    <div>
                                        <span className="role">장례지도사</span>{' '}
                                        <span className="name">우상욱</span>
                                    </div>
                                    <div className="phone">010-5436-3465</div>
                                </Box>
                            </div>
                        </TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                        }}
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                                background:
                                    'linear-gradient(0deg, rgba(195, 169, 129, 0.2), rgba(195, 169, 129, 0.2)), #FFFFFF',
                            }}
                        >
                            발인 견적서
                        </TableCell>
                        <TableCell colSpan={2} sx={{ fontSize: '14px' }}>
                            2021.11.14 (목)
                        </TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <>
                        <TableRow
                            sx={{
                                ...commonTableRowStyle,
                            }}
                        >
                            <TableCell
                                sx={{
                                    ...commonTableLabelColumnStyle,
                                    background:
                                        'linear-gradient(0deg, rgba(195, 169, 129, 0.2), rgba(195, 169, 129, 0.2)), #FFFFFF',
                                    verticalAlign: 'baseline',
                                }}
                                rowSpan={4}
                            >
                                장지
                            </TableCell>
                            <TableCell colSpan={2}>
                                <Box
                                    sx={{
                                        '& > .name': {
                                            fontWeight: 700,
                                        },
                                        '& > .address': {
                                            color: '#666',
                                        },
                                    }}
                                >
                                    <div className="name">
                                        서울시립자연장시설
                                    </div>
                                    <div className="address">
                                        경기도 파주시 광탄면 용미리 산107
                                    </div>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                ...commonTableRowStyle,
                            }}
                        >
                            <TableCell
                                sx={{ borderRight: '1px solid #E0E0E0' }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8.3px',
                                    }}
                                    className="fw-b"
                                >
                                    <div>일반수목</div>
                                    <ArrowForwardIosIcon
                                        sx={{ fontSize: '14px' }}
                                    />
                                    <div>F1</div>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '8.3px',
                                    }}
                                    className="fw-b"
                                >
                                    <div>일반수목</div>
                                    <ArrowForwardIosIcon
                                        sx={{ fontSize: '14px' }}
                                    />
                                    <div>G4</div>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                ...commonTableRowStyle,
                                '& > td > .MuiBox-root': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                },
                            }}
                        >
                            <TableCell
                                sx={{
                                    borderRight: '1px solid #E0E0E0',
                                }}
                            >
                                <Box>
                                    <div>이용료 40년</div>
                                    <div className="fw-b">
                                        {moneyWithCommas(3500000)}
                                    </div>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <Box>
                                    <div>이용료 40년</div>
                                    <div className="plus fw-b">
                                        {moneyWithCommas(500000)}
                                    </div>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                ...commonTableRowStyle,
                                '& > td > .MuiBox-root': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                },
                            }}
                        >
                            <TableCell
                                sx={{
                                    borderRight: '1px solid #E0E0E0',
                                }}
                            >
                                <Box>
                                    <div>관리비 5년</div>
                                    <div className="fw-b">
                                        {moneyWithCommas(230000)}
                                    </div>
                                </Box>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </>
                ),
            },
            {
                children: (
                    <>
                        <TableRow
                            sx={{
                                ...commonTableRowStyle,
                            }}
                        >
                            <TableCell
                                sx={{
                                    ...commonTableLabelColumnStyle,
                                    background:
                                        'linear-gradient(0deg, rgba(195, 169, 129, 0.2), rgba(195, 169, 129, 0.2)), #FFFFFF',
                                    verticalAlign: 'baseline',
                                }}
                                rowSpan={2}
                            >
                                화장장
                            </TableCell>
                            <TableCell colSpan={2}>
                                <Box
                                    sx={{
                                        '& > .name': {
                                            fontWeight: 700,
                                        },
                                        '& > .address': {
                                            color: '#666',
                                        },
                                    }}
                                >
                                    <div className="name">
                                        서울시립승화원(고양시)
                                    </div>
                                    <div className="address">
                                        경기도 고양시 덕양구 통일로 504
                                    </div>
                                </Box>
                            </TableCell>
                        </TableRow>
                        <TableRow
                            sx={{
                                ...commonTableRowStyle,
                                '& > td > .MuiBox-root': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                },
                            }}
                        >
                            <TableCell
                                sx={{
                                    borderRight: '1px solid #E0E0E0',
                                }}
                            >
                                <Box>
                                    <div>07:00 ~ 09:00</div>
                                    <div className="fw-b">
                                        {moneyWithCommas(120000)}
                                    </div>
                                </Box>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </>
                ),
            },
            {
                children: (
                    <>
                        <TableRow
                            sx={{
                                ...commonTableRowStyle,
                                '& > td > .MuiBox-root': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                },
                            }}
                        >
                            <TableCell
                                sx={{
                                    ...commonTableLabelColumnStyle,
                                    background:
                                        'linear-gradient(0deg, rgba(195, 169, 129, 0.2), rgba(195, 169, 129, 0.2)), #FFFFFF',
                                    verticalAlign: 'baseline',
                                }}
                                rowSpan={3}
                            >
                                차량
                            </TableCell>
                            <TableCell
                                sx={{
                                    borderRight: '1px solid #E0E0E0',
                                }}
                            >
                                <Box>
                                    <div>리무진 5인승 1대</div>
                                    <div className="fw-b">
                                        {moneyWithCommas(600000)}
                                    </div>
                                </Box>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                        <TableRow
                            sx={{
                                ...commonTableRowStyle,
                                '& > td > .MuiBox-root': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                },
                            }}
                        >
                            <TableCell
                                sx={{
                                    borderRight: '1px solid #E0E0E0',
                                }}
                            >
                                <Box>
                                    <div>버스 30인승 1대</div>
                                    <div className="fw-b">
                                        {moneyWithCommas(600000)}
                                    </div>
                                </Box>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                        <TableRow
                            sx={{
                                ...commonTableRowStyle,
                                '& > td > .MuiBox-root': {
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                },
                            }}
                        >
                            <TableCell
                                sx={{
                                    borderRight: '1px solid #E0E0E0',
                                }}
                            >
                                <Box>
                                    <div>버스 45인승 1대</div>
                                    <div className="fw-b">
                                        {moneyWithCommas(1000000)}
                                    </div>
                                </Box>
                            </TableCell>
                            <TableCell />
                        </TableRow>
                    </>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                            borderTop: '1.05px solid #303742',

                            '& > td': {
                                padding: '7px 16px',
                            },
                        }}
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                                background:
                                    'linear-gradient(0deg, rgba(195, 169, 129, 0.2), rgba(195, 169, 129, 0.2)), #FFFFFF',
                            }}
                        >
                            장례식 견적서
                        </TableCell>
                        <TableCell sx={{ borderRight: '1px solid #E0E0E0' }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    gap: '10px',
                                }}
                            >
                                <div>견적 금액</div>
                                <Box sx={{ fontWeight: 500, fontSize: '18px' }}>
                                    {moneyWithCommas(9550000)}
                                </Box>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',

                                    '& > .MuiBox-root': {
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        gap: '10px',
                                    },
                                }}
                            >
                                <Box>
                                    <Box>실 금액</Box>
                                    <Box
                                        sx={{
                                            fontWeight: 500,
                                            fontSize: '18px',
                                        }}
                                    >
                                        {moneyWithCommas(15494000)}
                                    </Box>
                                </Box>
                            </Box>
                        </TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        className="b-n"
                        sx={{
                            ...commonTableRowStyle,
                            background: '#303742',

                            '& > td': {
                                padding: '16px',
                            },
                        }}
                    >
                        <TableCell
                            sx={{
                                width: '145px',
                                fontWeight: 500,
                                fontSize: '20px',
                                color: '#fff',
                            }}
                        >
                            총 금액
                        </TableCell>
                        <TableCell>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    alignItems: 'flex-end',
                                    gap: '10px',
                                    color: '#fff',
                                    fontWeight: 500,
                                }}
                            >
                                <Box sx={{ opacity: 0.8 }}>총 견적 금액</Box>
                                <Box sx={{ fontWeight: 700, fontSize: '20px' }}>
                                    {moneyWithCommas(9550000)}
                                </Box>
                            </Box>
                        </TableCell>
                        <TableCell>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    color: '#fff',
                                    fontWeight: 500,

                                    '& > .MuiBox-root': {
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        gap: '10px',
                                    },
                                }}
                            >
                                <Box>
                                    <Box sx={{ opacity: 0.8 }}>최종 금액</Box>
                                    <Box
                                        sx={{
                                            fontWeight: 700,
                                            fontSize: '20px',
                                        }}
                                    >
                                        {moneyWithCommas(15494000)}
                                    </Box>
                                </Box>
                            </Box>
                        </TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                            background: '#F2F2F2',

                            '& > td': {
                                padding: 0,
                            },
                        }}
                    >
                        <TableCell padding="none" colSpan={2} />
                        <TableCell padding="none">
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    textAlign: 'end',
                                }}
                            >
                                <Box
                                    sx={{
                                        padding: '10px 0',
                                        width: '100%',
                                        borderBottom: '1px solid #e0e0e0',
                                        display: 'flex',
                                        justifyContent: 'flex-end',
                                        alignItems: 'center',
                                        gap: '10px',
                                        fontWeight: 500,
                                    }}
                                >
                                    <Box>차액</Box>
                                    <Box
                                        className="plus"
                                        sx={{
                                            fontSize: '18px',
                                            marginRight: '16px',
                                        }}
                                    >
                                        + {moneyWithCommas(5444000)}
                                    </Box>
                                </Box>
                                <Box
                                    sx={{
                                        padding: '13px 16px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        gap: '3px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            gap: '10px',
                                        }}
                                    >
                                        <Box>장례식</Box>
                                        <Box
                                            className="plus"
                                            sx={{ fontWeight: 500 }}
                                        >
                                            + {moneyWithCommas(6144000)}
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            alignItems: 'center',
                                            gap: '10px',
                                        }}
                                    >
                                        <Box>발인</Box>
                                        <Box
                                            className="minus"
                                            sx={{ fontWeight: 500 }}
                                        >
                                            - {moneyWithCommas(500000)}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </TableCell>
                    </TableRow>
                ),
            },
        ],
        [
            changeEstimate,
            commonTableLabelColumnStyle,
            commonTableRowStyle,
            estimate,
        ]
    );

    return costColumnContents;
}

export default useCostColumnContents;
