import MuiPagination from '@mui/material/Pagination';
import MuiPaginationItem from '@mui/material/PaginationItem';

function Pagination({ page, handleChange, count, sx, ...rest }) {
    return (
        <MuiPagination
            size="small"
            sx={{
                display: 'flex',
                justifyContent: 'center',
                ...sx,
            }}
            count={count}
            page={page + 1}
            renderItem={(item) => {
                const { type, page } = item;

                if (!(type === 'next' || type === 'previous')) {
                    item.onClick = () => handleChange(page - 1);
                } else {
                    // 이전버튼 혹은 다음버튼 누를시 10페이지 만큼 이동
                    if (type === 'previous') {
                        const prevPage = page - 10 > 0 ? page - 10 : 1;
                        item.page = prevPage;
                        item.onClick = () => handleChange(prevPage - 1);
                        item.sx = { marginRight: '6px' };
                    }
                    if (type === 'next') {
                        const prevPage = page + 10 > count ? count : page + 10;
                        item.page = prevPage;
                        item.onClick = () => handleChange(prevPage - 1);
                        item.sx = { marginLeft: '6px' };
                    }
                    item.shape = 'rounded';
                    item.variant = 'outlined';
                }

                return <MuiPaginationItem {...item} />;
            }}
            {...rest}
        />
    );
}

export default Pagination;
