import Title from '../../../Components/Title';
import WreathImage from './../../../resources/img/ic-wreath.svg';
import OriginImage from './../../../resources/img/wreth-origin.svg';
import { moneyWithCommas } from '../../../common/util';
import styled from 'styled-components';
import ImageZoom from '../../../Components/ImageZoom';
import PageHeader from '../../../Components/PageHeader';

const Wrapper = styled.div`
    h5 {
        font-weight: 500;
        font-size: 24px;
        line-height: 35px;
        color: #191919;
    }
    h4 {
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: #303742;
        margin-bottom: 10px;
    }

    .m-b-5 {
        margin-bottom: 5px;
    }

    .container {
        margin-bottom: 45px;
        .wreath-img {
            width: 300px;
            height: 300px;
            border: 1px solid #e0e0e0;
        }
        .product-content {
            width: 100%;
            border-top: 1px solid #e0e0e0;
            border-bottom: 1px solid #e0e0e0;
            margin-left: 60px;
            padding: 55px 0;
            .price {
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                color: #333333;
                margin: 10px 0 55px 0;
            }
        }
        .img-box {
            width: 400px;
            position: relative;
            .open-btn {
                position: absolute;
                right: 10px;
                bottom: 10px;
                padding: 13px;
                background: rgba(0, 0, 0, 0.1);
            }
        }
    }
`;

function FlowerDetail() {
    const delivery = 0;

    return (
        <Wrapper>
            <PageHeader
                title="근조화환 상품관리 상세"
                breadcrumbItems={[
                    { name: '근조화환 상품관리' },
                    { name: '근조화환 상품관리 상세' },
                ]}
            />
            <div className="container flex">
                <img className="wreath-img" src={WreathImage} alt="wreath" />
                <div className="product-content">
                    <h5>근조 3단 화환</h5>
                    <div className="price">{moneyWithCommas(49000)}</div>
                    {delivery ? (
                        <div className="f-w-500 m-b-5">
                            배송비{moneyWithCommas(delivery)}
                        </div>
                    ) : (
                        <>
                            <div className="f-w-500 m-b-5">무료배송</div>
                            <li>오전 9시 ~오후 8시까지 3시간 이내 도착</li>
                            <li>
                                배송지역 엽체, 꽃 수급 상황, 계절에 따라
                                원산지가 달라질 수 있습니다.
                            </li>
                        </>
                    )}
                </div>
            </div>

            <div className="container">
                <h4>원산지</h4>
                <ImageZoom src={OriginImage} alt="원산지" />
                {/* <div className="img-box">
                    <img src={OrignImage} alt="원산지" />
                    <button className="open-btn">
                        <img src={OpenImage} alt="열기" />
                    </button>
                </div> */}
            </div>

            <div className="container">
                <h4>배송안내</h4>
                <li>
                    배송비는 무료입니다. 주문 후 3시간 이내 배송이 완료되며,
                    오전 10시부터 오후 8시 이후 늦게까지도 배송 가능합니다.
                </li>
            </div>

            <div className="container">
                <h4>교환/환불 안내</h4>
                <li>
                    재료가 다 떨어져 제작이 불가능한 경우, 교통 및 날씨,
                    천재지변등 에 따라 배송이 불가능한 경우는 거의 발생하지
                    않으나 만약 발생 시에는 자동 주문취소 됩니다.
                </li>
                <li>조문 방명록 내용 없이 근조화환만 보내실 수 있습니다.</li>
                <li>
                    화환이 불량이라 받으신 분이 교환을 요청하시면 문제가 없는
                    화환 으로 교환해 드립니다.
                </li>
            </div>
        </Wrapper>
    );
}
export default FlowerDetail;
