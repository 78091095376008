import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
} from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Editor from '../../../Components/Editor';
import ColumnTable from '../../../Components/Table/Colunm';

function QuestionForm({ question, isEdit = false, onSubmit = () => {} }) {
    const navigate = useNavigate();
    const { control, watch, handleSubmit, reset } = useForm();

    const { title, content, target } = watch();
    const { questionId } = useParams();

    const isFilledInput = (() => {
        const isCheckTarget =
            target &&
            [...Object.keys(target)].filter((key) => target[key]).length > 0;

        return title !== '' && content !== '' && isCheckTarget;
    })();

    const columnContents = [
        {
            title: '제목',
            content: (
                <Controller
                    control={control}
                    name="title"
                    rules={{ required: '제목을 입력해주세요.' }}
                    defaultValue={question ? question.title : ''}
                    render={({ field }) => (
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            {...field}
                        />
                    )}
                />
            ),
        },
        {
            title: '내용',
            content: (
                <Controller
                    control={control}
                    name="content"
                    rules={{ required: '내용을 입력해주세요.' }}
                    defaultValue={question ? question.content : ''}
                    render={({ field: { value, onChange } }) => (
                        <Editor value={value} onChange={onChange} />
                    )}
                />
            ),
        },
        {
            title: '대상',
            content: (
                <>
                    <Controller
                        control={control}
                        name="target.family"
                        defaultValue={Boolean(
                            question && question.target.family
                        )}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                    />
                                }
                                label="유족"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="target.director"
                        defaultValue={Boolean(
                            question && question.target.director
                        )}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                    />
                                }
                                label="장례지도사"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="target.funeralHall"
                        defaultValue={Boolean(
                            question && question.target.funeralHall
                        )}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                    />
                                }
                                label="장례식장"
                            />
                        )}
                    />
                    <Controller
                        control={control}
                        name="target.cemetery"
                        defaultValue={Boolean(
                            question && question.target.cemetery
                        )}
                        render={({ field }) => (
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        {...field}
                                        checked={field.value}
                                    />
                                }
                                label="장지"
                            />
                        )}
                    />
                </>
            ),
        },
    ].map((ele) => ({
        ...ele,
        contentSx: {
            padding: '6px',
        },
    }));

    useEffect(() => {
        // Edit mode일때 question값 input value에 채우기
        if (isEdit && question) {
            reset(question);
        }
    }, []);

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ColumnTable columnContents={columnContents} />
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                    mt: '10px',
                    gap: '15px',
                }}
            >
                <Button
                    variant="outlined"
                    color="primary3"
                    onClick={() => {
                        if (isEdit) {
                            return navigate(`/service/questions/${questionId}`);
                        }
                        navigate('/service/questions');
                    }}
                >
                    취소
                </Button>
                <Button
                    variant="contained"
                    disabled={!isFilledInput}
                    type="submit"
                >
                    {isEdit ? '저장' : '추가'}
                </Button>
            </Box>
        </form>
    );
}

export default QuestionForm;
