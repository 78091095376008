import { useMemo } from 'react';
import { TableRow, TableCell } from '@mui/material';

function useFuneralColumnContents() {
    const commonTableRowStyle = useMemo(
        () => ({
            '&:last-child td, &:last-child th': {
                borderBottom: 0,
            },
        }),
        []
    );
    const commonTableLabelColumnStyle = useMemo(
        () => ({
            width: '145px',
            background: 'rgba(48, 55, 66, 0.07)',
            borderRight: '1px solid #E0E0E0',
            fontWeight: 500,
            color: '#191919',
        }),
        []
    );

    const funeralColumnContents = useMemo(
        () => [
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                        }}
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            고인
                        </TableCell>
                        <TableCell>배회호</TableCell>
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            요청자
                        </TableCell>
                        <TableCell>배수진 (010-9654-7861)</TableCell>
                    </TableRow>
                ),
            },
            {
                children: (
                    <TableRow
                        sx={{
                            ...commonTableRowStyle,
                        }}
                    >
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            장례기간
                        </TableCell>
                        <TableCell>2021.11.12 ~ 2021.11.14 (3일)</TableCell>
                        <TableCell
                            sx={{
                                ...commonTableLabelColumnStyle,
                            }}
                        >
                            발인
                        </TableCell>
                        <TableCell>2021년 11월 14일 오전 05:00</TableCell>
                    </TableRow>
                ),
            },
        ],
        [commonTableLabelColumnStyle, commonTableRowStyle]
    );
    return funeralColumnContents;
}

export default useFuneralColumnContents;
