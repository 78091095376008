import styled from '@emotion/styled';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';

const StyleTableCell = styled(TableCell)({
    width: '145px',
    background: 'rgba(48, 55, 66, 0.07)',
    borderRight: '1px solid #E0E0E0',
    borderBottom: '1px solid #E0E0E0',
    fontWeight: 500,
});

export default function DatePickerTable({
    control,
    search,
    title,
    title2,
    title3,
    select,
}) {
    return (
        <>
            <TableContainer>
                <Table
                    sx={{
                        borderWidth: '1px 0px',
                        borderStyle: 'solid',
                        borderColor: '#E0E0E0',
                        borderRight: '1px solid #F2F2F2',
                    }}
                >
                    <TableBody>
                        <TableRow>
                            <StyleTableCell>{title}</StyleTableCell>
                            <TableCell sx={{ padding: '7px 6px' }} colSpan="3">
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                >
                                    <Box sx={{ display: 'flex' }}>
                                        <Controller
                                            control={control}
                                            name="startDate"
                                            defaultValue={(() => {
                                                const date = new Date();
                                                date.setMonth(
                                                    date.getMonth() - 1
                                                );
                                                return date;
                                            })()}
                                            render={({ field }) => (
                                                <DatePicker
                                                    placeholder="override does nothing"
                                                    inputFormat="yyyyMMdd"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            sx={{
                                                                width: '150px',
                                                            }}
                                                        />
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />

                                        <Box
                                            sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                mx: '15px',
                                            }}
                                        >
                                            ~
                                        </Box>
                                        <Controller
                                            control={control}
                                            name="endDate"
                                            defaultValue={new Date()}
                                            render={({ field }) => (
                                                <DatePicker
                                                    placeholder="override does nothing"
                                                    inputFormat="yyyyMMdd"
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            sx={{
                                                                width: '150px',
                                                            }}
                                                        />
                                                    )}
                                                    {...field}
                                                />
                                            )}
                                        />
                                    </Box>
                                </LocalizationProvider>
                            </TableCell>
                        </TableRow>
                        {title2 && (
                            <TableRow>
                                <StyleTableCell>{title2}</StyleTableCell>
                                <TableCell
                                    sx={{
                                        padding: '6px',
                                    }}
                                >
                                    <Controller
                                        name="field1"
                                        defaultValue=""
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                {...field}
                                            />
                                        )}
                                    />
                                </TableCell>
                                {title3 && (
                                    <>
                                        <StyleTableCell>
                                            {title3}
                                        </StyleTableCell>
                                        <TableCell
                                            sx={{
                                                padding: '6px',
                                            }}
                                        >
                                            <Controller
                                                name="field2"
                                                defaultValue=""
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        size="small"
                                                        {...field}
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </>
                                )}
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button
                variant="contained"
                size="large"
                sx={{
                    display: 'flex',
                    margin: select
                        ? '20px auto 30px auto'
                        : '20px auto 75px auto',
                }}
                onClick={search}
            >
                검색
            </Button>
        </>
    );
}
