import {
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TableRow,
    TableCell,
} from '@mui/material';
import { Box, styled } from '@mui/system';

import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import DatePickerTable from '../../Components/Table/DatePickerTable';
import Title from '../../Components/Title';
import { useForm } from 'react-hook-form';
import Table from '../../Components/Table';
import { useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import Pagination from '../../Components/Table/Pagination';
import { moneyWithCommas } from '../../common/util';

function ManageFuneral() {
    const { control } = useForm();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();

    const manageFuneral = [
        {
            funeralPeriod: '2021.11.12~2021.11.14 (3일)',
            funeral: '',
            tomb: '',
            crematorium: <RadioButtonUncheckedIcon fontSize="small" />,
            vehicle: <RadioButtonUncheckedIcon fontSize="small" />,
            deceased: '홍경수',
            price: 950000,
            price2: -40000,
        },
        {
            funeralPeriod: '2021.11.12~2021.11.14 (3일)',
            funeral: <RadioButtonUncheckedIcon fontSize="small" />,
            tomb: <RadioButtonUncheckedIcon fontSize="small" />,
            crematorium: <RadioButtonUncheckedIcon fontSize="small" />,
            vehicle: <RadioButtonUncheckedIcon fontSize="small" />,
            deceased: '홍경수',
            price: 840000,
            price2: 4300000,
        },
        {
            funeralPeriod: '2021.11.12~2021.11.14 (3일)',
            funeral: <RadioButtonUncheckedIcon fontSize="small" />,
            tomb: <RadioButtonUncheckedIcon fontSize="small" />,
            crematorium: <RadioButtonUncheckedIcon fontSize="small" />,
            vehicle: <RadioButtonUncheckedIcon fontSize="small" />,
            deceased: '홍경수',
            price: 11240000,
            price2: 0,
        },
    ];

    const manageFuneralTableCellProps = [
        { width: '60px', align: 'center' },
        {},
        { width: '70px', align: 'center', sx: { padding: 0 } },
        { width: '70px', align: 'center', sx: { padding: 0 } },
        { width: '70px', align: 'center', sx: { padding: 0 } },
        { width: '70px', align: 'center', sx: { padding: 0 } },
        { width: '100px', align: 'center' },
        { width: '140px', align: 'right' },
        { width: '140px', align: 'right' },
    ];

    return (
        <>
            <Title header={{ title: '장례 관리' }} />
            <DatePickerTable title={'기간'} title2={'고인'} control={control} />
            <Box
                sx={{
                    minWidth: 120,
                    height: '50px',

                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <FormControl sx={{ m: '8px', minWidth: 120 }} size="small">
                    <InputLabel htmlFor="grouped-select">지역</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1} sx={{ textAlign: 'left' }}>
                            전국
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">예약 상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                        <MenuItem value={2}>요청</MenuItem>
                        <MenuItem value={3}>예약됨</MenuItem>
                        <MenuItem value={4}>불발</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    { content: '장례기간' },
                    { content: '장례식' },
                    { content: '장지' },
                    { content: '화장장' },
                    { content: '차량' },
                    { content: '고인명' },
                    { content: ' 견적금액' },
                    { content: '견적금액' },
                ]}
                tableCellProps={manageFuneralTableCellProps}
                items={manageFuneral.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/funeral/${idx}`)}
                    >
                        {[
                            { content: page * itemsPerPage + (idx + 1) },
                            { content: ele.funeralPeriod },
                            { content: ele.funeral },
                            { content: ele.tomb },
                            { content: ele.crematorium },
                            { content: ele.vehicle },
                            { content: ele.deceased },
                            { content: moneyWithCommas(ele.price) },
                            {
                                content: (
                                    <Box
                                        sx={{
                                            '&.plus': {
                                                color: '#ff5160',
                                            },
                                            '&.minus': {
                                                color: '#485BA1',
                                            },
                                        }}
                                        className={`price ${
                                            ele.price2 > 0
                                                ? 'plus'
                                                : ele.price2 < 0
                                                ? 'minus'
                                                : ''
                                        }`}
                                    >
                                        {ele.price2 > 0
                                            ? '+'
                                            : ele.price2 < 0
                                            ? '-'
                                            : ''}{' '}
                                        {moneyWithCommas(Math.abs(ele.price2))}
                                    </Box>
                                ),
                            },
                        ].map(({ content }, idx) => (
                            <TableCell
                                key={idx}
                                {...manageFuneralTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ marginTop: '25px' }}
            />
        </>
    );
}

export default ManageFuneral;
