import React from 'react';
import {
    TableContainer,
    Table as MuiTable,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from '@mui/material';

function Table({
    // 테이블 첫번째 column에 번호가 붙여지는지 여부
    numeric = false,
    page = 0,
    itemsPerPage = 10,
    // 테이블 row를 역방향으로 render할껀지 여부
    reverse = false,
    columns,
    rows,
    // 공통 props를 주어 columnProps와 rowProps에 동일한 값을 줄 수 있음
    tableRowProps = {},
    columnProps = {},
    rowProps = {},
    // 공통 props를 주어 columnCellProps와 rowCellProps에 동일한 값을 줄 수 있음
    tableCellProps = [],
    columnCellProps = [],
    rowCellProps = [],
    // tableRow를 직접 정의
    items,
}) {
    const tableBody =
        (items && [...items]) ||
        // rows에 적용되는 option
        // numeric, page, itemsPerPage, tableRowProps, rowProps, tableCellProps,rowCellProps
        rows.map((row, idx) => (
            <TableRow key={idx} {...tableRowProps} {...rowProps}>
                {(numeric
                    ? // 맨앞에 번호값을 추가하고 id값은 제외시킴
                      [
                          page * itemsPerPage + (idx + 1),
                          ...[...Object.keys(row)]
                              .filter((key) => key !== 'id')
                              .map((key) => row[key]),
                      ]
                    : [...Object.keys(row)].map((key) => row[key])
                ).map((content, idx) => (
                    <TableCell
                        key={idx}
                        {...tableCellProps[idx]}
                        {...rowCellProps[idx]}
                        sx={{
                            maxWidth: 'fit-content',
                            ...(tableCellProps[idx] && tableCellProps[idx].sx),
                            ...(rowCellProps[idx] && rowCellProps[idx].sx),
                        }}
                    >
                        {content}
                    </TableCell>
                ))}
            </TableRow>
        ));

    return (
        <TableContainer>
            <MuiTable
                sx={{ border: '1px solid #E0E0E0', position: 'relative' }}
                padding="normal"
                size="medium"
            >
                <TableHead>
                    <TableRow
                        {...tableRowProps}
                        {...columnProps}
                        sx={{
                            background: 'rgba(48, 55, 66, 0.07)',
                            borderBottom: '1px solid #E0E0E0',
                            ...tableRowProps.sx,
                            ...columnProps.sx,
                        }}
                    >
                        {(numeric
                            ? // 번호 column추가
                              [{ content: '번호' }, ...columns]
                            : columns
                        ).map((column, idx) => (
                            <TableCell
                                key={idx}
                                variant="head"
                                size="small"
                                padding="normal"
                                align="left"
                                // numeric table 번호 column width고정
                                width={numeric && idx === 0 ? '60px' : 'auto'}
                                {...tableCellProps[idx]}
                                {...columnCellProps[idx]}
                            >
                                {column.content}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        // reverse가 true면 역방향으로 render
                        reverse ? tableBody.reverse() : tableBody
                    }
                </TableBody>
            </MuiTable>
        </TableContainer>
    );
}

export default Table;
