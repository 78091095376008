import React, { useCallback, useMemo, useState } from 'react';
import {
    Button,
    Checkbox,
    FormControlLabel,
    Switch,
    TextField,
} from '@mui/material';
import { Box } from '@mui/material';
import ColumnTable from '../../Components/Table/Colunm';
import { useNavigate, useResolvedPath } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import ErrorMessage from '../../Components/Form/ErrorMessage';
import PageHeader from './../../Components/PageHeader';

function AddAdmin() {
    const checkbox = [
        {
            id: 1,
            label: '회원관리',
        },
        {
            id: 2,
            label: '협력사 관리',
        },
        {
            id: 3,
            label: '견적 관리',
        },
        {
            id: 4,
            label: '예약 관리',
        },
        {
            id: 5,
            label: '장례 관리',
        },
        {
            id: 6,
            label: '주문/배송 관리',
        },
        {
            id: 7,
            label: '상품 관리',
        },
        {
            id: 8,
            label: '장지 서비스',
        },
        {
            id: 9,
            label: '재무 관리',
        },
        {
            id: 10,
            label: '데이터 관리',
        },
        {
            id: 11,
            label: '컨텐츠 관리',
        },
        {
            id: 12,
            label: '온라인 관리',
        },
        {
            id: 13,
            label: '고객센터',
        },
        {
            id: 14,
            label: '메일링',
        },
        {
            id: 15,
            label: '메시지',
        },
        {
            id: 16,
            label: '통계',
        },
        {
            id: 17,
            label: 'Google Analytics',
        },
    ];

    const navigate = useNavigate();
    const {
        handleSubmit,
        control,
        watch,
        formState: { errors },
    } = useForm();

    const onSubmit = (data) => {
        console.log(data);
    };

    console.log(watch(), errors);

    const [checkedStudents, setCheckedStudents] = useState([]);

    const handleChange1 = (isChecked) => {
        if (isChecked) {
            setCheckedStudents(checkbox.map((checkbox) => checkbox.id));
        } else {
            setCheckedStudents([]);
        }
    };

    const handleChange2 = (isChecked, id) => {
        const index = checkedStudents.indexOf(id);

        if (isChecked) {
            setCheckedStudents((state) => [...state, id]);
        }

        if (!isChecked && index > -1) {
            return setCheckedStudents((state) => {
                state.splice(index, 1);
                return JSON.parse(JSON.stringify(state));
            });
        }
    };

    const columnContents = [
        {
            title: '아이디',
            padding: '6px',
            content: (
                <Controller
                    control={control}
                    name="id"
                    rules={{ required: '아이디를 입력하세요.' }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '이름',
            padding: '6px',
            content: (
                <Controller
                    control={control}
                    name="name"
                    rules={{ required: '이름을 입력하세요.' }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '비밀번호',
            padding: '6px',
            content: (
                <Controller
                    control={control}
                    name="password"
                    rules={{ required: '비밀번호를 입력하세요.' }}
                    render={({ field, fieldState: { error } }) => (
                        <>
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                type="password"
                                {...field}
                            />
                            <ErrorMessage error={error} />
                        </>
                    )}
                />
            ),
        },
        {
            title: '권한',
            padding: '6px',
            content: (
                <>
                    <FormControlLabel
                        label={'전체'}
                        sx={{
                            margin: '0 10px 0 0',
                            '& .MuiFormControlLabel-label': {
                                fontWeight: 700,
                            },
                        }}
                        control={
                            <Checkbox
                                checked={
                                    checkedStudents.length === checkbox.length
                                }
                                onChange={(event) =>
                                    handleChange1(event.target.checked)
                                }
                                size="small"
                            />
                        }
                    />
                    <br />
                    {checkbox.map((ele, idx) => (
                        <FormControlLabel
                            key={idx}
                            label={ele.label}
                            sx={{
                                margin: '0 10px 0 0',
                            }}
                            control={
                                <Checkbox
                                    size="small"
                                    checked={checkedStudents.includes(ele.id)}
                                    onChange={(event) =>
                                        handleChange2(
                                            event.target.checked,
                                            ele.id
                                        )
                                    }
                                />
                            }
                        />
                    ))}
                </>
            ),
        },
        {
            title: '로그인 제한',
            content: (
                <Box sx={{ color: '#888888' }}>
                    <Controller
                        control={control}
                        name="isLoginable"
                        render={({ field }) => (
                            <Switch color="error" {...field} />
                        )}
                    />
                    로그인을 제한할 경우 로그인이 되지 않습니다.
                </Box>
            ),
            padding: '7px 4px',
        },
    ];

    return (
        <>
            <PageHeader
                title="관리자 추가"
                breadcrumbItems={[
                    { name: '관리자 계정관리' },
                    { name: '관리자 추가' },
                ]}
            />
            <form onSubmit={handleSubmit(onSubmit)}>
                <ColumnTable
                    columnContents={columnContents}
                    control={control}
                />
                <Box sx={{ textAlign: 'right', mt: '10px' }}>
                    <Button
                        variant="outlined"
                        size="large"
                        color="secondary"
                        sx={{ mr: '15px' }}
                        onClick={() => navigate('/account')}
                    >
                        취소
                    </Button>
                    <Button variant="contained" size="large" type="submit">
                        추가
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default AddAdmin;
