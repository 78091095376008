import {
    Button,
    FormControl,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Title from '../../../Components/Title';
import parse from 'html-react-parser';

export const TERM_TYPES = {
    USER_SERVICE: 'USER_SERVICE',
    USER_PRIVACY: 'USER_PRIVACY',
    USER_THIRD_PARTY_INFO: 'USER_THIRD_PARTY_INFO',
    USER_LOCATION: 'USER_LOCATION',
    PARTNER: 'PARTNER',
    PARTNER_PRIVACY: 'PARTNER_PRIVACY',
};

function Terms() {
    const { control, watch } = useForm();
    const navigate = useNavigate();

    const terms = {
        [TERM_TYPES.USER_SERVICE]: {
            type: TERM_TYPES.USER_SERVICE,
            title: '사용자 서비스 이용약관',
            content:
                '<h1><strong>사용자 서비스 이용약관 자주하는 질문 내용</strong></h1>',
            target: {
                family: true,
                director: true,
                funeralHall: false,
                cemetery: true,
            },
        },
        [TERM_TYPES.USER_PRIVACY]: {
            type: TERM_TYPES.USER_PRIVACY,
            title: '사용자 개인정보 취급방침',
            content:
                '<h1><strong>사용자 개인정보 취급방침 자주하는 질문 내용</strong></h1>',
            target: {
                family: true,
                director: true,
                funeralHall: false,
                cemetery: true,
            },
        },
        [TERM_TYPES.USER_THIRD_PARTY_INFO]: {
            type: TERM_TYPES.USER_THIRD_PARTY_INFO,
            title: '사용자 제3자 정보 제공동의',
            content:
                '<h1><strong>사용자 제3자 정보 제공동의 자주하는 질문 내용</strong></h1>',
            target: {
                family: true,
                director: true,
                funeralHall: false,
                cemetery: true,
            },
        },
        [TERM_TYPES.USER_LOCATION]: {
            type: TERM_TYPES.USER_LOCATION,
            title: '사용자 위치정보 이용약관',
            content:
                '<h1><strong>사용자 위치정보 이용약관 자주하는 질문 내용</strong></h1>',
            target: {
                family: true,
                director: true,
                funeralHall: false,
                cemetery: true,
            },
        },
        [TERM_TYPES.PARTNER]: {
            type: TERM_TYPES.PARTNER,
            title: '협력사 이용약관',
            content:
                '<h1><strong>협력사 이용약관 자주하는 질문 내용</strong></h1>',
            target: {
                family: true,
                director: true,
                funeralHall: false,
                cemetery: true,
            },
        },
        [TERM_TYPES.PARTNER_PRIVACY]: {
            type: TERM_TYPES.PARTNER_PRIVACY,
            title: '협력사 개인정보 취급방침',
            content:
                '<h1><strong>협력사 개인정보 취급방침 자주하는 질문 내용</strong></h1>',
            target: {
                family: true,
                director: true,
                funeralHall: false,
                cemetery: true,
            },
        },
    };

    const selectTermType = watch('selectTermType', TERM_TYPES.USER_SERVICE);
    const term = terms[selectTermType];

    return (
        <>
            <Title header={{ title: '약관' }} />
            <Controller
                control={control}
                name="selectTermType"
                defaultValue={TERM_TYPES.USER_SERVICE}
                render={({ field }) => (
                    <FormControl
                        sx={{ mb: '10px', minWidth: 270 }}
                        size="small"
                    >
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            sx={{ minHeight: 40 }}
                            {...field}
                        >
                            <MenuItem value={TERM_TYPES.USER_SERVICE}>
                                사용자 서비스 이용약관
                            </MenuItem>
                            <MenuItem value={TERM_TYPES.USER_PRIVACY}>
                                사용자 개인정보 취급방침
                            </MenuItem>
                            <MenuItem value={TERM_TYPES.USER_THIRD_PARTY_INFO}>
                                사용자 제3자 정보 제공동의
                            </MenuItem>
                            <MenuItem value={TERM_TYPES.USER_LOCATION}>
                                사용자 위치정보 이용약관
                            </MenuItem>
                            <MenuItem value={TERM_TYPES.PARTNER}>
                                협력사 이용약관
                            </MenuItem>
                            <MenuItem value={TERM_TYPES.PARTNER_PRIVACY}>
                                협력사 개인정보 취급방침
                            </MenuItem>
                        </Select>
                    </FormControl>
                )}
            />
            <Box
                sx={{
                    background: '#FFFFFF',
                    borderWidth: '1px 0px',
                    borderStyle: 'solid',
                    borderColor: '#C4C4C4',

                    padding: '20px 20px 54px 20px',
                }}
            >
                <Typography
                    mb={'20px'}
                    sx={{
                        fontWeight: '500',
                        fontSize: '18px',
                        lineHeight: '26px',
                    }}
                >
                    {term.title}
                </Typography>
                {parse(term.content)}
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mt: '10px',
                }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        navigate('edit');
                    }}
                >
                    수정
                </Button>
            </Box>
        </>
    );
}

export default Terms;
