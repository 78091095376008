import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import DatePickerTable from '../../../Components/Table/DatePickerTable';
import Title from '../../../Components/Title';
import Pagination from '../../../Components/Table/Pagination';
import Table from '../../../Components/Table/index';
import { useNavigate } from 'react-router-dom';
import { moneyWithCommas } from '../../../common/util';

function ManageWithdraw() {
    const { control } = useForm();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const withdraws = [
        {
            id: 0,
            requester: '홍길동',
            price: 1000000,
            bankAccount: '기업은행 01023456789',
            requestDate: '2021.11.12 15:20',
            sendStatusCd: '1',
        },
        {
            id: 0,
            requester: '홍길동',
            price: 1000000,
            bankAccount: '기업은행 01023456789',
            requestDate: '2021.11.12 15:20',
            sendStatusCd: '0',
            sendDate: '2021.11.12 09:25',
        },
    ];
    const withdrawTableCellProps = [
        { align: 'center' },
        { align: 'center' },
        { align: 'right' },
        {},
        {},
        {},
    ];

    return (
        <>
            <Title header={{ title: '출금 관리' }} />
            <DatePickerTable title={'납부일'} control={control} select />
            <Box
                sx={{
                    minWidth: 120,
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">지급상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                        <MenuItem value={2}>지급전</MenuItem>
                        <MenuItem value={3}>지급완료</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    { content: '요청자' },
                    { content: '요청금액' },
                    { content: '수령계좌' },
                    { content: '요청일' },
                    { content: '지급상태' },
                ]}
                tableCellProps={withdrawTableCellProps}
                items={withdraws.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            navigate(`${idx}`);
                        }}
                    >
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },
                            {
                                content: ele.requester,
                            },
                            { content: moneyWithCommas(ele.price) },
                            {
                                content: ele.bankAccount,
                            },
                            { content: ele.requestDate },
                            {
                                content:
                                    ele.sendStatusCd === '0' ? (
                                        `지급완료 (${ele.sendDate})`
                                    ) : ele.sendStatusCd === '1' ? (
                                        <Box color="primary5.main">지급전</Box>
                                    ) : (
                                        ''
                                    ),
                            },
                        ].map(({ content }, idx, columns) => (
                            <TableCell
                                key={idx}
                                {...withdrawTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
        </>
    );
}

export default ManageWithdraw;
