import Title from '../../Components/Title';
import Table from './../../Components/Table/index';
import styled from 'styled-components';
import Pagination from '../../Components/Table/Pagination';
import { useState } from 'react';
import { Button, TableRow, TableCell } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';

const Wrapper = styled.div`
    .section {
        &:not(&:first-child) {
            margin-top: 45px;
        }
        & > h3 {
            margin-bottom: 10px;
            font-weight: 500;
            font-size: 16px;
            line-height: 23px;
            color: #303742;
            span {
                font-weight: 400;
                font-size: 12px;
                line-height: 17px;
                color: #666666;
            }
        }
    }
`;

function Message() {
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();

    const notices = [
        {
            id: 0,
            sort: '근조화환 주문',
            content: '[명당] 202111121342001 새로운 근조화환 주문이 있습니다.',
        },
        {
            id: 1,
            sort: '근조화환 주문',
            content: '[명당] 202111121342001 새로운 근조화환 주문이 있습니다.',
        },
        {
            id: 2,
            sort: '근조화환 주문',
            content: '[명당] 202111121342001 새로운 근조화환 주문이 있습니다.',
        },
    ];

    const emails = [
        {
            id: 0,
            title: '이용약관 개정 사전 안내',
            sendCnt: 2,
        },
        {
            id: 0,
            title: '이용약관 개정 사전 안내',
            sendCnt: 2,
        },
    ];

    return (
        <Wrapper>
            <Title header={{ title: '메세지' }} />
            <div className="section">
                <h3>자동 문자 메세지</h3>
                <Table
                    numeric
                    reverse
                    columns={[
                        {
                            content: '종류',
                        },
                        {
                            content: '내용',
                        },
                    ]}
                    rows={notices}
                    tableCellProps={[
                        { align: 'center', sx: { width: '60px' } },
                        {},
                        {},
                    ]}
                />
            </div>
            <div className="section">
                <h3>
                    수동 이메일 <span>이메일은 협력사에게만 발송됩니다.</span>
                </h3>
                <Table
                    numeric
                    reverse
                    page={page}
                    itemPerPage={1}
                    columns={[
                        {
                            content: '제목 ',
                        },
                        {
                            content: '발송 ',
                        },
                    ]}
                    tableCellProps={[
                        { align: 'center', sx: { width: '60px' } },
                        {},
                        { align: 'center', sx: { width: '120px' } },
                    ]}
                    items={emails.map((ele, idx) => (
                        <TableRow
                            key={idx}
                            onClick={() => navigate(`/message/${ele.id}`)}
                            sx={{ cursor: 'pointer' }}
                        >
                            <TableCell align="center" sx={{ width: '60px' }}>
                                {page * itemsPerPage + (idx + 1)}
                            </TableCell>
                            <TableCell>{ele.title}</TableCell>
                            <TableCell align="center" sx={{ width: '120px' }}>
                                {ele.sendCnt}회
                            </TableCell>
                        </TableRow>
                    ))}
                />
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        marginTop: '25px',
                    }}
                >
                    <div style={{ flex: '1' }} />
                    <Pagination count={10} page={page} handleChange={setPage} />
                    <div
                        style={{
                            flex: '1',
                            display: 'flex',
                            justifyContent: 'flex-end',
                        }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            color="primary2"
                            component={Link}
                            to="/message/add"
                        >
                            메시지 작성
                        </Button>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default Message;
