import { Box, TableCell, TableRow, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { moneyWithCommas } from '../../common/util';
import Table from '../../Components/Table';
import ColumnTable from '../../Components/Table/Colunm';
import Title from './../../Components/Title';
import WreathImage from '../../resources/img/ic-wreath.svg';
import PageHeader from './../../Components/PageHeader';

const StyledTableCell = {
    width: '145px',
    background: 'rgba(48, 55, 66, 0.07)',
    borderRight: '1px solid #E0E0E0',
    fontWeight: 500,
    verticalAlign: 'baseline',
    color: '#191919',
};

function OrderFlowerDetail() {
    const flowers = [
        {
            id: 0,
            orderNum: 202111120001244,
            orderDate: '2021.11.12 21:27',
            receiptDate: '2021.11.12 21:41',
            shippingStartDate: '2021.11.12 22:25',
            shippingCompletedDate: '2021.11.12 22:49',
            product: '근조 3단 화환 49,000원',
            productImg: WreathImage,
            payment: '신용카드 3645************',
            sendName: '이미소',
            phone: '010-6789-0123',
            ribbonMessage: '삼가 고인의 명복을 빕니다.',
            ribbonMessageName: '제자 이미소',
            funeralAddress: '동작구 동작경희병원 장례식장 5호실',
            funeralNum: '02-822-811',
            deceased: '故 홍경수',
        },
    ];
    const flower = flowers[0];
    const columnContents = [
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell sx={StyledTableCell}>주문번호</TableCell>
                    <TableCell>{flower.orderNum}</TableCell>
                    <TableCell sx={StyledTableCell}>주문일</TableCell>
                    <TableCell>{flower.orderDate}</TableCell>
                </TableRow>
            ),
        },
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell sx={StyledTableCell}>주문상품</TableCell>
                    <TableCell>{flower.product}</TableCell>
                    <TableCell sx={StyledTableCell}>결제</TableCell>
                    <TableCell>{flower.payment}</TableCell>
                </TableRow>
            ),
        },
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell sx={StyledTableCell}>보내는 분</TableCell>
                    <TableCell sx={{ verticalAlign: 'baseline' }}>
                        {flower.sendName} ({flower.phone})
                    </TableCell>
                    <TableCell sx={StyledTableCell}>리본문구</TableCell>
                    <TableCell padding="none">
                        <Box padding={2} borderBottom="1px solid #F2F2F2">
                            {flower.ribbonMessage}
                        </Box>
                        <Box padding={2}>{flower.ribbonMessageName}</Box>
                    </TableCell>
                </TableRow>
            ),
        },
        {
            title: '보내는 분2',
            content: (
                <>
                    <Box sx={{ fontWeight: 500, mb: 2 }}>{flower.deceased}</Box>
                    <Box>{flower.funeralAddress}</Box>
                    <Box>{flower.funeralNum} </Box>
                </>
            ),
        },
        {
            title: '보내는 분3',
            content: (
                <>
                    <Box sx={{ fontWeight: 500, mb: 2 }}>
                        840억 유현숙 꽃집플라워{flower.funeralNum}
                    </Box>
                    {[
                        { content: flower.orderDate, status: '배송완료' },
                        { content: flower.receiptDate, status: '주문완료' },
                        {
                            content: flower.shippingStartDate,
                            status: '접수완료',
                        },
                        {
                            content: flower.shippingCompletedDate,
                            status: '배송완료',
                        },
                    ].map(({ content, status }, idx) => (
                        <Box key={idx} display="flex">
                            <Box color={'#666666'} mr="20px">
                                {content}
                            </Box>
                            <Box>{status}</Box>
                        </Box>
                    ))}
                </>
            ),
        },
        {
            title: '보내는 분4',
            content: <img src={flower.productImg} width={300} />,
        },
    ];

    return (
        <>
            <PageHeader
                title="근조화환 주문관리 상세"
                breadcrumbItems={[
                    { name: '근조화환 주문관리' },
                    { name: '근조화환 주문관리 상세' },
                ]}
            />
            <Box
                sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#303742',
                    mb: '10px',
                }}
            >
                주문내역
            </Box>
            <ColumnTable columnContents={columnContents} maxColumnCnt={4} />
        </>
    );
}

export default OrderFlowerDetail;
