import {
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TableCell,
    TableRow,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { moneyWithCommas } from '../../common/util';
import Table from '../../Components/Table';
import DatePickerTable from '../../Components/Table/DatePickerTable';
import Pagination from '../../Components/Table/Pagination';
import Title from '../../Components/Title';

const OrderGift = [{}];

function OrderFlower() {
    const { control } = useForm();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();

    const Gift = [
        {
            orderNum: 202111120001244,
            orderDate: '2021.11.12 21:31',
            product: '소백산 사양 아카시아 벌꿀 1호(1.2kg)',
            price: 17900,
            sendName: '한습관',
            sendPhone: '010-2345-6789',
            receiveName: '74명',
            shippingStatus: '배송완료',
            totalShipping: 74,
            completeShipping: 23,
        },
        {
            orderNum: 202111120001244,
            orderDate: '2021.11.12 21:31',
            product: 'LIN 히말라야 핑크소금 200g',
            price: 3500,
            sendName: '한습관',
            sendPhone: '010-2345-6789',
            receiveName: '37명',
            shippingStatus: '주문완료',
        },
    ];

    const GiftTableCellProps = [
        { width: '60px', align: 'center' },
        { width: '160px' },
        {},
        { width: '150px' },
        { width: '120px', align: 'center' },
        { width: '140px', align: 'center' },
    ];

    return (
        <>
            <Title header={{ title: '답례품 주문관리' }} />
            <DatePickerTable title={'기간'} title2={'고인'} control={control} />
            <Box
                sx={{
                    minWidth: 120,
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">배송상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                        <MenuItem value={2}>요청</MenuItem>
                        <MenuItem value={3}>예약됨</MenuItem>
                        <MenuItem value={4}>불발</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    { content: '주문번호/주문일' },
                    { content: '상품' },
                    { content: '보내는 분' },
                    { content: '받는 분' },
                    { content: '배송상태' },
                ]}
                tableCellProps={GiftTableCellProps}
                items={Gift.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/order/gift/${idx}`)}
                    >
                        {[
                            { content: page * itemsPerPage + idx + 1 },
                            {
                                content: (
                                    <>
                                        <Box sx={{ fontWeight: 500 }}>
                                            {ele.orderNum}
                                        </Box>
                                        <Box sx={{ color: '#888888' }}>
                                            {ele.orderDate}
                                        </Box>
                                    </>
                                ),
                            },
                            {
                                content: (
                                    <>
                                        <Box>{ele.product}</Box>
                                        <Box sx={{ color: '#888888' }}>
                                            {moneyWithCommas(ele.price)}
                                        </Box>
                                    </>
                                ),
                            },
                            {
                                content: (
                                    <>
                                        <Box sx={{ fontWeight: 500 }}>
                                            {ele.sendName}
                                        </Box>
                                        <Box sx={{ color: '#888888' }}>
                                            {ele.sendPhone}
                                        </Box>
                                    </>
                                ),
                            },
                            {
                                content: (
                                    <>
                                        <Box sx={{ fontWeight: 500 }}>
                                            {ele.receiveName}
                                        </Box>
                                    </>
                                ),
                            },
                            {
                                content: (
                                    <>
                                        <Box sx={{ fontWeight: 500 }}>
                                            {ele.shippingStatus}
                                        </Box>
                                        {ele.totalShipping && (
                                            <Box>
                                                <Box
                                                    component="span"
                                                    fontWeight={500}
                                                >
                                                    ({ele.completeShipping} /
                                                </Box>
                                                &nbsp;{ele.totalShipping})
                                            </Box>
                                        )}
                                    </>
                                ),
                            },
                        ].map(({ content }, idx) => (
                            <TableCell
                                key={idx}
                                sx={{ verticalAlign: 'top' }}
                                {...GiftTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
        </>
    );
}
export default OrderFlower;
