import { Box } from '@mui/system';
import ModalLayout from './ModalLayout';
import { Button, ButtonGroup } from '@mui/material';

function DialogModal({
    open,
    close,
    handleClickLeftBtn = close,
    leftBtnProps,
    leftBtnChildren = '취소',
    handleClickRightBtn,
    rightBtnProps,
    rightBtnChildren,
    children,
}) {
    return (
        <ModalLayout open={open} close={close}>
            <Box
                sx={{
                    fontWeight: '500',
                    fontSize: '16px',
                    lineHeight: '23px',
                    textAlign: 'center',
                    padding: '0px 40px 30px ',
                }}
            >
                {children}
            </Box>
            <ButtonGroup
                disableElevation
                fullWidth
                size="large"
                sx={{
                    height: '50px',
                    fontSize: '16px',
                    fontWeight: '500',
                    borderTop: '1px solid #E0E0E0',
                    borderRadius: '0px',
                    borderTopLeftRadius: '0',
                    borderTopRightRadius: '0',
                    '& > button': {
                        width: '50%',
                        margin: '0 !important',
                    },
                }}
            >
                <Button
                    variant="text"
                    onClick={handleClickLeftBtn}
                    {...leftBtnProps}
                    sx={{
                        borderRadius: '0',
                        borderBottomLeftRadius: '10px',
                        color:
                            leftBtnProps && leftBtnProps.color === 'error'
                                ? '#ffffff'
                                : '#888888',
                        ...(leftBtnProps && leftBtnProps.sx),
                    }}
                >
                    {leftBtnChildren}
                </Button>
                <Button
                    variant="contained"
                    onClick={handleClickRightBtn}
                    {...rightBtnProps}
                    sx={{
                        borderRadius: '0',
                        borderBottomRightRadius: '10px',
                        color: '#fff fff',
                        ...(rightBtnProps && rightBtnProps.sx),
                    }}
                >
                    {rightBtnChildren}
                </Button>
            </ButtonGroup>
        </ModalLayout>
    );
}

export default DialogModal;
