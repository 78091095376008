import React from 'react';
import Header from '../Components/Header';
import Sidebar from '../Components/Sidebar';
import { Outlet } from 'react-router-dom';
import { Box, Container } from '@mui/material';

function MainLayout() {
    return (
        <div style={{ background: '#FAF9F8' }}>
            <Header />
            <Box
                sx={{
                    maxWidth: '1200px',
                    display: 'flex',
                    minHeight: 'calc(100vh - 55px)',
                    margin: '0 auto',
                }}
            >
                <Sidebar />
                <Container
                    sx={{
                        maxWidth: '1000px',
                        pl: '30px !important',
                        pr: '15px !important',
                        paddingBottom: '300px',
                    }}
                >
                    <Outlet />
                </Container>
            </Box>
        </div>
    );
}

export default MainLayout;
