import { TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import sx from '@mui/system/sx';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../../../Components/Table';
import Pagination from '../../../Components/Table/Pagination';
import Title from '../../../Components/Title';
import WreathImage from './../../../resources/img/ic-wreath.svg';
import { moneyWithCommas } from '../../../common/util';
import { numberWithCommas } from '../../../common/util';
import PageHeader from '../../../Components/PageHeader';

function Flower() {
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();

    const Product = [
        {
            productImg: WreathImage,
            productName: '생화 100% 근조 4단 화환',
            price: 119900,
            order: 36423,
        },
        {
            productImg: WreathImage,
            productName: '근조 3단 화환',
            price: 99000,
            order: 36423,
        },
        {
            productImg: WreathImage,
            productName: '근조 3단 화환',
            price: 89000,
            order: 36423,
        },
        {
            productImg: WreathImage,
            productName: '근조 3단 화환',
            price: 79000,
            order: 36423,
        },
        {
            productImg: WreathImage,
            productName: '근조 3단 화환',
            price: 59000,
            order: 36423,
        },
    ];

    const ProductTableCellProps = [
        { width: '60px', align: 'center' },
        { width: '160px', align: 'center' },
        {},
        { width: '150px', align: 'right' },
        { width: '120px', align: 'center' },
    ];

    return (
        <>
            <PageHeader title="근조화환 상품관리" />
            <Table
                numeric
                reverse
                columns={[
                    { content: '상품사진' },
                    { content: '상품명' },
                    { content: '금액' },
                    { content: '주문' },
                ]}
                tableCellProps={ProductTableCellProps}
                items={Product.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        onClick={() => navigate(`/product/flower/${idx}`)}
                    >
                        {[
                            { content: page * itemsPerPage + (idx + 1) },
                            {
                                content: (
                                    <img src={ele.productImg} alt="product" />
                                ),
                            },
                            {
                                content: (
                                    <>
                                        <Box>{ele.productName}</Box>
                                        <Box color={'#888888'}>
                                            {moneyWithCommas(ele.price)}
                                        </Box>
                                    </>
                                ),
                            },
                            { content: moneyWithCommas(ele.price) },
                            { content: numberWithCommas(ele.order) },
                        ].map(({ content }, idx) => (
                            <TableCell
                                key={idx}
                                {...ProductTableCellProps[idx]}
                                sx={{ verticalAlign: 'top' }}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ marginTop: '25px' }}
            />
        </>
    );
}
export default Flower;
