// import { Box, ListItemButton, ListItemText, Typography } from '@mui/material';
// import { useState } from 'react';
// import { Link } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';

// const NavItem = ({ item, level }) => (
//     <>
//         <Link to={`${item.url}`} style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
//             <NavLink
//                 sx={{
//                     backgroundColor:
//                         level > 1 ? 'rgba(195, 169, 129, 0.15)' : 'rgba(195, 169, 129, 0.15)',
//                     py: level > 1 ? 1 : 1.25,
//                     pl: `${level * 16}px`,
//                 }}
//             >
//                 <ListItemText
//                     primary={
//                         <Typography
//                             color="inherit"
//                             sx={{
//                                 fontSize: level > 1 ? '14px' : '16px',
//                                 fontWeight: 500,
//                                 lineHeight: level > 1 ? '20px' : '23px',
//                             }}
//                         >
//                             {item.title}
//                         </Typography>
//                     }
//                 />
//             </NavLink>
//         </Link>
//         <Box
//             sx={{
//                 borderBottom: level > 1 || '1px solid rgba(0, 0, 0, 0.12)',
//             }}
//         ></Box>
//     </>
// );

// export default NavItem;
import React from 'react';
import { Box, ListItemButton, ListItemText, Typography } from '@mui/material';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';

const NavItem = ({ item, level }) => (
    <>
        <NavLink to={`${item.url}`}>
            <ListItemButton
                sx={{
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 16}px`,
                }}
            >
                <ListItemText
                    primary={
                        <Typography
                            color="inherit"
                            sx={{
                                fontSize: level > 1 ? '14px' : '16px',
                                fontWeight: 500,
                                lineHeight: level > 1 ? '20px' : '23px',
                            }}
                        >
                            {item.title}
                        </Typography>
                    }
                />
            </ListItemButton>
        </NavLink>
        <Box
            sx={{
                borderBottom: level > 1 || '1px solid rgba(0, 0, 0, 0.12)',
            }}
        ></Box>
    </>
);

export default NavItem;
