import React, { useMemo, useState } from 'react';

import {
    Button,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    TextField,
    TableRow,
    TableCell,
} from '@mui/material';
import { Box } from '@mui/system';

import Title from '../../../Components/Title';
import ColumnTable from '../../../Components/Table/Colunm';
import Table from './../../../Components/Table/index';
import { Controller, useForm } from 'react-hook-form';
import { addHyphenPhone } from '../../../common/util';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../Components/Table/Pagination';

function Tomb() {
    const { control, setValue, handleSubmit } = useForm();

    const onSubmit = (data) => {
        console.log(data);
    };

    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const columnContents = useMemo(
        () => [
            {
                title: '이름',
                padding: '6px',
                content: (
                    <Controller
                        control={control}
                        name="name"
                        defaultValue=""
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                        )}
                    />
                ),
            },
            {
                title: '전화번호',
                padding: '6px',
                content: (
                    <Controller
                        control={control}
                        name="phone"
                        defaultValue=""
                        rules={{
                            onChange: (e) => {
                                const value = e.target.value;
                                setValue('phone', addHyphenPhone(value));
                            },
                        }}
                        render={({ field, fieldState: { error } }) => (
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                {...field}
                            />
                        )}
                    />
                ),
            },
        ],
        [control, setValue]
    );

    const tombs = [
        {
            id: 0,
            area: '서울시 송파구',
            funeralHall: '유토피아 수목장',
            phone: '02-578-444',
            reservationRoomCnt: 17000,
            roomCnt: 20000,
            statusCd: 0,
        },
        {
            id: 1,
            area: '서울시 구로구',
            funeralHall: '(재)용인추모원 자연장지',
            phone: '02-578-444',
            reservationRoomCnt: 375,
            roomCnt: 600,
            statusCd: 1,
        },
        {
            id: 2,
            area: '서울시 성북구',
            funeralHall: '에버그린수목장',
            phone: '02-578-444',
            reservationRoomCnt: 11200,
            roomCnt: 12000,
            statusCd: 2,
        },
    ];

    const tombTableCellProps = [
        { align: 'center' },
        {},
        {},
        {},
        { align: 'center' },
        { align: 'center' },
    ];

    return (
        <>
            <Title header={{ title: '장지' }} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <ColumnTable columnContents={columnContents} />
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    sx={{ display: 'flex', margin: '20px auto 30px auto' }}
                >
                    검색
                </Button>
            </form>

            <Box
                sx={{
                    minWidth: 120,
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <FormControl sx={{ m: '8px', minWidth: 120 }} size="small">
                    <InputLabel htmlFor="grouped-select">지역</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                        sx={{ height: '40px !important' }}
                    >
                        <MenuItem
                            value={1}
                            sx={{ textAlign: 'left', fontSize: '14px' }}
                        >
                            전국
                        </MenuItem>
                    </Select>
                </FormControl>
                <FormControl
                    sx={{ m: ' 8px 0 5px 15px', minWidth: 120 }}
                    size="small"
                >
                    <InputLabel htmlFor="grouped-select">운영 상태</InputLabel>
                    <Select
                        defaultValue={1}
                        id="grouped-select"
                        label="Grouping"
                    >
                        <MenuItem value={1}>전체</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    { content: '지역' },
                    { content: '장례시작' },
                    { content: '전화번호' },
                    { content: '예약가능 빈소' },
                    { content: '운영상태' },
                ]}
                tableCellProps={tombTableCellProps}
                items={tombs.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => {
                            // navigate(`${idx}`);
                        }}
                    >
                        {[
                            {
                                content: page * itemsPerPage + idx + 1,
                            },
                            {
                                content: ele.area,
                            },
                            { content: ele.funeralHall },
                            { content: ele.phone },
                            {
                                content: (
                                    <>
                                        <Box
                                            component="span"
                                            sx={{
                                                fontFamily: 'Roboto',
                                                color: '#333',
                                                fontSize: '16px',
                                                fontWeight: 700,
                                            }}
                                        >
                                            {ele.reservationRoomCnt}
                                        </Box>
                                        /{ele.roomCnt}
                                    </>
                                ),
                            },
                            {
                                content:
                                    ele.statusCd === 0 ? (
                                        <Box
                                            component="button"
                                            width={'100%'}
                                            padding={'16px'}
                                            color="primary4.main"
                                        >
                                            승인 전
                                        </Box>
                                    ) : ele.statusCd === 1 ? (
                                        <Box
                                            component="button"
                                            width={'100%'}
                                            padding={'16px'}
                                            color="primary5.main"
                                        >
                                            운영중지
                                        </Box>
                                    ) : ele.statusCd === 2 ? (
                                        <Box width={'100%'} padding={'16px'}>
                                            운영 중
                                        </Box>
                                    ) : (
                                        ''
                                    ),
                            },
                        ].map(({ content }, idx, columns) => (
                            <TableCell
                                key={idx}
                                {...tombTableCellProps[idx]}
                                padding={
                                    // 운영상태 column은 버튼영역을
                                    // 최대로 키우기 위해 padding none
                                    columns.length === idx + 1
                                        ? 'none'
                                        : 'normal'
                                }
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
        </>
    );
}

export default Tomb;
