import {
    Box,
    Button,
    TableCell,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import React, { useState } from 'react';
import { moneyWithCommas } from '../../common/util';
import { numberWithCommas } from '../../common/util';
import Table from '../../Components/Table';
import ColumnTable from '../../Components/Table/Colunm';
import Pagination from '../../Components/Table/Pagination';
import { styled } from '@mui/material/styles';
import PageHeader from '../../Components/PageHeader';

const StyledTableCell = {
    width: '145px',
    background: 'rgba(48, 55, 66, 0.07)',
    borderRight: '1px solid #E0E0E0',
    fontWeight: 500,
    verticalAlign: 'baseline',
    color: '#191919',
};

const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#303742',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 344,
        background: '#303742',
        color: '#FFF',
        padding: '15px 20px',
        fontSize: 14,
        borderRadius: 9,
    },
}));

function OrderGiftDetail() {
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const Gifts = [
        {
            id: 0,
            orderNum: 202111120001244,
            orderDate: '2021.11.12 21:27',
            product: 'LIN 히말라야 핑크소금 200g',
            paymentMethod: '신용카드 3645************',
            paymentStatus: '결제완료',
            quantity: 74,
            price: 1324600,
            deliveryFee: 185500,
            sendName: '이미소 (010-6789-0123)',
            ribbonMessage: '삼가 고인의 명복을 빕니다.',
            content: '동작구 동작경희병원 장례식장 5호실 02-822-811',
        },
    ];
    const gift = Gifts[0];
    const columnContents = [
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell sx={StyledTableCell}>주문번호</TableCell>
                    <TableCell className="f-w-500">{gift.orderNum}</TableCell>
                    <TableCell sx={StyledTableCell}>주문일</TableCell>
                    <TableCell>{gift.orderDate}</TableCell>
                </TableRow>
            ),
        },
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell sx={StyledTableCell}>주문상품</TableCell>
                    <TableCell>{gift.product}</TableCell>
                    <TableCell sx={StyledTableCell}>수량</TableCell>
                    <TableCell>{numberWithCommas(gift.quantity)}</TableCell>
                </TableRow>
            ),
        },
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell sx={StyledTableCell}> 결제금액</TableCell>
                    <TableCell sx={{ verticalAlign: 'baseline' }}>
                        <Box className="f-w-500" mb={2}>
                            {moneyWithCommas(gift.price + gift.deliveryFee)}
                        </Box>
                        <Box display="flex">
                            <Box width={60}>상품금액:</Box>
                            {moneyWithCommas(gift.price)}
                        </Box>

                        <Box display="flex">
                            <Box width={60}>배송료:</Box>
                            {moneyWithCommas(gift.deliveryFee)}
                        </Box>
                    </TableCell>
                    <TableCell sx={StyledTableCell}>결제</TableCell>
                    <TableCell sx={{ verticalAlign: 'baseline' }}>
                        <Box>{gift.paymentStatus}</Box>
                        {gift.paymentMethod}
                    </TableCell>
                </TableRow>
            ),
        },
        {
            title: '보내는 분',
            content: [gift.sendName],
        },
    ];

    const orderGiftDetail = [
        {
            name: '조진성',
            contact: '010-8446-9965',
            shippingStatus: '수령거절    2021.11.12 21:15',
            lookUp: 1,
            refuseReason:
                '해외에 있어 직접 찾아 뵙고 조문 드리지 못 했습니다. 답례품은 받지 못하나 한국에 들어가는 대 로 장지로 선생님을 찾아 뵙고 인사 드리겠 습니다.',
        },
        {
            name: '조진석',
            contact: '010-8446-9965',
            shippingStatus: '주문접수    2021.11.12 21:31',
            lookUp: 0,
        },
        {
            name: '박연수',
            contact: '010-8446-9965',
            shippingStatus: '주문접수    2021.11.12 21:39',
        },
    ];

    const orderGiftDetailtableCellProps = [
        { width: '60px', align: 'center' },
        { width: '120px' },
        {},
        { width: '220px' },
        { width: '140px', align: 'center' },
    ];

    return (
        <>
            <PageHeader
                title="답례품 주문관리 상세"
                breadcrumbItems={[
                    { name: '답례품 주문관리' },
                    { name: '답례품 주문관리 상세' },
                ]}
            />
            <Box
                sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#303742',
                    mb: '10px',
                }}
            >
                주문내역
            </Box>
            <ColumnTable columnContents={columnContents} maxColumnCnt={4} />
            <Box
                sx={{
                    fontWeight: 500,
                    fontSize: '16px',
                    color: '#303742',
                    margin: '45px 0 10px 0',
                }}
            >
                받는 분
            </Box>
            <Table
                numeric
                reverse
                columns={[
                    { content: '이름' },
                    { content: '연락처' },
                    { content: '배송상태' },
                    { content: '배송조회' },
                ]}
                tableCellProps={orderGiftDetailtableCellProps}
                items={orderGiftDetail.map((ele, idx) => (
                    <TableRow key={idx}>
                        {[
                            { content: page * itemsPerPage + idx + 1 },
                            { content: ele.name },
                            { content: ele.contact },
                            { content: ele.shippingStatus },
                            {
                                content:
                                    ele.lookUp === 0 ? (
                                        <Box color="primary4.main">
                                            배송조회
                                        </Box>
                                    ) : ele.lookUp === 1 ? (
                                        <StyledTooltip
                                            title={ele.refuseReason}
                                            placement="bottom-end"
                                            arrow
                                        >
                                            <Box
                                                color="primary5.main"
                                                sx={{ cursor: 'pointer' }}
                                            >
                                                거절사유
                                            </Box>
                                        </StyledTooltip>
                                    ) : (
                                        ''
                                    ),
                            },
                        ].map(({ content }, idx) => (
                            <TableCell
                                key={idx}
                                {...orderGiftDetailtableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
        </>
    );
}

export default OrderGiftDetail;
