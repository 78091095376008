import React, { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import ColumnTable from '../../../Components/Table/Colunm';
import EstimateModal from '../../../Components/Modal/EstimateModal';
import Table from '../../../Components/Table';
import { Box } from '@mui/system';
import CheckIcon from '@mui/icons-material/Check';
import Pagination from '../../../Components/Table/Pagination';
import PageHeader from '../../../Components/PageHeader';
import { moneyWithCommas } from '../../../common/util';

const subTitle = {
    fontWeight: 500,
    fontSize: '16px',
    lineHeight: '23px',
    color: '#303742',
    marginBottom: '10px',
};

const StyledTableCell = {
    minWidth: '145px',
    background: 'rgba(48, 55, 66, 0.07)',
    borderRight: '1px solid #E0E0E0',
    fontWeight: 500,
    verticalAlign: 'baseline',
    color: '#191919',
};

function EstimateDetail() {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);

    const columnContents = [
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell sx={StyledTableCell}>장례기간</TableCell>
                    <TableCell width={'50%'}>
                        2021.11.12 ~ 2021.11.14 (3일)
                    </TableCell>
                    <TableCell sx={StyledTableCell}>희망지역</TableCell>
                    <TableCell width={'50%'}>서울 동작구</TableCell>
                </TableRow>
            ),
        },
        {
            children: (
                <TableRow
                    sx={{
                        '&:last-child td, &:last-child th': {
                            borderBottom: 0,
                        },
                    }}
                >
                    <TableCell sx={StyledTableCell}>고인명</TableCell>
                    <TableCell>홍경수</TableCell>
                    <TableCell sx={StyledTableCell}>요청자</TableCell>
                    <TableCell>홍길동</TableCell>
                </TableRow>
            ),
        },
        {
            title: '예상 조문객',
            content: '49명 이하',
        },
    ];

    const receivedEstimate = [
        {
            id: 0,
            funeral: '서울보라매병원장례식장',
            funeralDirector: '홍경수',
            price: 8400000,
            reservation: true,
        },
        {
            id: 1,
            funeral: '동작경희병원장례식장',
            funeralDirector: '홍경수',
            price: 9500000,
        },
    ];

    const EstimateDetailTableCellProps = [
        { align: 'center', width: '60px' },
        {},
        {
            align: 'center',
            width: '120px',
        },
        {
            align: 'right',
            width: '150px',
        },
        {
            align: 'center',
            width: '100px',
            padding: 'none',
        },
    ];

    const estimate = {
        children: [
            {
                title: '견적금액',
                children: [{ totalPrice: 9550000 }],
            },
            {
                title: '장례기간',
                children: [
                    { content: '2021년 11월 12일 ~ 2021년 11월 14일 (3일)' },
                ],
            },
            {
                title: '장래식장',
                children: [
                    {
                        content: '동작경희병원 장례식장',
                        address: '서울 동작구 상도로 146 동작경희병원',
                    },
                ],
            },
            {
                title: '빈소',
                children: [
                    { content: '5호 (45평)', price: 2000000 },
                    { content: '제단 꽃장식 9호', price: 400000 },
                    { content: '영정사진', price: 100000 },
                ],
            },
            {
                title: '상복',
                children: [
                    { content: '남성 3벌', price: 135000 },
                    { content: '여성 5벌', price: 175000 },
                ],
            },

            {
                title: '예상조문객',
                children: [{ content: '49명 이하 (접객음식)', price: 3000000 }],
            },
            {
                title: '운구',
                children: [{ content: '관내 이동', price: 100000 }],
            },
            {
                title: '수시',
                children: [{ content: '수시비', price: 200000 }],
            },
            {
                title: '안치',
                children: [{ content: '2일', price: 3500000 }],
            },
            {
                title: '염습',
                children: [{ content: '염습비', price: 30000 }],
            },
            {
                title: '입관',
                children: [
                    { content: '목화순면수의', price: 80000 },
                    { content: '향나무 2단관', price: 1350000 },
                ],
            },
            {
                title: '청소',
                children: [{ content: '청소 및 폐기물 처리', price: 300000 }],
            },
            {
                title: '인력파견',
                children: [
                    { content: '장례지도사 1인', price: 300000 },
                    { content: '접객도우미 0인', price: 0 },
                ],
            },
        ],
    };

    return (
        <>
            <PageHeader
                title="장례식 견적 상세"
                breadcrumbItems={[
                    { name: '장례식 견적관리' },
                    { name: '장례식 견적 상세' },
                ]}
            />
            <Box mb={'45px'}>
                <Box sx={subTitle}>견적 요청</Box>
                <ColumnTable columnContents={columnContents} />
            </Box>
            <Box>
                <Box sx={subTitle}>받은 견적</Box>
                <Table
                    numeric
                    reverse
                    columns={[
                        {
                            content: '장례식장',
                        },
                        {
                            content: '장례지도사',
                        },
                        {
                            content: '견적금액',
                        },
                        {
                            content: '예약',
                        },
                    ]}
                    tableCellProps={EstimateDetailTableCellProps}
                    items={receivedEstimate.map((ele, idx) => (
                        <TableRow key={idx} onClick={() => handleOpen()}>
                            {[
                                { content: page * itemsPerPage + (idx + 1) },
                                { content: ele.funeral },
                                { content: ele.funeralDirector },
                                { content: moneyWithCommas(ele.price) },
                                { content: ele.reservation && <CheckIcon /> },
                            ].map(({ content }, idx) => (
                                <TableCell
                                    key={idx}
                                    {...EstimateDetailTableCellProps[idx]}
                                >
                                    {content}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                />
            </Box>
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />
            <EstimateModal
                estimate={estimate}
                title={'장례식 견적서'}
                open={open}
                close={handleClose}
            />
        </>
    );
}
export default EstimateDetail;
