import React, { useState } from 'react';

import { TableCell, TableRow } from '@mui/material';

import Title from '../../Components/Title';
import DatePickerTable from '../../Components/Table/DatePickerTable';
import Pagination from './../../Components/Table/Pagination';
import { useForm } from 'react-hook-form';
import Table from '../../Components/Table';
import { useNavigate } from 'react-router-dom';

function ManageUser() {
    const { control, watch } = useForm();
    const [page, setPage] = useState(0);
    const { startDate, endDate, field1: name, field2: phone } = watch();
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();

    const manage = [
        {
            id: 1,
            name: '이미소',
            phNum: '010-0190-28374',
            funeral: '0',
            signUp: '네이버',
            joinDay: '2021.11.25',
            lastLogin: '오늘',
        },
        {
            id: 2,
            name: '이미소',
            phNum: '010-0190-28374',
            funeral: '0',
            signUp: '휴대폰번호',
            joinDay: '2021.11.25',
            lastLogin: '352일 전',
        },
        {
            id: 3,
            name: '이미소',
            phNum: '010-0190-28374',
            funeral: '0',
            signUp: '카카오',
            joinDay: '2021.11.20',
            lastLogin: '11일 전',
        },
    ];

    const manageUserTableCellProps = [
        { sx: { width: '60px', textAlign: 'center' } },
        { sx: { width: '120px', textAlign: 'left' } },
        { sx: { maxWidth: '100%', textAlign: 'left' } },
        { sx: { Width: '150px', textAlign: 'center' } },
        { sx: { width: '150px', textAlign: 'left' } },
        { sx: { width: '120px', textAlign: 'left' } },
        { sx: { width: '120px', textAlign: 'center' } },
    ];

    return (
        <>
            <Title header={{ title: '회원 관리' }} />
            <DatePickerTable
                title={'가입일'}
                title2={'이름'}
                title3={'휴대폰 번호'}
                control={control}
                search={() => {}}
            />
            <Table
                numeric
                reverse
                columns={[
                    {
                        content: '이름',
                    },
                    {
                        content: '휴대폰 번호',
                    },
                    {
                        content: '장례진행',
                    },
                    {
                        content: '가입방식',
                    },
                    {
                        content: '가입일',
                    },
                    {
                        content: '마지막 로그인',
                    },
                ]}
                tableCellProps={manageUserTableCellProps}
                items={manage.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        sx={{ cursor: 'pointer' }}
                        onClick={() => navigate(`/user/${idx}`)}
                    >
                        {[
                            { content: page * itemsPerPage + (idx + 1) },
                            { content: ele.name },
                            { content: ele.phNum },
                            { content: ele.funeral },
                            { content: ele.signUp },
                            { content: ele.joinDay },
                            { content: ele.lastLogin },
                        ].map(({ content }, idx) => (
                            <TableCell
                                key={idx}
                                {...manageUserTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ marginTop: '25px' }}
            />
        </>
    );
}
export default ManageUser;
