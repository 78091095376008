import React, { useRef } from 'react';
import ModalLayout, {
    BACKDROP_TYPES,
} from './../../Components/Modal/ModalLayout';
import styled from 'styled-components';
import {
    getDiffMinutes,
    getDiffDays,
    getTimeString,
    getDateString,
} from './../../common/util';
import InfiniteScroll from 'react-infinite-scroll-component';
import { IconButton } from '@mui/material';
import ImageIcon from '@mui/icons-material/Image';
import SendImage from './../../resources/img/ic-send.svg';
import { useForm } from 'react-hook-form';
import CloseIcon from '@mui/icons-material/Close';
import { useAlert } from 'react-alert';

const ModalHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 13px 10px 13px 15px;
    background: #f6f4f1;
    box-shadow: inset 0px -1px 0px #c3a981;

    font-family: 'Noto Sans KR';
    font-weight: 500;
    font-size: 16px;
    color: #000;
`;

const ChatWrapper = styled.div`
    display: flex;
    flex-direction: column;
    width: 370px;
    height: 700px;

    .chat-content-wrapper {
        width: 100%;
        overflow: auto;
        display: flex;
        flex-direction: column-reverse;
        .chat-date {
            font-family: 'Roboto';
            font-weight: 400;
            font-size: 12px;
            color: #666666;
            text-align: center;
            margin: 15px;
        }
        .chat {
            margin: 15px;
            .chat-body {
                display: flex;
                align-items: flex-start;

                .chat-message {
                    background: #f1f1f1;
                    border-radius: 0px 6px 6px 6px;
                    display: inline-block;
                    padding: 12px;

                    &.file {
                        background: transparent;
                        border-radius: initial;
                        padding: 0;

                        img {
                            max-width: 100px;
                        }
                    }
                }
            }

            .chat-time {
                font-family: 'Roboto';
                font-weight: 400;
                font-size: 11px;
                color: #888;
                margin: 8px 0 0;
            }

            &.me {
                .chat-body {
                    justify-content: flex-end;

                    & > div {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-end;
                        .chat-message {
                            background: rgba(195, 169, 129, 0.5);
                            border-radius: 6px 0px 6px 6px;

                            &.file {
                                background: transparent;
                                border-radius: initial;
                            }
                        }
                        .chat-time {
                            text-align: right;
                        }
                    }
                }
            }
        }
    }
`;

const ChatForm = styled.form`
    padding: 10px;
    background: #eeeeee;

    .chat-input {
        background: #fff;
        border: 1px solid #ddd;
        border-radius: 25px;

        padding: 4px 7px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 5px;

        input {
            flex: 1;
            border: 0;
            outline: 0;
            &:focus {
                outline: none !important;
            }
        }
    }
`;

function InquireConsultModal({ open, close }) {
    const alert = useAlert();
    const chatRoomWrapper = useRef();
    const { register, handleSubmit } = useForm();
    const inputImage = useRef();

    const onSubmit = (data) => {
        console.log(data);
    };

    const messages = [
        {
            messageId: 2105426958,
            messageType: 'user',
            channelUrl:
                'sendbird_group_channel_89052958_43e6259283bcf6cc9ad6a495cce4d7e480d2facc',
            data: '',
            customType: '',
            silent: false,
            createdAt: 1661135193214,
            updatedAt: 0,
            channelType: 'group',
            metaArrays: [],
            reactions: [],
            mentionType: 'users',
            mentionedUsers: [],
            mentionedUserIds: [],
            mentionedMessageTemplate: null,
            sendingStatus: 'succeeded',
            parentMessageId: 0,
            parentMessageText: null,
            threadInfo: {
                replyCount: 0,
                mostRepliedUsers: [],
                lastRepliedAt: 0,
                updatedAt: 0,
            },
            isReplyToChannel: false,
            parentMessage: null,
            ogMetaData: null,
            isOperatorMessage: false,
            appleCriticalAlertOptions: null,
            reqId: '1661134909758',
            _isAutoResendRegistered: false,
            message: '안녕하세요',
            _sender: {
                nickname: '홍길동',
                plainProfileUrl: '',
                userId: 'DIRECTOR_3',
                connectionStatus: 'nonavailable',
                lastSeenAt: 0,
                metaData: {},
                isActive: true,
                friendDiscoveryKey: null,
                friendName: null,
                _preferredLanguages: null,
                requireAuth: false,
                role: 'none',
                isBlockedByMe: false,
            },
            translations: {},
            requestState: 'succeeded',
            requestedMentionUserIds: [],
            errorCode: 0,
            messageSurvivalSeconds: -1,
            plugins: [],
            poll: null,
            _messageParams: null,
        },
        {
            messageId: 2105426767,
            messageType: 'user',
            channelUrl:
                'sendbird_group_channel_89052958_43e6259283bcf6cc9ad6a495cce4d7e480d2facc',
            data: '',
            customType: '',
            silent: false,
            createdAt: 1661135184785,
            updatedAt: 0,
            channelType: 'group',
            metaArrays: [],
            reactions: [],
            mentionType: 'users',
            mentionedUsers: [],
            mentionedUserIds: [],
            mentionedMessageTemplate: null,
            sendingStatus: 'succeeded',
            parentMessageId: 0,
            parentMessageText: null,
            threadInfo: {
                replyCount: 0,
                mostRepliedUsers: [],
                lastRepliedAt: 0,
                updatedAt: 0,
            },
            isReplyToChannel: false,
            parentMessage: null,
            ogMetaData: null,
            isOperatorMessage: false,
            appleCriticalAlertOptions: null,
            reqId: '1661134888728',
            _isAutoResendRegistered: false,
            message: '2134124124',
            _sender: {
                nickname: '홍길동',
                plainProfileUrl: '',
                userId: 'BEREAVED_13',
                connectionStatus: 'nonavailable',
                lastSeenAt: 0,
                metaData: {},
                isActive: true,
                friendDiscoveryKey: null,
                friendName: null,
                _preferredLanguages: null,
                requireAuth: false,
                role: 'none',
                isBlockedByMe: false,
            },
            translations: {},
            requestState: 'succeeded',
            requestedMentionUserIds: [],
            errorCode: 0,
            messageSurvivalSeconds: -1,
            plugins: [],
            poll: null,
            _messageParams: null,
        },
        {
            messageId: 2105419836,
            messageType: 'user',
            channelUrl:
                'sendbird_group_channel_89052958_43e6259283bcf6cc9ad6a495cce4d7e480d2facc',
            data: '',
            customType: '',
            silent: false,
            createdAt: 1661134927503,
            updatedAt: 0,
            channelType: 'group',
            metaArrays: [],
            reactions: [],
            mentionType: 'users',
            mentionedUsers: [],
            mentionedUserIds: [],
            mentionedMessageTemplate: null,
            sendingStatus: 'succeeded',
            parentMessageId: 0,
            parentMessageText: null,
            threadInfo: {
                replyCount: 0,
                mostRepliedUsers: [],
                lastRepliedAt: 0,
                updatedAt: 0,
            },
            isReplyToChannel: false,
            parentMessage: null,
            ogMetaData: null,
            isOperatorMessage: false,
            appleCriticalAlertOptions: null,
            reqId: '1661134888726',
            _isAutoResendRegistered: false,
            message: '1234',
            _sender: {
                nickname: '홍길동',
                plainProfileUrl: '',
                userId: 'BEREAVED_13',
                connectionStatus: 'nonavailable',
                lastSeenAt: 0,
                metaData: {},
                isActive: true,
                friendDiscoveryKey: null,
                friendName: null,
                _preferredLanguages: null,
                requireAuth: false,
                role: 'none',
                isBlockedByMe: false,
            },
            translations: {},
            requestState: 'succeeded',
            requestedMentionUserIds: [],
            errorCode: 0,
            messageSurvivalSeconds: -1,
            plugins: [],
            poll: null,
            _messageParams: null,
        },
        {
            messageId: 2073592092,
            messageType: 'file',
            channelUrl:
                'sendbird_group_channel_89052958_43e6259283bcf6cc9ad6a495cce4d7e480d2facc',
            url: 'https://file-ap-2.sendbird.com/f0f7fad2625e4bba8bb9ed906f65bb31.jpg?auth=ehRBYhGdtyxpXgl19XcFyTLEmhRYfKqRgjwS2SVWIyc1qu19FOLUkUwrlzPkAciBBoa8gxyH4H2IZMSXkRH6iA',

            data: '',
            customType: '',
            silent: false,
            createdAt: 1660208890954,
            updatedAt: 0,
            channelType: 'group',
            metaArrays: [],
            reactions: [],
            mentionType: 'users',
            mentionedUsers: [],
            mentionedUserIds: [],
            mentionedMessageTemplate: null,
            sendingStatus: 'succeeded',
            parentMessageId: 0,
            parentMessageText: null,
            threadInfo: {
                replyCount: 0,
                mostRepliedUsers: [],
                lastRepliedAt: 0,
                updatedAt: 0,
            },
            isReplyToChannel: false,
            parentMessage: null,
            ogMetaData: null,
            isOperatorMessage: false,
            appleCriticalAlertOptions: null,
            reqId: '1660208848467',
            _isAutoResendRegistered: false,
            plainUrl:
                'https://file-ap-2.sendbird.com/f0f7fad2625e4bba8bb9ed906f65bb31.jpg',
            name: 'image-612149580879535836.jpg',
            size: 3230333,
            type: 'image/jpeg',
            thumbnails: [],
            _sender: {
                nickname: '홍길동',
                plainProfileUrl: '',
                userId: 'BEREAVED_13',
                connectionStatus: 'nonavailable',
                lastSeenAt: 0,
                metaData: {},
                isActive: true,
                friendDiscoveryKey: null,
                friendName: null,
                _preferredLanguages: null,
                requireAuth: false,
                role: 'none',
                isBlockedByMe: false,
            },
            requireAuth: true,
            requestState: 'succeeded',
            requestedMentionUserIds: [],
            errorCode: 0,
            messageSurvivalSeconds: -1,
            _messageParams: null,
        },
        {
            messageId: 2067832579,
            messageType: 'user',
            channelUrl:
                'sendbird_group_channel_89052958_43e6259283bcf6cc9ad6a495cce4d7e480d2facc',
            data: '',
            customType: '',
            silent: false,
            createdAt: 1660036447984,
            updatedAt: 0,
            channelType: 'group',
            metaArrays: [],
            reactions: [],
            mentionType: 'users',
            mentionedUsers: [],
            mentionedUserIds: [],
            mentionedMessageTemplate: null,
            sendingStatus: 'succeeded',
            parentMessageId: 0,
            parentMessageText: null,
            threadInfo: {
                replyCount: 0,
                mostRepliedUsers: [],
                lastRepliedAt: 0,
                updatedAt: 0,
            },
            isReplyToChannel: false,
            parentMessage: null,
            ogMetaData: null,
            isOperatorMessage: false,
            appleCriticalAlertOptions: null,
            reqId: '1660036141670',
            _isAutoResendRegistered: false,
            message: '반갑습니다',
            _sender: {
                nickname: '홍길동',
                plainProfileUrl: '',
                userId: 'DIRECTOR_3',
                connectionStatus: 'nonavailable',
                lastSeenAt: 0,
                metaData: {},
                isActive: true,
                friendDiscoveryKey: null,
                friendName: null,
                _preferredLanguages: null,
                requireAuth: false,
                role: 'none',
                isBlockedByMe: false,
            },
            translations: {},
            requestState: 'succeeded',
            requestedMentionUserIds: [],
            errorCode: 0,
            messageSurvivalSeconds: -1,
            plugins: [],
            poll: null,
            _messageParams: null,
        },
        {
            messageId: 2067579407,
            messageType: 'user',
            channelUrl:
                'sendbird_group_channel_89052958_43e6259283bcf6cc9ad6a495cce4d7e480d2facc',
            data: '',
            customType: '',
            silent: false,
            createdAt: 1660029055416,
            updatedAt: 0,
            channelType: 'group',
            metaArrays: [],
            reactions: [],
            mentionType: 'users',
            mentionedUsers: [],
            mentionedUserIds: [],
            mentionedMessageTemplate: null,
            sendingStatus: 'succeeded',
            parentMessageId: 0,
            parentMessageText: null,
            threadInfo: {
                replyCount: 0,
                mostRepliedUsers: [],
                lastRepliedAt: 0,
                updatedAt: 0,
            },
            isReplyToChannel: false,
            parentMessage: null,
            ogMetaData: null,
            isOperatorMessage: false,
            appleCriticalAlertOptions: null,
            reqId: '1660029051324',
            _isAutoResendRegistered: false,
            message: '안녕하세요',
            _sender: {
                nickname: '홍길동',
                plainProfileUrl: '',
                userId: 'BEREAVED_13',
                connectionStatus: 'nonavailable',
                lastSeenAt: 0,
                metaData: {},
                isActive: true,
                friendDiscoveryKey: null,
                friendName: null,
                _preferredLanguages: null,
                requireAuth: false,
                role: 'none',
                isBlockedByMe: false,
            },
            translations: {},
            requestState: 'succeeded',
            requestedMentionUserIds: [],
            errorCode: 0,
            messageSurvivalSeconds: -1,
            plugins: [],
            poll: null,
            _messageParams: null,
        },
        {
            messageId: 2067578768,
            messageType: 'user',
            channelUrl:
                'sendbird_group_channel_89052958_43e6259283bcf6cc9ad6a495cce4d7e480d2facc',
            data: '',
            customType: '',
            silent: false,
            createdAt: 1660029035676,
            updatedAt: 0,
            channelType: 'group',
            metaArrays: [],
            reactions: [],
            mentionType: 'users',
            mentionedUsers: [],
            mentionedUserIds: [],
            mentionedMessageTemplate: null,
            sendingStatus: 'succeeded',
            parentMessageId: 0,
            parentMessageText: null,
            threadInfo: {
                replyCount: 0,
                mostRepliedUsers: [],
                lastRepliedAt: 0,
                updatedAt: 0,
            },
            isReplyToChannel: false,
            parentMessage: null,
            ogMetaData: null,
            isOperatorMessage: false,
            appleCriticalAlertOptions: null,
            reqId: '1660029028468',
            _isAutoResendRegistered: false,
            message: '반갑습니다',
            _sender: {
                nickname: '홍길동',
                plainProfileUrl: '',
                userId: 'BEREAVED_13',
                connectionStatus: 'nonavailable',
                lastSeenAt: 0,
                metaData: {},
                isActive: true,
                friendDiscoveryKey: null,
                friendName: null,
                _preferredLanguages: null,
                requireAuth: false,
                role: 'none',
                isBlockedByMe: false,
            },
            translations: {},
            requestState: 'succeeded',
            requestedMentionUserIds: [],
            errorCode: 0,
            messageSurvivalSeconds: -1,
            plugins: [],
            poll: null,
            _messageParams: null,
        },
        {
            messageId: 2067574784,
            messageType: 'user',
            channelUrl:
                'sendbird_group_channel_89052958_43e6259283bcf6cc9ad6a495cce4d7e480d2facc',
            data: '',
            customType: '',
            silent: false,
            createdAt: 1660028917820,
            updatedAt: 0,
            channelType: 'group',
            metaArrays: [],
            reactions: [],
            mentionType: 'users',
            mentionedUsers: [],
            mentionedUserIds: [],
            mentionedMessageTemplate: null,
            sendingStatus: 'succeeded',
            parentMessageId: 0,
            parentMessageText: null,
            threadInfo: {
                replyCount: 0,
                mostRepliedUsers: [],
                lastRepliedAt: 0,
                updatedAt: 0,
            },
            isReplyToChannel: false,
            parentMessage: null,
            ogMetaData: null,
            isOperatorMessage: false,
            appleCriticalAlertOptions: null,
            reqId: '1660028775418',
            _isAutoResendRegistered: false,
            message: '안녕하세유',
            _sender: {
                nickname: '홍길동',
                plainProfileUrl: '',
                userId: 'BEREAVED_13',
                connectionStatus: 'nonavailable',
                lastSeenAt: 0,
                metaData: {},
                isActive: true,
                friendDiscoveryKey: null,
                friendName: null,
                _preferredLanguages: null,
                requireAuth: false,
                role: 'none',
                isBlockedByMe: false,
            },
            translations: {},
            requestState: 'succeeded',
            requestedMentionUserIds: [],
            errorCode: 0,
            messageSurvivalSeconds: -1,
            plugins: [],
            poll: null,
            _messageParams: null,
        },
        {
            messageId: 2067541950,
            messageType: 'user',
            channelUrl:
                'sendbird_group_channel_89052958_43e6259283bcf6cc9ad6a495cce4d7e480d2facc',
            data: '',
            customType: '',
            silent: false,
            createdAt: 1660027896451,
            updatedAt: 0,
            channelType: 'group',
            metaArrays: [],
            reactions: [],
            mentionType: 'users',
            mentionedUsers: [],
            mentionedUserIds: [],
            mentionedMessageTemplate: null,
            sendingStatus: 'succeeded',
            parentMessageId: 0,
            parentMessageText: null,
            threadInfo: {
                replyCount: 0,
                mostRepliedUsers: [],
                lastRepliedAt: 0,
                updatedAt: 0,
            },
            isReplyToChannel: false,
            parentMessage: null,
            ogMetaData: null,
            isOperatorMessage: false,
            appleCriticalAlertOptions: null,
            reqId: '1660027887478',
            _isAutoResendRegistered: false,
            message: '안녕하세요',
            _sender: {
                nickname: '홍길동',
                plainProfileUrl: '',
                userId: 'BEREAVED_13',
                connectionStatus: 'nonavailable',
                lastSeenAt: 0,
                metaData: {},
                isActive: true,
                friendDiscoveryKey: null,
                friendName: null,
                _preferredLanguages: null,
                requireAuth: false,
                role: 'none',
                isBlockedByMe: false,
            },
            translations: {},
            requestState: 'succeeded',
            requestedMentionUserIds: [],
            errorCode: 0,
            messageSurvivalSeconds: -1,
            plugins: [],
            poll: null,
            _messageParams: null,
        },
    ];

    return (
        <ModalLayout
            open={open}
            close={close}
            headerChildren={
                <ModalHeader>
                    <div>홍길동</div>
                    <IconButton
                        size="small"
                        sx={{ padding: '5px' }}
                        onClick={close}
                    >
                        <CloseIcon sx={{ color: '#333' }} />
                    </IconButton>
                </ModalHeader>
            }
            backdropType={BACKDROP_TYPES.OPACITY}
        >
            <ChatWrapper className="chat-body-wrapper">
                <div
                    id="scrollableDiv"
                    className="chat-content-wrapper"
                    ref={chatRoomWrapper}
                >
                    <InfiniteScroll
                        dataLength={messages.length}
                        // next={loadMessages}
                        style={{
                            display: 'flex',
                            flexDirection: 'column-reverse',
                        }}
                        inverse={true} //
                        // hasMore={
                        //     commentListQuery.current
                        //         ? commentListQuery.current.hasMore
                        //         : true
                        // }
                        hasMore={false}
                        scrollableTarget="scrollableDiv"
                    >
                        {messages.map((message, idx, messages) => {
                            const { _sender: sender } = message;
                            const isMe = sender.userId === 'DIRECTOR_3';

                            return (
                                <React.Fragment key={idx}>
                                    {
                                        // 채팅
                                        <div
                                            className={`chat ${
                                                isMe ? 'me' : ''
                                            }`}
                                        >
                                            <div className="chat-body">
                                                <div>
                                                    <div
                                                        className={`chat-message ${
                                                            message.messageType ===
                                                            'file'
                                                                ? 'file'
                                                                : ''
                                                        }`}
                                                    >
                                                        {message.messageType ===
                                                        'user' ? (
                                                            message.message
                                                        ) : (
                                                            <img
                                                                src={
                                                                    message.url
                                                                }
                                                                alt="message"
                                                            />
                                                        )}
                                                    </div>
                                                    {
                                                        // 채팅 시간
                                                    }
                                                    <>
                                                        {messages[idx - 1] ? (
                                                            // 다음 채팅과 날짜가 1분이상 차이나거나 유저 아이디가 다른경우
                                                            getDiffMinutes(
                                                                messages[
                                                                    idx - 1
                                                                ].createdAt,
                                                                message.createdAt
                                                            ) > 0 ||
                                                            messages[idx - 1]
                                                                ._sender
                                                                .userId !==
                                                                sender.userId ? (
                                                                <div className="chat-time">
                                                                    {getTimeString(
                                                                        message.createdAt
                                                                    )}
                                                                </div>
                                                            ) : null
                                                        ) : (
                                                            // 다음 채팅이 없는경우
                                                            <div className="chat-time">
                                                                {getTimeString(
                                                                    message.createdAt
                                                                )}
                                                            </div>
                                                        )}
                                                    </>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        // 채팅 날짜
                                        messages[idx + 1] ? (
                                            // 이전 채팅과 날짜가 1일이상 차이나는경우
                                            getDiffDays(
                                                messages[idx + 1].createdAt,
                                                message.createdAt
                                            ) > 0 ? (
                                                <div className="chat-date">
                                                    {getDateString(
                                                        message.createdAt,
                                                        'point'
                                                    )}
                                                </div>
                                            ) : null
                                        ) : (
                                            // 이전 채팅이 없는경우
                                            <div className="chat-date">
                                                {getDateString(
                                                    message.createdAt,
                                                    'point'
                                                )}
                                            </div>
                                        )
                                    }
                                </React.Fragment>
                            );
                        })}
                    </InfiniteScroll>
                </div>
                <ChatForm onSubmit={handleSubmit(onSubmit)}>
                    <div className="chat-input">
                        <IconButton
                            size="small"
                            onClick={() => {
                                inputImage.current.click();
                            }}
                        >
                            <ImageIcon />
                            <input
                                hidden
                                accept="image/gif, image/jpeg, image/png"
                                type="file"
                                ref={inputImage}
                                onChange={(e) => {
                                    const { files } = e.target;
                                    const [file] = files;
                                    if (file) {
                                        const { type } = file;
                                        // 파일이 지정한 타입에 해당하다면 정상적으로 값설정
                                        if (
                                            type === 'image/gif' ||
                                            type === 'image/jpeg' ||
                                            type === 'image/png'
                                        ) {
                                            //
                                        }
                                        alert.error(
                                            'jpeg, png, gif타입의 이미지를 업로드해주세요.'
                                        );
                                    }
                                }}
                            />
                        </IconButton>
                        <input
                            type={'text'}
                            autoComplete="off"
                            placeholder="메시지를 입력해주세요."
                            {...register('message', {
                                required: '메시지를 입력해주세요.',
                            })}
                        />
                        <IconButton type="submit">
                            <img src={SendImage} alt="send" />
                        </IconButton>
                    </div>
                </ChatForm>
            </ChatWrapper>
        </ModalLayout>
    );
}

export default InquireConsultModal;
