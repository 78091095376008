import React, { useState } from 'react';
import { Button, TableCell, TableRow, Typography } from '@mui/material';
import Title from '../../../Components/Title';
import { Box } from '@mui/system';
import ColumnTable from '../../../Components/Table/Colunm';
import styled from 'styled-components';
import Pagination from '../../../Components/Table/Pagination';
import Table from '../../../Components/Table';
import { moneyWithCommas } from '../../../common/util';
import DialogModal from './../../../Components/Modal/DialogModal';
import PageHeader from '../../../Components/PageHeader';

const StyleTypography = styled(Typography)`
    font-weight: 500;
    font-size: 16px;
    font-size: 16px !important;
    line-height: 23px;
    color: #303742;
    margin-bottom: 10px;
`;

function FuneralDetail() {
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    // 운영 제한 모달
    const [isOpenDisableManage, setIsOpenDisableManage] = useState(false);
    // 운영 재개 모달
    const [isOpenResumeManage, setIsOpenResumeManage] = useState(false);

    const columnContents = [
        {
            title: '장례식장',
            content: (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    서울아산병원 장례식장
                    <Button
                        variant="outlined"
                        color="primary4"
                        size="small"
                        sx={{ ml: '30px', height: '30px' }}
                    >
                        다운로드
                    </Button>
                </Box>
            ),
        },
        {
            title: '주소',
            content: '서울시 송파구 올림픽로43길 88',
        },
        {
            title: '전화번호',
            content: '02-3010-2000',
        },
        {
            title: '상태',
            content: (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '11px 16px',
                    }}
                >
                    운영 중
                    <div>
                        <Button
                            variant="outlined"
                            size="small"
                            color="error"
                            sx={{ height: ' 30px' }}
                            onClick={() => setIsOpenDisableManage(true)}
                        >
                            운영 제한
                        </Button>
                        <Button
                            variant="contained"
                            size="small"
                            color="primary2"
                            sx={{ height: ' 30px' }}
                            onClick={() => setIsOpenResumeManage(true)}
                        >
                            운영 재개
                        </Button>
                    </div>
                </Box>
            ),
            padding: 0,
        },
    ];

    const mortuary = [
        {
            id: 1,
            name: '이름름',
            date: '2022-01-09',
            time: '10시 30분',
        },

        {
            id: 2,
            name: '이름름',
            date: '2022-01-09',
            time: '10시 30분',
        },
        {
            id: 3,
            name: '이름름',
            date: '2022-01-09',
            time: '10시 30분',
        },
        {
            id: 4,
            name: '이름름',
            date: '2022-01-09',
            time: '10시 30분',
        },
        {
            id: 5,
            name: '이름름',
            date: '2022-01-09',
            time: '10시 30분',
        },
        {
            id: 6,
            name: '이름름',
            date: '2022-01-09',
            time: '10시 30분',
        },
        {
            id: 7,
            name: '이름름',
            date: '2022-01-09',
            time: '10시 30분',
        },
        {
            id: 8,
            name: '이름름',
            date: '2022-01-09',
            time: '10시 30분',
        },
        {
            id: 9,
            name: '이름름',
            date: '2022-01-09',
            time: '10시 30분',
        },
    ];

    const funeralTableCellProps = [
        { align: 'center', sx: { maxWidth: 'fit-content' } },
        {},
        {},
        {},
        { align: 'right' },
    ];
    const funerals = [
        {
            range: '2021.11.12 ~ 2021.11.14',
            rangeCd: '00',
            room: '7호실',
            deceased: '주영애',
            price: '9550000',
        },
        {
            range: '2021.11.12 ~ 2021.11.14',
            rangeCd: '00',
            room: '7호실',
            deceased: '주영애',
            price: '9550000',
        },
        {
            range: '2021.11.12 ~ 2021.11.14',
            rangeCd: '00',
            room: '7호실',
            deceased: '주영애',
            price: '9550000',
        },
    ];

    return (
        <>
            <PageHeader
                title="장례식장"
                breadcrumbItems={[
                    { name: '장례식장' },
                    { name: '장례식장 정보' },
                ]}
            />
            <StyleTypography>장례식장 정보</StyleTypography>
            <ColumnTable columnContents={columnContents} />
            <Box my={'45px'}>
                <StyleTypography>
                    실시간 빈소 현황
                    <Typography
                        component="span"
                        sx={{
                            fontWeight: 500,
                            fontSize: '12px',
                            lineHeight: '17px',
                            color: '#666666',
                        }}
                        ml="10px"
                    >
                        사용 시 발인시간 표시
                    </Typography>
                </StyleTypography>
                <Box
                    sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        columnGap: '25px',
                        rowGap: '10px',
                    }}
                >
                    {mortuary.map((mortuary, idx) => (
                        <Box
                            sx={{ width: '220px', border: '1px solid #E0E0E0' }}
                            key={idx}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    height: '45px',
                                    alignItems: 'center',
                                    background: 'rgba(48, 55, 66, 0.07)',
                                    fontWeight: 500,
                                }}
                            >
                                {mortuary.id}호실
                            </Box>
                            <Box
                                sx={{
                                    padding: '15px',
                                    textAlign: 'center',
                                    background: '#fff',
                                }}
                            >
                                <Box sx={{ fontWeight: 700 }}>
                                    故<Box mr={'5px'} component="span" />
                                    {mortuary.name}
                                </Box>
                                <Box sx={{ justifyContent: 'center' }}>
                                    {mortuary.date}
                                    <Box mr={'8px'} component="span" />
                                    {mortuary.time}
                                </Box>
                            </Box>
                        </Box>
                    ))}
                </Box>
            </Box>
            <StyleTypography>장례 이력</StyleTypography>
            <Table
                numeric
                reverse
                columns={[
                    { content: '기간' },
                    { content: '빈소' },
                    { content: '고인명' },
                    { content: '금액' },
                ]}
                tableCellProps={funeralTableCellProps}
                items={funerals.map((ele, idx) => (
                    <TableRow key={idx} sx={{ cursor: 'pointer' }}>
                        {[
                            { content: page * itemsPerPage + idx + 1 },
                            { content: ele.range },
                            { content: ele.room },
                            { content: ele.deceased },
                            { content: moneyWithCommas(ele.price) },
                        ].map(({ content }, idx) => (
                            <TableCell
                                key={idx}
                                {...funeralTableCellProps[idx]}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ mt: '25px' }}
            />

            {/* 모달 */}
            <>
                {/* 운영 제한 모달 */}
                <DialogModal
                    open={isOpenDisableManage}
                    close={() => setIsOpenDisableManage(false)}
                    handleClickRightBtn={() => setIsOpenDisableManage(false)}
                    rightBtnProps={{ color: 'error' }}
                    rightBtnChildren="운영제한"
                >
                    운영상태
                </DialogModal>

                {/* 운영 재개 모달 */}
                <DialogModal
                    open={isOpenResumeManage}
                    close={() => setIsOpenResumeManage(false)}
                    handleClickRightBtn={() => setIsOpenResumeManage(false)}
                    rightBtnChildren="운영재개"
                >
                    운영상태
                </DialogModal>
            </>
        </>
    );
}

export default FuneralDetail;
