import { Box } from '@mui/system';
import React from 'react';

function Title({
    header = {},
    modal = false,
    defaultMt = true,
    defaultMb = true,
}) {
    const { title = '' } = header;
    return (
        <Box
            sx={{
                borderBottom: '1px solid #E0E0E0',
                mt: defaultMt ? (modal ? '0px' : '30px') : '0px',
                mb: defaultMb ? (modal ? '20px' : '45px') : '0px',
                height: '45px',
                fontSize: '24px',
                fontWeight: 500,
                lineHeight: '35px',
            }}
        >
            {title}
        </Box>
    );
}

export default Title;
