import React from 'react';

import styled from '@emotion/styled';
import { Box, Container } from '@mui/system';
import { Link, useMatch } from 'react-router-dom';
import { Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { logout } from '../../redux/reducer/user';

const StyledContainer = styled(Container)`
    .img {
        margin-right: 9px;
    }
    span {
        height: 25px;
        font-weight: 700;
    }
`;
function Header() {
    // 로그인 페이지 여부
    const isLoginPage = useMatch('/login');
    const dispatch = useDispatch();

    return (
        <div style={{ background: '#423D36' }}>
            <StyledContainer
                component="main"
                sx={{
                    height: '55px',
                    display: 'flex',
                    justifyContent: 'space-between',
                    position: 'static',

                    alignItems: 'center',
                    fontSize: '14px',
                    lineHeight: '20px',
                    fontWeight: '500',
                    color: ' #FFFFFF',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
                    <Link to="/" className="img">
                        <img
                            src={require('../../images/Logo.svg').default}
                            alt=""
                        />
                    </Link>
                    <span>관리자</span>
                </Box>
                {
                    // 로그인 페이지가 아닌경우에만 관리자 정보, 로그아웃 메뉴 render
                    !!isLoginPage || (
                        <Box sx={{ display: 'flex' }}>
                            <Link to="/info">
                                <Box
                                    sx={{
                                        color: '#C3A981',
                                        marginRight: '24px',
                                    }}
                                >
                                    관리자 정보
                                </Box>
                            </Link>
                            <button
                                style={{ fontWeight: '500', fontSize: '14px' }}
                                onClick={() => {
                                    dispatch(logout());
                                }}
                            >
                                로그아웃
                            </button>
                        </Box>
                    )
                }
            </StyledContainer>
        </div>
    );
}

export default Header;
