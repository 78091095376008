import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
    token: localStorage.getItem('accessToken'),
    me: null,
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setToken: (state, action) => {
            const token = action.payload;
            localStorage.setItem('accessToken', token);
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            state.token = token;
        },
        login: (state, action) => {
            const user = action.payload;
            state.me = user;
        },
        logout: (state) => {
            localStorage.removeItem('accessToken');
            delete axios.defaults.headers.common['Authorization'];
            state.token = null;
            state.me = null;
        },
    },
});

export const { setToken, login, logout } = userSlice.actions;

export default userSlice.reducer;
