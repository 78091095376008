import { TableCell, TableContainer, TableRow, Typography } from '@mui/material';
import { Box } from '@mui/system';
import sx from '@mui/system/sx';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from '../../../Components/Table';
import Pagination from '../../../Components/Table/Pagination';
import Title from '../../../Components/Title';
import GiftImage from './../../../resources/img/ic-gift.svg';
import { moneyWithCommas } from '../../../common/util';
import { numberWithCommas } from '../../../common/util';

function Gift() {
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const navigate = useNavigate();

    const Product = [
        {
            productImg: GiftImage,
            productName: '굳지않는 쑥콩누름(60g X 30개)찹쌀떡',
            price: 20000,
            order: 36423,
        },
        {
            productImg: GiftImage,
            productName: '송월타월 카운테스마라 국산 150g (2매)',
            price: 109000,
            order: 36423,
        },
        {
            productImg: GiftImage,
            productName: '애경 랩신 포밍 핸드워시 250ml',
            price: 4200,
            order: 36423,
        },
        {
            productImg: GiftImage,
            productName: '소백산 사양 아카시아 벌꿀 1호(1.2kg)',
            price: 179000,
            order: 36423,
        },
        {
            productImg: GiftImage,
            productName: '혼합견과 85g',
            price: 2900,
            order: 36423,
        },
    ];

    const ProductTableCellProps = [
        { width: '60px', align: 'center' },
        { width: '160px', align: 'center' },
        {},
        { width: '150px', align: 'right' },
        { width: '120px', align: 'center' },
    ];

    return (
        <>
            <Title header={{ title: '답례품 상품관리' }} />
            <Table
                numeric
                reverse
                columns={[
                    { content: '상품사진' },
                    { content: '상품명' },
                    { content: '금액' },
                    { content: '주문' },
                ]}
                tableCellProps={ProductTableCellProps}
                items={Product.map((ele, idx) => (
                    <TableRow
                        key={idx}
                        onClick={() => navigate(`/product/gift/${idx}`)}
                    >
                        {[
                            { content: page * itemsPerPage + (idx + 1) },
                            {
                                content: (
                                    <img src={ele.productImg} alt="product" />
                                ),
                            },
                            {
                                content: (
                                    <>
                                        <Box>{ele.productName}</Box>
                                        <Box color={'#888888'}>
                                            {moneyWithCommas(ele.price)}
                                        </Box>
                                    </>
                                ),
                            },
                            { content: moneyWithCommas(ele.price) },
                            { content: numberWithCommas(ele.order) },
                        ].map(({ content }, idx) => (
                            <TableCell
                                key={idx}
                                {...ProductTableCellProps[idx]}
                                sx={{ verticalAlign: 'top' }}
                            >
                                {content}
                            </TableCell>
                        ))}
                    </TableRow>
                ))}
            />
            <Pagination
                count={10}
                page={page}
                handleChange={setPage}
                sx={{ marginTop: '25px' }}
            />
        </>
    );
}
export default Gift;
