import { IconButton, Modal, Typography } from '@mui/material';
import { Box, padding, typography } from '@mui/system';
import React from 'react';
import Title from '../Title';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    p: '40px 30px',
};

const Wrapper = styled.div`
    h3 {
        background: rgba(195, 169, 129, 0.2);
        border-left: 3px solid #303742;
        padding: 5px 15px;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: #303742;
        margin-bottom: 10px;
    }
    .container {
        color: #191919;
    }
    .date {
        font-size: 12px;
        color: #666666;
        margin-bottom: 5px;
    }
    .f-w-500 {
        font-weight: 500;
    }
    .m-t-30 {
        margin-top: 30px;
    }
    .m-t-4 {
        margin-top: 4px;
    }

    .user-information {
        margin: 15px 0 30px;
        ul {
            display: flex;

            li {
                &:first-child {
                    width: 70px;
                    font-weight: 500;
                }
            }
            &:not(&:last-child) {
                margin-bottom: 5px;
            }
        }
    }
    .box-area {
        display: flex;
        margin-top: 30px;
        .box {
            width: 150px;
            padding: 10px;
            background: rgba(48, 55, 66, 0.05);
            border: 1px solid rgba(48, 55, 66, 0.15);
            border-radius: 8px;
            &:not(&:last-child) {
                margin-right: 10px;
            }
        }
    }
    .date-area {
        margin-top: 10px;
        padding-left: 20px;
        ul {
            display: flex;
            li {
                &:first-child {
                    list-style: disc;
                    color: #666666;
                    margin-right: 10px;
                }
            }
        }
    }
    .gift {
        display: flex;
        padding-left: 10px;
        margin-top: 25px;
        ul {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 10px;
        }
    }
`;

function CondolenceModal({ open, close }) {
    return (
        <Modal open={open}>
            <Wrapper>
                <Box sx={style}>
                    <IconButton
                        onClick={close}
                        sx={{
                            position: 'absolute',
                            top: '20px',
                            right: '10px',
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                    <Title header={{ title: '조문' }} modal />
                    <div className="container">
                        <h3>부고</h3>
                        <div className="date">2021.11.12</div>
                        <div>
                            <span className="f-w-500">홍경수님</span>께서 2021년
                            11월 12일 오전 10시 소천하셨습니다.
                        </div>
                        <div className="user-information">
                            <ul>
                                <li>상주</li>
                                <li>김종원</li>
                            </ul>
                            <ul>
                                <li>배우자</li>
                                <li>이이이</li>
                            </ul>
                            <ul>
                                <li>빈소</li>
                                <li>서울 동작구 동작경희병원 장례식장 5호</li>
                            </ul>
                            <ul>
                                <li>발인</li>
                                <li>2021년 11월 14일 10시</li>
                            </ul>
                        </div>
                    </div>

                    <div className="container m-t-30">
                        <h3>조문 방명록</h3>
                        <li className="date">2021.11.12</li>
                        <div>더 좋은 곳에서 편히 쉬십시오.</div>
                        <div className="box-area">
                            <div className="box">
                                <span>근조화환</span>
                                <div className="f-w-500 m-t-4">
                                    근조화환 3단 화환
                                </div>
                            </div>
                            <div className="box">
                                <span>조의금</span>
                                <div className="f-w-500 m-t-4">20,000원</div>
                            </div>
                        </div>
                        <div className="date-area">
                            <ul className="order-date">
                                <li>2021.11.12 16:29</li>
                                <li className="f-w-500">배송시작</li>
                            </ul>
                            <ul className="order-date m-t-4">
                                <li>2021.11.12 16:29</li>
                                <li className="f-w-500">배송완료</li>
                            </ul>
                        </div>
                    </div>

                    <div className="container m-t-30">
                        <h3>조문 방명록</h3>
                        <li className="date">2021.11.12</li>
                        <div>
                            지난번 장례식에 바쁘신 와중에도 시간 내어 참석해
                            주셔서 진 심으로 감사드립니다. 황망한 가운데 먼저
                            글로서 감사인사를 드립니다.
                        </div>
                        <div className="gift">
                            <img
                                src={
                                    require('../../images/Image (5).svg')
                                        .default
                                }
                            ></img>
                            <ul>
                                <li className="f-w-500">
                                    Lin 히말라야 핑크소름 200g
                                </li>
                                <li className="m-t-4">3,500원</li>
                            </ul>
                        </div>
                        <div className="date-area">
                            <ul className="order-date">
                                <li>2021.11.12 16:29</li>
                                <li className="f-w-500">배송시작</li>
                            </ul>
                            <ul className="order-date m-t-4">
                                <li>2021.11.12 16:29</li>
                                <li className="f-w-500">배송완료</li>
                            </ul>
                        </div>
                    </div>
                </Box>
            </Wrapper>
        </Modal>
    );
}

export default CondolenceModal;
