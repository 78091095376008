import { Button, Table, TableContainer, TextField } from '@mui/material';
import { Box } from '@mui/system';
import { set } from 'date-fns';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { addHyphenPhone, checkIsValidPhone } from '../../../common/util';
import ErrorMessage from '../../../Components/Form/ErrorMessage';
import ColumnTable from '../../../Components/Table/Colunm';
import Title from '../../../Components/Title';

function FuneralFlower() {
    const { control, handleSubmit, setValue, reset } = useForm();
    const navigate = useNavigate();

    const funeralFlowerBusiness = {
        id: 0,
        name: '(주)플라워센터',
        businessNumber: '352-86-01087',
        representative: '박상화',
        address: '부산광역시 사상구 학강대로 127,2층 (학장동, 문성빌딩)',
        phone: '1666-1002',
    };

    const columnContents = [
        {
            title: '협회명',
            content: funeralFlowerBusiness.name,
        },
        {
            title: '사업자번호',
            content: funeralFlowerBusiness.businessNumber,
        },
        {
            title: '대표자명',
            content: funeralFlowerBusiness.representative,
        },
        {
            title: '주소',
            content: funeralFlowerBusiness.address,
        },
        {
            title: '전화번호',
            content: funeralFlowerBusiness.phone,
        },
    ].map((ele) => ({
        ...ele,
        contentSx: { padding: '16px' },
    }));

    const onSubmit = (data) => {
        console.log(data);
    };

    return (
        <>
            <Title header={{ title: '근조화환' }} />
            <form onSubmit={handleSubmit(onSubmit)}>
                <ColumnTable columnContents={columnContents} />
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        mt: '10px',
                    }}
                >
                    <Button
                        variant="contained"
                        size="large"
                        onClick={() => {
                            navigate(
                                `/partner/flower/${funeralFlowerBusiness.id}/edit`
                            );
                        }}
                    >
                        변경
                    </Button>
                </Box>
            </form>
        </>
    );
}

export default FuneralFlower;
