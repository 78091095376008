import { Box, Button } from '@mui/material';
import React, { useState } from 'react';
import DialogModal from '../../../Components/Modal/DialogModal';
import ColumnTable from '../../../Components/Table/Colunm';
import Title from './../../../Components/Title';
import QuestionForm from './QuestionForm';
import PageHeader from './../../../Components/PageHeader';

function EditQuestion() {
    // 질문 삭제 모달 오픈여부
    const [isOpenRemoveNewsModal, setIsOpenRemoveNewsModal] = useState(false);

    const question = {
        createdDate: '2021.11.07 04:43',
        target: {
            family: true,
            director: true,
            funeralHall: false,
            cemetery: false,
        },
        title: '환불계좌를 등록하지 않았는데 환불 시 어떻게 입금되나요?',
        content: '<h1><strong>자주하는 질문 내용</strong></h1>',
    };

    return (
        <>
            <PageHeader
                title="자주하는 질문 수정"
                breadcrumbItems={[
                    { name: '자주하는 질문' },
                    { name: '자주하는 질문 상세' },
                    { name: '자주하는 질문 수정' },
                ]}
            />
            <QuestionForm question={question} isEdit onSubmit={() => {}} />
            <DialogModal
                open={isOpenRemoveNewsModal}
                close={() => {
                    setIsOpenRemoveNewsModal(false);
                }}
                rightBtnProps={{ color: 'error' }}
                rightBtnChildren="삭제"
                handleClickRightBtn={() => {
                    setIsOpenRemoveNewsModal(false);
                }}
            >
                삭제하시겠습니까?
            </DialogModal>
        </>
    );
}

export default EditQuestion;
