import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { moneyWithCommas } from '../../common/util';
import { OutletOutlined } from '@mui/icons-material';

const Wrapper = styled.div`
    .header-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        h4 {
            font-weight: 500;
            font-size: 24px;
            line-height: 35px;
            color: rgba(0, 0, 0, 0.87);
            line-height: 35px;
        }
        .name {
            font-weight: 700;
            margin-right: 10px;
        }
    }
    .flex {
        display: flex;
        justify-content: space-between;
    }
    .total-price {
        font-family: 'Roboto';
        font-weight: 700;
        font-size: 20px;
        line-height: 23px;
        letter-spacing: -0.25px;
    }
    .price {
        font-family: 'Roboto';
        font-weight: 500;
        line-height: 16px;
        letter-spacing: -0.25px;
    }
    .address {
        color: #666666;
    }
    .fw-700 {
        font-weight: 700;
    }
    .fw-500 {
        font-weight: 500;
    }
`;

const style = {
    position: 'relative',
    margin: '0 auto',
    width: 600,
    bgcolor: '#FFFFFF',
    p: '50px 30px 30px',
};

const StyledTableCell = {
    width: '120px',
    borderWidth: '0px 1px 1px 0px',
    borderStyle: 'solid',
    borderColor: ' #e0e0e0',
    fontWeight: 500,
    lineHeight: '20px',
    verticalAlign: 'top',
    p: '10px 16px',
};

function EstimateModal({ title = '', estimate, open, close }) {
    return (
        <div>
            <Modal
                open={open}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={{ overflow: 'scroll', margin: '90px 0' }}
            >
                <Wrapper>
                    <Box sx={style}>
                        <IconButton
                            size="small"
                            sx={{
                                padding: '5px',
                                position: 'absolute',
                                right: '5px',
                                top: '5px',
                            }}
                            onClick={close}
                        >
                            <CloseIcon sx={{ color: 'rgba(0, 0, 0, 0.54)' }} />
                        </IconButton>
                        <div className="header-container">
                            <h4>{title}</h4>
                            <div className="flex">
                                <ul>
                                    <Box
                                        color="text.secondary"
                                        lineHeight={'16px'}
                                    >
                                        장례지도사
                                    </Box>
                                    <li>
                                        <span className="name">우상욱</span>
                                        010-5436-3465
                                    </li>
                                </ul>
                                <Button
                                    color="primary5"
                                    sx={{
                                        border: '2px solid',
                                        fontWeight: 700,
                                        fontFamily: 'Noto Sans KR',
                                        lineHeight: '20px',
                                        ml: '15px',
                                        padding: '8.5px 15px',
                                    }}
                                >
                                    예약됨
                                </Button>
                            </div>
                        </div>

                        <TableContainer>
                            <Table
                                sx={{
                                    borderWidth: '1px 0',
                                    borderStyle: 'solid',
                                    borderColor: '#E0E0E0',
                                }}
                            >
                                <TableBody>
                                    {estimate.children.map((ele, idx) => (
                                        <TableRow
                                            key={idx}
                                            sx={{
                                                '&:last-child td, &:last-child th':
                                                    {
                                                        borderBottom: 0,
                                                    },
                                                '&:first-of-type td': {
                                                    padding: '16px',
                                                },
                                            }}
                                        >
                                            {ele.subTitle ? (
                                                <TableCell
                                                    colSpan={2}
                                                    sx={{
                                                        fontSize: '16px',
                                                        fontWeight: 500,
                                                        p: '15px 0 5px 0',
                                                    }}
                                                >
                                                    {ele.subTitle}
                                                </TableCell>
                                            ) : (
                                                <TableCell
                                                    sx={{
                                                        ...StyledTableCell,
                                                    }}
                                                >
                                                    {ele.title}
                                                </TableCell>
                                            )}

                                            {ele.children?.map(
                                                (content, idx) => (
                                                    <TableCell
                                                        key={idx}
                                                        className="flex"
                                                        sx={{
                                                            padding:
                                                                content.totalPrice
                                                                    ? '11.5px 16px'
                                                                    : '10px 16px',
                                                            height: '100%',
                                                            lineHeight: '20px',
                                                        }}
                                                    >
                                                        <ul>
                                                            <li className="total-price">
                                                                {content.totalPrice &&
                                                                    moneyWithCommas(
                                                                        content.totalPrice
                                                                    )}
                                                            </li>
                                                            {content.content}
                                                            {content.map}
                                                            <li className="fw-700">
                                                                {content.tomb}
                                                            </li>
                                                            <li className="address">
                                                                {
                                                                    content.address
                                                                }
                                                            </li>
                                                        </ul>
                                                        <li className="price">
                                                            {content.price &&
                                                                moneyWithCommas(
                                                                    content.price
                                                                )}
                                                        </li>
                                                    </TableCell>
                                                )
                                            )}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Wrapper>
            </Modal>
        </div>
    );
}

export default EstimateModal;
