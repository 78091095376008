import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Tab, TableCell, TableRow, Tabs, Modal } from '@mui/material';
import { Box, styled } from '@mui/system';
import React, { useState } from 'react';
import Table from '../../Components/Table';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckIcon from '@mui/icons-material/Check';
import ColumnTable from '../../Components/Table/Colunm';
import CondolenceModal from '../../Components/Modal/CondolenceModal';
import PageHeader from '../../Components/PageHeader';
import useCostColumnContents from '../ManageFinancial/Claim/ClaimDetail/hooks/useCostColumnContents';

function ManageFuneralDetail() {
    const [value, setValue] = useState('1');
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const StyledTableCell = styled(TableCell)`
        width: 145px;
        background: rgba(48, 55, 66, 0.07);
        border-right: '1px solid #E0E0E0';
        font-weight: 500;
    `;

    const ManageFuneralDetail = [
        {
            name: '이하나',
            range: '2021.11.12~2021.11.14 (3일)',
            condolences: true,
            wreath: true,
            condolence: '20,000원',
            gift: true,
        },
        {
            name: '한습관',
            range: '2021.11.12~2021.11.14 (3일)',
            condolences: true,
            wreath: '',
            condolence: '50,000원',
            gift: '',
        },
    ];

    const ManageFuneralDetailTableCellProps = [
        { width: '60px', align: 'center' },
        { width: '100px' },
        {},
        { width: '70px', align: 'center', sx: { padding: 0 } },
        { width: '70px', align: 'center', sx: { padding: 0 } },
        { width: '140px', align: 'right' },
        { width: '90px', align: 'center', sx: { padding: 0 } },
    ];

    const columnContents = [
        {
            children: (
                <TableRow>
                    <StyledTableCell>고인</StyledTableCell>
                    <TableCell>배희호</TableCell>
                    <StyledTableCell>요청자</StyledTableCell>
                    <TableCell>배수진</TableCell>
                </TableRow>
            ),
        },
        {
            children: (
                <TableRow>
                    <StyledTableCell>장례기간</StyledTableCell>
                    <TableCell>2021.11.12 ~ 2021.11.14 (3일)</TableCell>
                    <StyledTableCell>발인</StyledTableCell>
                    <TableCell>2021.11.12 ~ 2021.11.14 (3일)</TableCell>
                </TableRow>
            ),
        },
    ];

    const estimate = {
        room: {
            name: '특2호실',
            price: 20000,
        },
        shrineFlower: {
            name: '꽃장식',
            price: 30000,
        },
        portrait: {
            name: '소',
            price: 100000,
        },
        manClothes: {
            name: '남성 상복',
            price: 30000,
            cnt: 2,
        },
        womanClothes: {
            name: '여성 상복',
            price: 30000,
            cnt: 2,
        },
        move: {
            name: '관내',
            price: 100000,
        },
        food: {
            name: '접객 음식',
            price: 12000,
        },
        wash: {
            name: '수시비',
            price: 100000,
        },
        sepulchre: {
            name: '안치',
            price: 100000,
        },
        shroud: {
            name: '염습비',
            price: 100000,
        },
        shroudClothes: {
            name: '수의',
            price: 30000,
        },
        coffin: {
            name: '오동나무관',
            price: 30000,
        },
        clean: {
            name: '청소 및 폐기물 처리',
            price: 100000,
        },
        manpowerDirector: {
            price: 250000,
            cnt: 1,
            days: 1,
        },
        manpowerHelper: {
            price: 9160,
            cnt: 1,
            hours: 1,
        },
        etc: [
            {
                name: '기타',
                price: 50000,
            },
        ],
    };

    const changeEstimate = {
        room: {
            name: '특1호실',
            price: 10000,
        },
        shrineFlower: {
            name: '꽃장식',
            price: '30000',
        },
        portrait: {
            name: '대',
            price: 0,
        },
        manClothes: {
            name: '남성 상복',
            price: 0,
            cnt: 1,
        },
        womanClothes: {
            name: '여성 상복',
            price: 0,
            cnt: 1,
        },
        move: {
            name: '관외',
            price: 0,
        },
        food: {
            name: '접객 음식',
            price: 0,
        },
        wash: {
            name: '수시비',
            price: 0,
        },
        sepulchre: {
            name: '안치',
            price: 0,
        },
        shroud: {
            name: '염습비',
            price: 0,
        },
        shroudClothes: {
            name: '수의',
            price: 30000,
        },
        coffin: {
            name: '오동나무관',
            price: 30000,
        },
        clean: {
            name: '청소 및 폐기물 처리',
            price: 0,
        },
        manpowerDirector: {
            price: 250000,
            cnt: 3,
            days: 1,
        },
        manpowerHelper: {
            price: 9160,
            cnt: 2,
            hours: 1,
        },
        etc: [],
    };

    const costColumnContents = useCostColumnContents({
        estimate,
        changeEstimate,
    });

    return (
        <>
            <PageHeader
                title="장례관리 상세"
                breadcrumbItems={[
                    { name: '장례관리' },
                    { name: '장례관리 상세' },
                ]}
            />
            <Box sx={{ fontWeight: 500, fontSize: '16px', mb: '10px' }}>
                견적 요청
            </Box>
            <ColumnTable columnContents={columnContents} />
            <Box mt={'45px'}>
                <TabContext value={value}>
                    <Box
                        sx={{
                            borderBottom: 1,
                            borderColor: 'divider',
                        }}
                    >
                        <TabList
                            onChange={handleChange}
                            sx={{
                                '& .MuiTab-root': {
                                    fontWeight: 700,
                                    fontSize: '18px',
                                    lineHeight: '26px',
                                    color: 'text.disabled',
                                    padding: '0 20px 14px 20px',
                                },

                                '& .Mui-selected': {
                                    borderBottom: '3px solid #C3A981',
                                    color: 'text.primary',
                                },
                            }}
                            textColor="secondary"
                        >
                            <Tab label="장례・발인" value="1" />
                            <Tab label="부고・조문・부조・답례" value="2" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <ColumnTable columnContents={costColumnContents} />
                    </TabPanel>
                    <TabPanel value="2">
                        <Table
                            numeric
                            reverse
                            columns={[
                                { content: '이름' },
                                { content: '장례기간' },
                                { content: '조문' },
                                { content: '근조화환' },
                                { content: '부조' },
                                { content: '답례품' },
                            ]}
                            tableCellProps={ManageFuneralDetailTableCellProps}
                            items={ManageFuneralDetail.map((ele, idx) => (
                                <TableRow key={idx} onClick={handleOpen}>
                                    {[
                                        {
                                            content:
                                                page * itemsPerPage + (idx + 1),
                                        },
                                        { content: ele.name },
                                        { content: ele.range },
                                        {
                                            content: ele.condolences && (
                                                <RadioButtonUncheckedIcon fontSize="small" />
                                            ),
                                        },
                                        {
                                            content: ele.wreath && (
                                                <RadioButtonUncheckedIcon fontSize="small" />
                                            ),
                                        },
                                        { content: ele.condolence },
                                        {
                                            content: ele.gift && (
                                                <CheckIcon fontSize="small" />
                                            ),
                                        },
                                    ].map(({ content }, idx) => (
                                        <TableCell
                                            key={idx}
                                            {...ManageFuneralDetailTableCellProps[
                                                idx
                                            ]}
                                        >
                                            {content}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        />
                    </TabPanel>
                </TabContext>
            </Box>
            <CondolenceModal open={open} close={handleClose} />
        </>
    );
}

export default ManageFuneralDetail;
