import { useState } from 'react';
import { TextField, Box, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import styled from 'styled-components';
import ModalLayout from '../../../Components/Modal/ModalLayout';
import { addHyphenPhone } from './../../../common/util';
import regexp from './../../../common/regexp';
import ErrorMessage from '../../../Components/Form/ErrorMessage';

const EditWreathOrderNotifyReceivePhoneForm = styled.form`
    .body {
        text-align: center;
        border-bottom: 1px solid #e0e0e0;
        padding: 0 40px 30px;
        h5 {
            font-weight: 500;
            font-size: 16px;
            padding: 5px 24px 15px;
        }
    }
    .footer {
        ul {
            padding: 10px 20px 20px;
            display: flex;
            gap: 10px;
            align-items: center;
            justify-content: flex-end;
        }
    }
`;

function EditWreathOrderNotifyReceivePhoneModal() {
    const [
        isOpenEditWreathOrderNotifyReceivePhone,
        setIsOpenEditWreathOrderNotifyReceivePhone,
    ] = useState(false);

    const {
        control: editPhoneControl,
        handleSubmit,
        watch: watchEditNotifyReceivePhone,
        setValue,
    } = useForm();

    const disabledEditNotifyReceivePhone = !watchEditNotifyReceivePhone().phone;

    const onSubmitEditNotifyReceivePhone = (data) => {
        console.log(data);
        setIsOpenEditWreathOrderNotifyReceivePhone(false);
    };

    return (
        <>
            <Box
                component={'button'}
                type="button"
                sx={{
                    color: '#007AFF',
                    textDecoration: 'underline !important',
                }}
                onClick={() => {
                    setIsOpenEditWreathOrderNotifyReceivePhone(true);
                }}
            >
                Edit
            </Box>
            <ModalLayout
                open={isOpenEditWreathOrderNotifyReceivePhone}
                close={() => {
                    setIsOpenEditWreathOrderNotifyReceivePhone(false);
                }}
            >
                <EditWreathOrderNotifyReceivePhoneForm
                    onSubmit={handleSubmit(onSubmitEditNotifyReceivePhone)}
                >
                    <div className="body">
                        <h5>근조화환 주문 알림 수신 휴대폰번호</h5>
                        <Controller
                            name="phone"
                            defaultValue=""
                            control={editPhoneControl}
                            rules={{
                                validate: {
                                    isCorrectPattern: (v) =>
                                        regexp.mobilePhone.test(v) ||
                                        '휴대폰번호를 양식에 맞게 입력해주세요.',
                                },
                                onChange: (e) => {
                                    const value = e.target.value;
                                    setValue('phone', addHyphenPhone(value));
                                },
                            }}
                            render={({ field, fieldState: { error } }) => (
                                <>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        {...field}
                                    />
                                    <ErrorMessage error={error} />
                                </>
                            )}
                        />
                    </div>
                    <div className="footer">
                        <ul>
                            <li>
                                <Button
                                    color="primary2"
                                    variant="outlined"
                                    type="button"
                                    onClick={() => {
                                        setIsOpenEditWreathOrderNotifyReceivePhone(
                                            false
                                        );
                                    }}
                                >
                                    취소
                                </Button>
                            </li>
                            <li>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    disabled={disabledEditNotifyReceivePhone}
                                >
                                    저장
                                </Button>
                            </li>
                        </ul>
                    </div>
                </EditWreathOrderNotifyReceivePhoneForm>
            </ModalLayout>
        </>
    );
}

export default EditWreathOrderNotifyReceivePhoneModal;
